import React, {ChangeEvent, FormEvent, useEffect} from 'react';
import {USER_CREATE_MUTATION} from 'queries/users';
import {useMutation} from '@apollo/react-hooks';
import {useHistory} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import 'components/base/EPackForm/EPackForm.css';
import './User.css';
import 'base.css';
import {Alert} from "constants/swal";
import EpackLoader from 'components/loaders/Loaders';

const {useState} = require('react');

export function CreateUser() {
    let history = useHistory();
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    // is manager default false
    const [isManager, setIsManager] = useState(false);
    // is auditor default true
    const [isAuditor, setIsAuditor] = useState(true);

    let errors: { [msg: string]: string } = {};
    const [validated, setValidated] = useState(false);

    // Handles state of checkbox group validness. At least one of two is required.
    const [checkboxGroupValid, setCheckboxGroupValid] = useState(isManager || isAuditor);
    useEffect(() => {
        setCheckboxGroupValid(isManager || isAuditor);
    }, [isManager, isAuditor]);

    const [
        createUser,
        {data, error: mutationError, loading: mutationLoading},
    ] = useMutation(USER_CREATE_MUTATION, {errorPolicy: 'all'});

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            createUser({variables: {email, firstName, lastName, isManager, isAuditor}})
                .then(({data}) => {
                    const validation_errors = data.createUser.errors;
                    if (validation_errors != null && validation_errors.length !== 0) {
                        setValidated(false);
                    }
                })
                .catch(mutationError => {
                    // Catch Promise.
                    Alert.fire("Error has occurred:", mutationError.message.replace("GraphQL error: ", "").trim(), "error");
                });
        }
        setValidated(true);
    };

    if (mutationError) {
        Alert.fire("There is something went wrong...", "Please contact administrator.", "error");
        // TODO For production environment error codes could be added (user friendly).
        return <div>{mutationError && <p>Error : <b>{mutationError.message}</b></p>}</div>;
    }

    if (data) {
        const validation_errors = data.createUser.errors;
        if (validation_errors != null && validation_errors.length !== 0) {
            for (const err of validation_errors) errors[err.field] = err.messages[0];
        } else {
            history.push('/users');
        }
    }

    return (
        <div>
            <div className="user-form-wrapper">
                <Form
                    id="createUserForm"
                    className="p-4"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                >
                    <p className="h4 mb-4 f-1">
                        <b>Create user</b>
                    </p>
                    <hr/>
                    {errors['__all__'] && (
                        <div className="alert alert-danger" role="alert">
                            {errors['__all__']}
                        </div>)}
                    <Form.Group controlId="formHorizontalEmail">
                        <Form.Label className="required" column sm={2}>
                            <b>Email:</b>
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control
                                required
                                type="email"
                                value={email}
                                placeholder="Email"
                                isInvalid={Boolean(errors['email'])}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                            />
                            {errors['email'] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors['email']}
                                </Form.Control.Feedback>)}
                        </Col>

                    </Form.Group>
                    <Form.Group controlId="formHorizontalName">
                        <Form.Label column sm={2}>
                            <b>Full Name:</b>
                        </Form.Label>
                        <Form.Group as={Row}>
                            <Col sm={6}>
                                <Form.Control
                                    title="First name"
                                    placeholder="First name"
                                    value={firstName}
                                    isInvalid={Boolean(errors['first_name'])}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                                    name="firstName"
                                />
                                {errors['first_name'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['first_name']}
                                    </Form.Control.Feedback>)}
                            </Col>
                            <Col sm={6}>
                                <Form.Control
                                    title="Last name"
                                    placeholder="Last name"
                                    value={lastName}
                                    isInvalid={Boolean(errors['last_name'])}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                                    name="lastName"
                                />
                                {errors['last_name'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['last_name']}
                                    </Form.Control.Feedback>)}
                            </Col>
                        </Form.Group>
                    </Form.Group>
                    <Form.Group controlId="formHorizontalPermission">
                        <Form.Label column sm={2} className="required">
                            <b>Permissions:</b>
                        </Form.Label>

                        <Col className="permission-checkbox-wrapper">
                            <Form.Check
                                custom
                                inline
                                type="checkbox"
                                label="Is manager"
                                id="isManager"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setIsManager(e.target.checked)}
                                required={!checkboxGroupValid}
                            />

                            <Form.Check inline custom type="checkbox" id="isAuditor">
                                <Form.Check.Input
                                    required={!checkboxGroupValid}
                                    checked={isAuditor}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setIsAuditor(e.target.checked)}
                                />
                                <Form.Check.Label>Is auditor</Form.Check.Label>

                                <Form.Control.Feedback type="invalid" className="checkbox-feedback">
                                    Please select at least one option
                                </Form.Control.Feedback>
                            </Form.Check>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="create-form-submit-btn my-4">
                        <Col>
                            <Button type="submit">Save</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </div>
            <div>{mutationLoading && (<EpackLoader loaderType="ballcliprotate"/>)}</div>
        </div>
    );
}

export default CreateUser;
