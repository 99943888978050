import React, {useState} from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {DateRangePicker} from "react-date-range";
import {subDays} from 'date-fns';
import {useQuery} from "@apollo/react-hooks";
import {Alert} from "constants/swal";
import {USER_STATS_QUERY} from 'queries/stats';
import {Col, Container, Row} from "react-bootstrap";
import './UserStats.css'
import EPackButton from "components/base/EPackButton/EPackButton";
import {faPoll} from "@fortawesome/free-solid-svg-icons/faPoll";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import UserInfo from "components/users/UserStats/helpers/UserInfo";
import EpackLoader from 'components/loaders/Loaders';


interface auditorStatsSection {
    workTime: number,
    comparisonCount: number,
    comparisonsPerHour: number,
}


function UserStatsView(props: any) {

    const [dateRange, setDateRange] = useState([
        {
            startDate: subDays(new Date(), 6),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const {
        loading: globalStatsLoading,
        error: globalStatsError,
        data: globalStatsData
    } = useQuery(USER_STATS_QUERY, {
        variables: {
            userId: props.match.params.id,
            startDate: dateRange[0].startDate,
            endDate: dateRange[0].endDate
        }
    });
    if (globalStatsLoading) {
        return <EpackLoader/>
    }
    if (globalStatsError) {
        Alert.fire(globalStatsError.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
        return <div>Error: {globalStatsError.message}</div>;
    }

    return (
        <Container className="main-container container-white">
            <Container className="stats-header">
                <Row>
                    <Col>
                        <h2><b>Stats</b></h2>
                        <UserInfo userId={props.match.params.id}/>
                    </Col>
                    <Col>
                        <div className="btn-group">
                            <EPackButton className="mr-1" label="Details" icon={faList} variant="primary"
                                         href={"/stats/" + props.match.params.id + "/details/"}
                                         />
                            <EPackButton className="w-30" label="Time statistics for all users" icon={faPoll} href="/stats-for-all"/>

                        </div>

                    </Col>
                </Row>
            </Container>
            <hr/>
            <Row>
                <Col>
                    <StatsValues workTime={globalStatsData.auditorStats[0].workTime}
                                 comparisonCount={globalStatsData.auditorStats[0].comparisonCount}
                                 comparisonsPerHour={globalStatsData.auditorStats[0].comparisonsPerHour}
                    />
                </Col>
                <Col>
                    <DateRangePicker
                        // @ts-ignore
                        onChange={item => setDateRange([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                        direction="horizontal"
                    />
                </Col>
            </Row>
        </Container>
    )
}


function StatsValues(stats: auditorStatsSection) {
    return (
        <Container className="auditor-global-stats text-center">
            <Container className="stats-single-box">
                <Row><Col className="auditor-global-stats-values">{stats.workTime}h</Col></Row>
                <Row><Col className="text-muted">Work time</Col></Row>
            </Container>
            <br/>
            <Container className="stats-single-box">
                <Row><Col className="auditor-global-stats-values">{stats.comparisonCount}</Col></Row>
                <Row><Col className="text-muted">Comparisons</Col></Row>
            </Container>
            <br/>
            <Container className="stats-single-box">
                <Row> <Col className="auditor-global-stats-values">{stats.comparisonsPerHour}</Col></Row>
                <Row><Col className="text-muted">Comparisons/hr</Col></Row>
            </Container>
        </Container>
    )
}


export default UserStatsView;
