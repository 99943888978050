import React, {useState} from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {DateRangePicker} from "react-date-range";
import {subDays} from 'date-fns';
import {useQuery} from "@apollo/react-hooks";
import {Alert} from "constants/swal";
import {STATS_FOR_ALL_QUERY} from 'queries/stats';
import {Col, Container, Row} from "react-bootstrap";
import 'components/users/UserStats/StatsForAll/StatsForAll.css'
import EpackLoader from 'components/loaders/Loaders';
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";


function StatsForAllView(props: any) {

    const [dateRange, setDateRange] = useState([
        {
            startDate: subDays(new Date(), 6),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [sortType, setSortType] = useState('');

    const {
        loading: statsForAllLoading,
        error: statsForAllError,
        data: statsForAllData,
        refetch: detailedStatsRefetch
    } = useQuery(STATS_FOR_ALL_QUERY, {
        variables: {
            startDate: dateRange[0].startDate,
            endDate: dateRange[0].endDate,
            sort: sortType
        }
    });
    if (statsForAllLoading) {
        return <EpackLoader/>
    }
    if (statsForAllError) {
        Alert.fire("Error occured.", "Please contact administrator.", "error");
        return <p>Error occured :(</p>;
    }
    return (
        <Container className="main-container container-white">
            <Container className="stats-header">
                <Row>
                    <Col>
                        <h2><b>Time statistics for all users</b></h2>
                    </Col>
                </Row>
            </Container>
            <hr/>
            <div className='d-flex justify-content-center'>
                <Row>
                    <Col>

                        <DateRangePicker
                            // @ts-ignore
                            onChange={item => setDateRange([item.selection])}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            ranges={dateRange}
                            direction="horizontal"
                        />

                    </Col>
                </Row>
            </div>
            <Container className="d-flex justify-content-center">
                <Row>
                    <Col>
                        <div className="detailed-sort-button">
                            <Button variant="outline-secondary"
                                    onClick={() => {
                                        setSortType('work_time_time_dsc');
                                        detailedStatsRefetch();
                                    }}
                            ><FontAwesomeIcon
                                title="Sort work time descending" icon={faCaretDown}
                                className="button-icon"/>Sort work time desc
                            </Button>
                        </div>
                        <div className="detailed-sort-button">
                            <Button variant="outline-primary"
                                    onClick={() => {
                                        setSortType('work_time_asc');
                                        detailedStatsRefetch();
                                    }}
                            ><FontAwesomeIcon
                                title="Sort work time ascending" icon={faCaretUp}
                                className="button-icon"/>Sort work time asc
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <hr/>
            <UserStatsDetailsTable statsForAllData={statsForAllData} loading={statsForAllLoading}
                                    error={statsForAllError}/>
        </Container>
    )
}

function UserStatsDetailsTable({statsForAllData, statsForAllLoading, statsForAllError}: any) {
    if (statsForAllLoading) {
        return <EpackLoader/>
    }
    if (statsForAllError) {
        Alert.fire("Error occured.", "Please contact administrator.", "error");
        return <p>Error occured :(</p>;
    }

    if (statsForAllData.statsForAll && statsForAllData.statsForAll.length === 0) {
        return null
    } else {
        return (
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Work time</th>
                </tr>
                </thead>
                <tbody>
                {statsForAllData.statsForAll.map(function (user: any) {
                    return (
                        <tr key={user.id}>
                            <td width="25%"><a href={"/stats/" + user.id}>{user.name}</a></td>
                            <td width="25%">{user.workTime}h</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        )
    }
}


export default StatsForAllView;
