import React, {FormEvent, useContext, useEffect, useState} from 'react';
import {
  faCog,
  faEdit,
  faPlus,
  faPoll,
  faRedo,
  faTasks,
  faWaveSquare,
  faEquals,
  faImages,
  faClock,
  faBalanceScale,
  faHourglassHalf,
  faTimesCircle,
  faFileExcel,
  faNewspaper,
  faAngleDoubleDown,
  faExclamationTriangle,
  faCheckDouble,
  faInfoCircle,
  faSyncAlt,
  faUserClock,
  faPlusCircle
} from "@fortawesome/free-solid-svg-icons";
import './ProjectList.css';
import {AccordionContext} from 'react-bootstrap';
import Dropdown from "react-bootstrap/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProjectTasks from "./ProjectTasks/ProjectTasks";
import {useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import {PROJECTS_LIST_QUERY} from "queries/projects";
import {COMPARISON_GET_BY_PROJECT_ID_QUERY} from "queries/comparisons";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import LocalHeader from "components/base/HeaderBar/LocalHeader/LocalHeader";
import EPackButton from "components/base/EPackButton/EPackButton";
import SearchBox from "components/base/SearchBox/SearchBox";
import EPackDropdown from "components/base/EPackDropdown/EPackDropdown";
import Modal from "react-bootstrap/cjs/Modal";
import Form from "react-bootstrap/cjs/Form";
import {
  RETRY_WAVE_MUTATION,
  SET_PUBLIC_WAVE_STATUS_MUTATION,
  RE_INITIALIZE_IMAGE_DOWNLOADS_MUTATION,
  WAVE_DELETE_MUTATION,
  PUBLISH_DONE_WAVES_MUTATION,
  WAVE_MAKE_URGENT_MUTATION, WAVE_PROGRESS, WAVE_APPEND_DATA_MUTATION
} from 'queries/waves';
import {TASK_CREATE_TASK_QUERY} from 'queries/tasks';
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import 'base.css';
import Select from 'react-select';
import {Alert, InfoAlert, SuccessAlert, WarningAlert} from 'constants/swal';
import {PROJECTS_PER_PAGE} from 'constants/baseConstants';
import EpackLoader from 'components/loaders/Loaders';
import {useHistory} from "react-router-dom";
import {ClientInterface} from "components/helpers/interfaces";

// The list of queries for project list.
function _getQueryVariables() {
  const first = PROJECTS_PER_PAGE; // The number of projects per page.
  const order = null; // We want to use conditional ordering mechanism from backend.
  return {first, order};
}

interface selectOptions {
  label: string
  value: string
}

function ProjectListView() {
  const history = useHistory();
  // Set initial state.
  const [search, setSearch] = useState('')
  const [publishAllDoneWaves, {loading: publishAllDoneWavesLoading}] = useMutation(PUBLISH_DONE_WAVES_MUTATION, {errorPolicy: 'all'});
  const [waveStatus, setWaveStatus] = useState<object>([]);
  const [projectClient, setProjectClient] = useState<object>([]);
  const waveStatusOptions = [
    {value: '10', label: 'Importing from mapet'},
    {value: '12', label: 'Waiting to start'},
    {value: '10,20,11,22,30,12', label: 'In progress'},
    {value: '11', label: 'In queue'},
    {value: '21', label: 'Auditing'},
    {value: '22', label: 'Task creation'},
    {value: '30', label: 'Done'},
    {value: '40', label: 'Public'},
  ]
  const [isFetching, setIsFetching] = useState(false);
  const [isManualFetching, setManualIsFetching] = useState(false);

  function isScrolling() {
    // Finding the bottom of the page.
    const bottom = document.documentElement.scrollHeight - document.documentElement.scrollTop === window.innerHeight;
    if (bottom) {
      setIsFetching(true)
    }
  }

  const moreData = () => {
    let variables = {
      // @ts-ignore
      waveStatuses: waveStatus.map(({value}) => value),
      // @ts-ignore
      projectClients: projectClient.map(({value}) => value),
      search: search,
    }
    if (data && data.projects.pageInfo.endCursor != null) {
      // @ts-ignore
      variables["after"] = data.projects.pageInfo.endCursor
    }
    // Prevent loading results from beginning.
    if (variables.hasOwnProperty('after')) {
      fetchMore({
        variables: variables,
        updateQuery: (prevResult, {fetchMoreResult}) => {
          // @ts-ignore

          fetchMoreResult.projects.edges = [
            // @ts-ignore
            ...prevResult.projects.edges,
            // @ts-ignore
            ...fetchMoreResult.projects.edges,
          ];
          setIsFetching(false)
          window.scrollTo(0, window.pageYOffset);
          return fetchMoreResult;
        },
      });
    }
  }

  const moreManualData = () => {
    let variables = {
      // @ts-ignore
      waveStatuses: waveStatus.map(({value}) => value),
      // @ts-ignore
      projectClients: projectClient.map(({value}) => value),
      search: search,
    }
    fetchMore({
      variables: variables,
      updateQuery: (prevResult, {fetchMoreResult}) => {
        // @ts-ignore
        fetchMoreResult.projects.edges = [
          // @ts-ignore
          ...fetchMoreResult.projects.edges,
        ];
        setManualIsFetching(false)
        return fetchMoreResult;
      },
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", isScrolling);
    return () => window.removeEventListener("scroll", isScrolling);
  }, [])

  useEffect(() => {
    if (isFetching) {
      moreData();
    }
    if (isManualFetching) {
      moreManualData();
    }
  }, [moreData, isFetching, moreManualData, isManualFetching]);

  // Get project list from API.
  const {loading, error, data, fetchMore} = useQuery(PROJECTS_LIST_QUERY, {
    variables: _getQueryVariables(),
  });
  if (loading) {
    return <EpackLoader/>
  }
  if (publishAllDoneWavesLoading) {
    return <EpackLoader loaderType="ballcliprotate"/>
  }
  if (error) {
    Alert.fire(error.message.replace('GraphQL error: ', '').trim(), 'Please try again later.', 'error');
    return <div>Error: {error.message}</div>;
  }

  if (data) {
    // Set filter value to blank.
    // Map data from API.
    const projectsToRender = data.projects.edges;
    let projectsToRenderMapped = projectsToRender.map((project: {
      node: Project;
    }) => {
      return {
        id: project.node.id,
        name: project.node.name,
        createDate: project.node.createDate,
        waveDataInfo: project.node.waveDataInfo,
      };
    });
    // Map client data from API.
    const clientsToRender = data.clients.edges;
    let projectClientOptions = clientsToRender.map((client: ClientInterface) => {
      return {
        value: client.node.id,
        label: client.node.name,
      };
    });
    // projectClientOptions = prepare_options(clientsToRenderMapped)
    const projectsResultsHasNextPage = data.projects.pageInfo.hasNextPage

    return (
      <>
        <LocalHeader>
          <EPackButton label="Add project" icon={faPlus} href="/add-project"/>
          {projectsResultsHasNextPage ?
            <div>
              <div className="ml-1">
                <EPackButton label="Show more Projects" variant="primary" icon={faAngleDoubleDown}
                             onClick={() => {
                               setIsFetching(true)
                             }}
                />
              </div>
            </div>
            :
            <></>}
          <div className="ml-1">
            <EPackButton label="Publish all done projects" variant="outline-success"
                         icon={faNewspaper}
                         onClick={() => WarningAlert.fire({
                           title: "Publish all done projects",
                           text: "Are you sure you want to publish all projects with status 'Done' ?",
                           showCancelButton: true,
                           confirmButtonText: "Yes",
                           cancelButtonText: "No",
                           allowOutsideClick: false,
                         }).then((setAllPublishSelected) => {
                           if (setAllPublishSelected.isConfirmed) {
                             publishAllDoneWaves().then(({data}) => {
                               const validation_errors = data.publishAllDoneWaves.errors;
                               if (validation_errors != null && validation_errors.length !== 0) {
                                 Alert.fire("Error has occurred:", validation_errors[0].message.replace("GraphQL error: ", "").trim(), "error");
                               } else {
                                 SuccessAlert.fire("All projects with status 'Done' will be published. It may take a while...").then((ok) => {
                                   history.go(0);
                                 });
                               }
                             })
                               .catch(error => {
                                 // Catch Promise.
                                 // TODO Add 500 component.
                                 Alert.fire("Error has occurred:", error.message.replace("GraphQL error: ", "").trim(), "error");
                               })

                           }
                         })
                         }
            />
          </div>
          <div className="col-2 mr-0 pt-1">
            <Select
              isMulti
              isClearable
              name="waveStatusFilterSelect"
              options={waveStatusOptions}
              placeholder="By last wave status"
              classNamePrefix="select"
              onChange={(val: any) => {
                setWaveStatus(val as selectOptions || [])
              }}
            />
          </div>
          <div className="col-1 mr-1 pt-1">
            <Select
              isMulti
              isClearable
              name="projectClientFilterSelect"
              options={projectClientOptions}
              placeholder="By client name"
              classNamePrefix="select"
              onChange={(val: any) => {
                setProjectClient(val as selectOptions || [])
              }}
            />
          </div>
          <div className="mr-3 pt-1">
            <Button variant="warning"
                    onClick={() => {
                      setManualIsFetching(true)
                    }}
            >
              Filter
            </Button>
          </div>
          <SearchBox onChangeInput={(e) => setSearch(e.target.value)} onEnter={() => {
            setManualIsFetching(true)
          }}/>
          <Button variant="primary"
                  onClick={() => {
                    setManualIsFetching(true)
                  }}
          >
            Search
          </Button>
        </LocalHeader>
        <div className="main-container">
          {projectsToRenderMapped.length > 0 ?
            <ProjectList projects={projectsToRenderMapped}/>
            :
            <Card className="text-center">
              <Card.Body className="h5">There are no projects to show.</Card.Body>
            </Card>}
          <div>
            {isFetching && projectsResultsHasNextPage &&
              <EpackLoader loaderType="ballcliprotate" loaderStyle="loading-text"/>}
            {isManualFetching && <EpackLoader loaderType="ballcliprotate"/>}</div>
        </div>
      </>
    )
  }
}


interface Project {
  id: string;
  name: string;
  createDate: string;
  public: boolean;
  taskStats: Record<string, number>;
  shops: string[];
  queuedWavesCount: number;
  notAnsweredComparisonsCount: boolean;
  waveDataInfo: {
    public: boolean,
    taskStats: {
      total: number,
      unassigned: number,
      inProgress: number,
      todo: number
      paused: number
      done: number
    },
    shops: string[],
    currentWave: {
      pk: number,
      alterable: boolean,
      status: number,
      isUrgent: boolean
    },
    disabled: boolean,
    queuedWavesCount: number,
    createDateLastWave: string
  };
  currentWave: {
    pk: number,
    alterable: boolean,
    status: number,
    isUrgent: boolean
  };
  disabled: boolean
  createDateLastWave: string;
}

interface ProjectListProps {
  projects: Project[]
}

function ProjectList({projects}: ProjectListProps) {
  const objects = []
  for (const [pk, project] of projects.entries()) {
    objects.push(
      <Card key={pk}>
        <Card.Header>
          <SingleProjectBox project={project} key={pk} accordionKey={pk}/>
        </Card.Header>
        <Accordion.Collapse eventKey={pk.toString()}>
          <Card.Body><ProjectTasks project_pk={project.id} accordionKey={pk}/></Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }

  return (
    <Accordion>{objects}</Accordion>
  )
}

interface SingleProjectBoxProps {
  project: Project
  accordionKey: number,
  handleMakeWaveUrgent?: any
}

function SingleProjectBox({project, accordionKey}: SingleProjectBoxProps) {
  const [makeUrgentLastWave, {loading: makeUrgentLastWaveLoading}] = useMutation(WAVE_MAKE_URGENT_MUTATION, {errorPolicy: 'all'});
  const history = useHistory();
  if (makeUrgentLastWaveLoading) {
    return <EpackLoader loaderType="ballcliprotate"/>
  }
  const handleMakeWaveUrgent = (waveId: number, setUrgent: boolean) => {
    makeUrgentLastWave({variables: {"waveId": waveId, "setUrgent": setUrgent}}
    ).then(({data}) => {
      const validation_errors = data.makeUrgentLastWave.errors;
      if (validation_errors != null && validation_errors.length !== 0) {
        Alert.fire("Error has occurred:", validation_errors[0].messages[0].replace("GraphQL error: ", "").trim(), "error");
      } else {
        if (setUrgent) {
          SuccessAlert.fire("Project has been set as urgent.").then((ok) => {
            history.go(0);
          });
        } else {
          SuccessAlert.fire("Project has been set as unimportant.").then((ok) => {
            history.go(0);
          });
        }
      }
    })
      .catch(error => {
        // Catch Promise.
        // TODO Add 500 component.
        Alert.fire("Error has occurred:", error.message.replace("GraphQL error: ", "").trim(), "error");
      })
  }

  function GetWaveImportingResults() {
    const [getWaveProgress, {
      loading: waveProgressLoading,
      error: waveProgressError,
      data: waveProgressData
    }] = useLazyQuery(WAVE_PROGRESS, {
      variables: {id: project.waveDataInfo.currentWave.pk},
      fetchPolicy: "network-only"
    });
    const [deleteLastWave, {loading: deleteLastWaveLoading}] = useMutation(WAVE_DELETE_MUTATION, {errorPolicy: 'all'});
    if (deleteLastWaveLoading) {
      return <EpackLoader loaderType="ballcliprotate" loaderStyle="loading-wave-status"/>
    }
    if (waveProgressLoading) {
      return <div><EpackLoader loaderType="ballcliprotate" loaderStyle="loading-wave-status"/></div>
    }
    if (waveProgressError) {
      Alert.fire(waveProgressError.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
    }
    if (waveProgressData && waveProgressData.wave.waveProgress.status !== 10) {
      history.go(0);
    }

    return (
      <>
        {waveProgressData ? <>
            <div className="badge badge-pill badge-warning pl-1"><FontAwesomeIcon
              title="Total comparisons count in wave" icon={faWaveSquare}
              className="button-icon"/>{waveProgressData.wave.waveProgress.comparisonCount}</div>
            <span
              className="btn-group ml-1 pb-1">
                    <Button variant="btn btn btn-outline-primary btn-sm mr-1"
                            onClick={() => getWaveProgress()}><FontAwesomeIcon title="Refresh wave status"
                                                                               icon={faSyncAlt}
                                                                               className="button-icon"/>
                        Refresh</Button>

            <EPackDropdown className="project-configuration-dropdown">
                <Dropdown.Toggle variant="outline-primary">
                    <FontAwesomeIcon icon={faCog} className="button-icon"/>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-options">
                                                          <Dropdown.Item className=""
                                                                         href={'/project-results/' + project.id}>
                <FontAwesomeIcon icon={faTasks}
                                 className="button-icon"/>Results
            </Dropdown.Item>
                                                          <Dropdown.Item className=""
                                                                         href={'/wave-stats/' + project.waveDataInfo.currentWave.pk}>
                <FontAwesomeIcon icon={faClock}
                                 className="button-icon"/>Wave performance
            </Dropdown.Item>
            <Dropdown.Item className=""
                           onClick={() => handleMakeWaveUrgent(project.waveDataInfo.currentWave.pk, !project.waveDataInfo.currentWave.isUrgent)
                           }>
                <FontAwesomeIcon icon={faExclamationTriangle}
                                 className="button-icon"/>{project.waveDataInfo.currentWave.isUrgent ? 'Mark as unimportant' : 'Mark as urgent'}
            </Dropdown.Item>
                </Dropdown.Menu>
            </EPackDropdown></span>
          </>
          : <Button variant="btn btn-outline-primary btn-sm"
                    onClick={() => getWaveProgress()}><FontAwesomeIcon
            title="Show wave progress" icon={faInfoCircle} className="button-icon"/>
            Show wave progress</Button>}
      </>
    );
  }

  function GetWaveWaitingToStartResults() {
    const [getWaveProgress, {
      loading: waveProgressLoading,
      error: waveProgressError,
      data: waveProgressData
    }] = useLazyQuery(WAVE_PROGRESS, {
      variables: {id: project.waveDataInfo.currentWave.pk},
      fetchPolicy: "network-only"
    });
    if (waveProgressLoading) {
      return <div><EpackLoader loaderType="ballcliprotate" loaderStyle="loading-wave-status"/></div>
    }
    if (waveProgressError) {
      Alert.fire(waveProgressError.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
    }
    if (waveProgressData && waveProgressData.wave.waveProgress.status !== 12) {
      history.go(0);
    }

    return (
      <>
        {waveProgressData ? <>
          <div className="badge badge-pill badge-warning pl-1"><FontAwesomeIcon
            title="Total comparisons count in wave" icon={faWaveSquare}
            className="button-icon"/>{waveProgressData.wave.waveProgress.comparisonCount}</div>
          <Button variant="btn btn btn-outline-primary btn-sm ml-1"
                  onClick={() => getWaveProgress()}><FontAwesomeIcon title="Refresh wave status"
                                                                     icon={faSyncAlt}
                                                                     className="button-icon"/>
            Refresh</Button></> : <Button variant="btn btn-outline-primary btn-sm"
                                          onClick={() => getWaveProgress()}><FontAwesomeIcon
          title="Show wave progress" icon={faInfoCircle} className="button-icon"/>
          Show wave progress</Button>}
      </>
    );
  }

  function GetWaveInProgressResults() {
    const [getWaveProgress, {
      loading: waveProgressLoading,
      error: waveProgressError,
      data: waveProgressData
    }] = useLazyQuery(WAVE_PROGRESS, {
      variables: {id: project.waveDataInfo.currentWave.pk},
      fetchPolicy: "network-only"
    });
    if (waveProgressError) {
      Alert.fire(waveProgressError.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
    }
    if (waveProgressData && waveProgressData.wave.waveProgress.status !== 20) {
      history.go(0);
    }

    return (
      <>
        {waveProgressLoading && <><EpackLoader loaderType="ballcliprotate"
                                               loaderStyle="loading-wave-status-in-progress"/></>}
        {waveProgressData ? [(waveProgressData.wave.waveProgress.progress !== 100 ?
            <>Wave is in progress... {waveProgressData.wave.waveProgress.progress}% <span
              className="btn-group ml-1 pb-1"><div
              className="badge badge-pill badge-success ml-1"><FontAwesomeIcon
              title="Answered comparisons count (finished)" icon={faEquals}
              className="button-icon"/>{waveProgressData.wave.waveProgress.answeredComparisonsCount}</div><div
              className="badge badge-pill badge-danger ml-1"><FontAwesomeIcon
              title="Comparisons with downloaded images count" icon={faImages}
              className="button-icon"/>{waveProgressData.wave.waveProgress.downloadedComparisonsCount}</div><div
              className="badge badge-pill badge-warning ml-1"><FontAwesomeIcon
              title="Total comparisons count in wave" icon={faWaveSquare}
              className="button-icon"/>{waveProgressData.wave.waveProgress.comparisonCount}</div><div
              className="badge badge-pill badge-primary ml-1"><FontAwesomeIcon
              title="Approximate complete time" icon={faClock}
              className="button-icon"/>{waveProgressData.wave.waveProgress.endTime}</div><Button
              variant="btn btn btn-outline-primary btn-sm ml-1"
              onClick={() => getWaveProgress()}><FontAwesomeIcon title="Refresh wave status"
                                                                 icon={faSyncAlt}
                                                                 className="button-icon"/>
      </Button></span><ProjectReInitializeImageDownloads
              project={project} handleMakeWaveUrgent={handleMakeWaveUrgent}/></> :
            <> Waiting for tasks creation... <span className="btn-group ml-4 pb-1"><div
              className="badge badge-pill badge-warning ml-1"><FontAwesomeIcon
              title="Total comparisons count in wave" icon={faWaveSquare}
              className="button-icon"/>{waveProgressData.wave.waveProgress.comparisonCount}</div>            <Button
              variant="btn btn btn-outline-primary btn-sm ml-1"
              onClick={() => getWaveProgress()}><FontAwesomeIcon title="Refresh wave status"
                                                                 icon={faSyncAlt}
                                                                 className="button-icon"/>
      Refresh</Button></span><span
              className="btn-group ml-4 pb-1">
                                        <EPackDropdown className="project-configuration-dropdown">
                                            <Dropdown.Toggle variant="outline-primary">
                                                <FontAwesomeIcon icon={faCog} className="button-icon"/>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-options">
                                                <Dropdown.Item className=""
                                                               href={'/project-results/' + project.id}>
                                            <FontAwesomeIcon icon={faTasks}
                                                             className="button-icon"/>Results
                                        </Dropdown.Item>
                                                                                      <Dropdown.Item className=""
                                                                                                     href={'/wave-stats/' + project.waveDataInfo.currentWave.pk}>
                <FontAwesomeIcon icon={faClock}
                                 className="button-icon"/>Wave performance
            </Dropdown.Item>
                                        <Dropdown.Item className=""
                                                       onClick={() => handleMakeWaveUrgent(project.waveDataInfo.currentWave.pk, !project.waveDataInfo.currentWave.isUrgent)
                                                       }>
                                            <FontAwesomeIcon icon={faExclamationTriangle}
                                                             className="button-icon"/>{project.waveDataInfo.currentWave.isUrgent ? 'Mark as unimportant' : 'Mark as urgent'}
                                        </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                      onClick={() => InfoAlert.fire({
                                                                        title: "Generate XLSX report for last wave",
                                                                        text: "Report will provide information about all comparisons, which were not successful.",
                                                                        showCancelButton: false,
                                                                        confirmButtonText: "Generate report",
                                                                        cancelButtonText: "No",
                                                                        allowOutsideClick: true,
                                                                      }).then((generateReportSelected) => {
                                                                        if (generateReportSelected.isConfirmed) {
                                                                          const reportUrl = window.location.protocol + "//" + window.location.hostname + '/comparisons/failed-comparisons-report/' + project.id + '/'
                                                                          // const reportUrl = process.env.REACT_APP_ROOT_HOST_URL + '/comparisons/failed-comparisons-report/' + project.id + '/'
                                                                          window.open(reportUrl, "_blank")
                                                                        }
                                                                      })
                                                                      }>
                                                    <FontAwesomeIcon icon={faFileExcel}
                                                                     className="button-icon"/>Generate report
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </EPackDropdown>
                                    </span></>)]
          : <>Wave is in progress... <span
            className="btn-group ml-4 pb-1"><Button variant="btn btn-outline-primary btn-sm"
                                                    onClick={() => getWaveProgress()}><FontAwesomeIcon
            title="Show wave progress" icon={faInfoCircle} className="button-icon"/>
      Show wave progress</Button></span><span
            className="btn-group ml-1 pb-1">
                                               <EPackButton
                                                 label={project.waveDataInfo.currentWave.isUrgent ? "Mark as unimportant" : "Mark as urgent"}
                                                 className="make-urgent-last-wave-button"
                                                 icon={faExclamationTriangle}
                                                 variant={project.waveDataInfo.currentWave.isUrgent ? "btn btn-outline-secondary btn-sm" : "btn btn-outline-danger btn-sm"}
                                                 onClick={() => handleMakeWaveUrgent(project.waveDataInfo.currentWave.pk, !project.waveDataInfo.currentWave.isUrgent)}
                                               />
                                </span></>}
      </>
    );
  }

  function GetWaveTaskCreationResults() {
    const [getWaveProgress, {
      loading: waveProgressLoading,
      error: waveProgressError,
      data: waveProgressData
    }] = useLazyQuery(WAVE_PROGRESS, {
      variables: {id: project.waveDataInfo.currentWave.pk},
      fetchPolicy: "network-only"
    });
    if (waveProgressError) {
      Alert.fire(waveProgressError.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
    }
    if (waveProgressData && waveProgressData.wave.waveProgress.status !== 22) {
      history.go(0);
    }

    return (
      <>
        {waveProgressLoading && <><EpackLoader loaderType="ballcliprotate"
                                               loaderStyle="loading-wave-status-in-progress"/></>}
        {waveProgressData ?
          <>Task creation in progress... <span className="btn-group ml-4 pb-1"><div
            className="badge badge-pill badge-warning ml-1"><FontAwesomeIcon
            title="Total comparisons count in wave" icon={faWaveSquare}
            className="button-icon"/>{waveProgressData.wave.waveProgress.comparisonCount}</div>            <Button
            variant="btn btn btn-outline-primary btn-sm ml-1"
            onClick={() => getWaveProgress()}><FontAwesomeIcon title="Refresh wave status"
                                                               icon={faSyncAlt}
                                                               className="button-icon"/>
        Refresh</Button></span><span
            className="btn-group ml-4 pb-1">
                                          <EPackDropdown className="project-configuration-dropdown">
                                              <Dropdown.Toggle variant="outline-primary">
                                                  <FontAwesomeIcon icon={faCog} className="button-icon"/>
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu className="dropdown-options">
                                                  <Dropdown.Item className=""
                                                                 href={'/project-results/' + project.id}>
                                              <FontAwesomeIcon icon={faTasks}
                                                               className="button-icon"/>Results
                                          </Dropdown.Item>
                                                                                        <Dropdown.Item className=""
                                                                                                       href={'/wave-stats/' + project.waveDataInfo.currentWave.pk}>
                <FontAwesomeIcon icon={faClock}
                                 className="button-icon"/>Wave performance
            </Dropdown.Item>
                                          <Dropdown.Item className=""
                                                         onClick={() => handleMakeWaveUrgent(project.waveDataInfo.currentWave.pk, !project.waveDataInfo.currentWave.isUrgent)
                                                         }>
                                              <FontAwesomeIcon icon={faExclamationTriangle}
                                                               className="button-icon"/>{project.waveDataInfo.currentWave.isUrgent ? 'Mark as unimportant' : 'Mark as urgent'}
                                          </Dropdown.Item>
                                                                      <Dropdown.Item
                                                                        onClick={() => InfoAlert.fire({
                                                                          title: "Generate XLSX report for last wave",
                                                                          text: "Report will provide information about all comparisons, which were not successful.",
                                                                          showCancelButton: false,
                                                                          confirmButtonText: "Generate report",
                                                                          cancelButtonText: "No",
                                                                          allowOutsideClick: true,
                                                                        }).then((generateReportSelected) => {
                                                                          if (generateReportSelected.isConfirmed) {
                                                                            const reportUrl = window.location.protocol + "//" + window.location.hostname + '/comparisons/failed-comparisons-report/' + project.id + '/'
                                                                            // const reportUrl = process.env.REACT_APP_ROOT_HOST_URL + '/comparisons/failed-comparisons-report/' + project.id + '/'
                                                                            window.open(reportUrl, "_blank")
                                                                          }
                                                                        })
                                                                        }>
                                                      <FontAwesomeIcon icon={faFileExcel}
                                                                       className="button-icon"/>Generate report
                                                  </Dropdown.Item>
                                              </Dropdown.Menu>
                                          </EPackDropdown>
                                      </span></>
          : <>Task creation in progress... <span
            className="btn-group ml-4 pb-1"><Button variant="btn btn-outline-primary btn-sm"
                                                    onClick={() => getWaveProgress()}><FontAwesomeIcon
            title="Show wave progress" icon={faInfoCircle} className="button-icon"/>
        Show wave progress</Button></span><span
            className="btn-group ml-1 pb-1">
                                                 <EPackButton
                                                   label={project.waveDataInfo.currentWave.isUrgent ? "Mark as unimportant" : "Mark as urgent"}
                                                   className="make-urgent-last-wave-button"
                                                   icon={faExclamationTriangle}
                                                   variant={project.waveDataInfo.currentWave.isUrgent ? "btn btn-outline-secondary btn-sm" : "btn btn-outline-danger btn-sm"}
                                                   onClick={() => handleMakeWaveUrgent(project.waveDataInfo.currentWave.pk, !project.waveDataInfo.currentWave.isUrgent)}
                                                 />
                                  </span></>}
      </>
    );
  }

  const renderStatusSwitch = () => {
    switch (project.waveDataInfo.currentWave.status) {
      // Status TODO, when comparisons are being imported.
      case 10:
        return <>Importing comparisons... <span className="btn-group ml-4 pb-1"><GetWaveImportingResults/></span></>
      // Status TODO, when comparisons are being imported.
      case 12:
        return <>Waiting for wave to start... <span
          className="btn-group ml-4 pb-1"><GetWaveWaitingToStartResults/></span>
          <div className="btn-group ml-1 pb-1">
            <span className="pl8 display-flex">
            <EPackDropdown className="project-configuration-dropdown">
                <Dropdown.Toggle variant="outline-primary">
                    <FontAwesomeIcon icon={faCog} className="button-icon"/>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-options">
                                        <Dropdown.Item className=""
                                                       href={'/project-results/' + project.id}>
                <FontAwesomeIcon icon={faTasks}
                                 className="button-icon"/>Results
            </Dropdown.Item>
                                                          <Dropdown.Item className=""
                                                                         href={'/wave-stats/' + project.waveDataInfo.currentWave.pk}>
                <FontAwesomeIcon icon={faClock}
                                 className="button-icon"/>Wave performance
            </Dropdown.Item>
            <Dropdown.Item className=""
                           onClick={() => handleMakeWaveUrgent(project.waveDataInfo.currentWave.pk, !project.waveDataInfo.currentWave.isUrgent)
                           }>
                <FontAwesomeIcon icon={faExclamationTriangle}
                                 className="button-icon"/>{project.waveDataInfo.currentWave.isUrgent ? 'Mark as unimportant' : 'Mark as urgent'}
            </Dropdown.Item>
                </Dropdown.Menu>
            </EPackDropdown>
        </span>
          </div>
        </>
      // Status in progress
      case 20:
        return <><GetWaveInProgressResults/></>
      // Status Task creation
      case 22:
        return <><GetWaveTaskCreationResults/></>
      // No wave for selected project
      default:
        return <>Waiting for wave to start in Mapet... <span
          className="btn-group ml-4 pb-1"><EPackButton
          href={'/update-project/' + project.id} label='Edit project'
          icon={faEdit} className="button-icon"
          variant="btn btn-outline-primary btn-sm"/></span></>
    }
  }
  return (
    <>
      {project.waveDataInfo.disabled && <div className="single-project-box-disabled-message">

        {renderStatusSwitch()}
      </div>
      }
      <div
        className={project.waveDataInfo.disabled && project.waveDataInfo.currentWave.isUrgent ? "urgent-box white-box-list display-block single-project-box-disabled" : (project.waveDataInfo.currentWave.isUrgent ? "urgent-box white-box-list display-block single-project-box" : (project.waveDataInfo.disabled ? "white-box white-box-list display-block single-project-box-disabled" : "white-box white-box-list display-block single-project-box"))}>
        <div className="project-name">{project.waveDataInfo.currentWave.isUrgent ?
          <span className="is-urgent"><FontAwesomeIcon title="Urgent project" icon={faExclamationTriangle}
                                                       className="button-icon"/></span> : <></>}{project.name}{project.waveDataInfo.queuedWavesCount ?
          <span className="btn-group ml-2 pb-1"><div
            className="badge badge-pill badge-warning ml-1"><FontAwesomeIcon
            title="Waves in queue. Complete or remove all tasks from previous wave and publish project in order to initiate next wave."
            icon={faHourglassHalf}
            className="button-icon"/>{project.waveDataInfo.queuedWavesCount}</div></span> : ""}
        </div>
        <ProjectMiddleRow project={project} accordionKey={accordionKey} handleMakeWaveUrgent={handleMakeWaveUrgent}/>
        <ProjectTasksInfo project={project}/>
      </div>
    </>
  )
}

interface SingleProjectProps {
  project: Project,
  handleMakeWaveUrgent?: any
}

const resolveShowProjectTasksDropdownToggle = (project: any) => {
  if (project.waveDataInfo.disabled) {
    return false
  } else if (project.waveDataInfo.public) {
    return false
  } else if (project.waveDataInfo.taskStats.total == project.waveDataInfo.taskStats.done) {
    return false
  } else {
    return true
  }
}

function ProjectMiddleRow({project, accordionKey, handleMakeWaveUrgent}: SingleProjectBoxProps) {
  const showProjectTasksDropdownToggle = resolveShowProjectTasksDropdownToggle(project)
  return (
    <div className="data-row">
      {project.waveDataInfo.createDateLastWave ?
        <span className="col pl0 project-create-date text-muted">
                 Wave created: {project.waveDataInfo.createDateLastWave}
                </span> :
        <span className="col pl0 project-create-date text-muted">
                 Created: {project.createDate}
                </span>
      }
      <div className="display-flex">
        <span className="col project-public">
                {project.waveDataInfo.public ? <h5>
                  <div
                    className="badge badge-success mr-5"><FontAwesomeIcon
                    title="Project is public" icon={faCheckDouble}
                    className="button-icon"/>Published
                  </div>
                </h5> : ""}
            </span>
      </div>
      <div className="display-flex">
        <ProjectButtons project={project}/>
        <ProjectConfigurationDropdown project={project} handleMakeWaveUrgent={handleMakeWaveUrgent}/>
        {showProjectTasksDropdownToggle ? (<ProjectTasksDropdownToggle accordionKey={accordionKey}/>) :
          <div style={{width: "47px"}}></div>}
      </div>
    </div>
  )
}

function ProjectReInitializeImageDownloads({project, handleMakeWaveUrgent}: SingleProjectProps) {
  const [reInitializeImageDownloads, {loading}] = useMutation(RE_INITIALIZE_IMAGE_DOWNLOADS_MUTATION, {errorPolicy: 'all'});
  const [deleteLastWave, {loading: deleteLastWaveLoading}] = useMutation(WAVE_DELETE_MUTATION, {errorPolicy: 'all'});
  const [appendDataLastWave, {loading: appendDataLastWaveLoading}] = useMutation(WAVE_APPEND_DATA_MUTATION, {errorPolicy: 'all'});
  const history = useHistory();
  if (loading) {
    return <EpackLoader loaderType="ballcliprotate"/>
  }
  if (deleteLastWaveLoading) {
    return <EpackLoader loaderType="ballcliprotate"/>
  }
  if (appendDataLastWaveLoading) {
    return <EpackLoader loaderType="ballcliprotate"/>
  }
  return (
    <div className="btn-group ml-1 pb-1">
            <span className="pl8 display-flex">
            <EPackDropdown className="project-configuration-dropdown">
                <Dropdown.Toggle variant="outline-primary">
                    <FontAwesomeIcon icon={faCog} className="button-icon"/>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-options">
                                        <Dropdown.Item className=""
                                                       href={'/project-results/' + project.id}>
                <FontAwesomeIcon icon={faTasks}
                                 className="button-icon"/>Results
            </Dropdown.Item>
                                                          <Dropdown.Item className=""
                                                                         href={'/wave-stats/' + project.waveDataInfo.currentWave.pk}>
                <FontAwesomeIcon icon={faClock}
                                 className="button-icon"/>Wave performance
            </Dropdown.Item>
            <Dropdown.Item className=""
                           onClick={() => handleMakeWaveUrgent(project.waveDataInfo.currentWave.pk, !project.waveDataInfo.currentWave.isUrgent)
                           }>
                <FontAwesomeIcon icon={faExclamationTriangle}
                                 className="button-icon"/>{project.waveDataInfo.currentWave.isUrgent ? 'Mark as unimportant' : 'Mark as urgent'}
            </Dropdown.Item>
                    <Dropdown.Item onClick={() => WarningAlert.fire({
                      title: "Beware!",
                      text: "Are you sure you want to re-initiate image downloads for following project ?\n This option should be used only if wave progress is stuck!",
                      showCancelButton: true,
                      confirmButtonText: "Re-initiate",
                      cancelButtonText: "No",
                      allowOutsideClick: false,
                    }).then((reInitiateSelected) => {
                      if (reInitiateSelected.isConfirmed) {
                        reInitializeImageDownloads({variables: {"current_wave_id": project.waveDataInfo.currentWave.pk}}
                        ).then(({data}) => {
                          const validation_errors = data.reInitializeImageDownloads.errors;
                          if (validation_errors != null && validation_errors.length !== 0) {
                            Alert.fire("Error has occurred:", validation_errors[0].messages[0].replace("GraphQL error: ", "").trim(), "error");
                          } else {
                            SuccessAlert.fire("Image downloads and comparison process have been re-initiated.").then((ok) => {
                              history.go(0);
                            });
                          }
                        })
                          .catch(error => {
                            // Catch Promise.
                            // TODO Add 500 component.
                            Alert.fire("Error has occurred:", error.message.replace("GraphQL error: ", "").trim(), "error");
                          })
                      }
                    })
                    }>
                        <FontAwesomeIcon icon={faBalanceScale} className="button-icon"/>Re-initiate
                    </Dropdown.Item>
                                        <Dropdown.Item onClick={() => InfoAlert.fire({
                                          title: "Generate XLSX report for last wave",
                                          text: "Report will provide information about all comparisons, which were not successful.",
                                          showCancelButton: false,
                                          confirmButtonText: "Generate report",
                                          cancelButtonText: "No",
                                          allowOutsideClick: true,
                                        }).then((generateReportSelected) => {
                                          if (generateReportSelected.isConfirmed) {
                                            const reportUrl = window.location.protocol + "//" + window.location.hostname + '/comparisons/failed-comparisons-report/' + project.id + '/'
                                            // const reportUrl = process.env.REACT_APP_ROOT_HOST_URL + '/comparisons/failed-comparisons-report/' + project.id + '/'
                                            window.open(reportUrl, "_blank")
                                          }
                                        })
                                        }>
                        <FontAwesomeIcon icon={faFileExcel} className="button-icon"/>Generate report
                    </Dropdown.Item>
                  <Dropdown.Item className="button-icon" onClick={() => WarningAlert.fire({
                      title: "Append data to wave",
                      text: "Wave will be set for importing state. Are you sure ?",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                      allowOutsideClick: false,
                    }).then((appendDataWaveSelected) => {
                      if (appendDataWaveSelected.isConfirmed) {
                        appendDataLastWave({variables: {"current_wave_id": project.waveDataInfo.currentWave.pk}}
                        ).then(({data}) => {
                          const validation_errors = data.appendDataLastWave.errors;
                          if (validation_errors != null && validation_errors.length !== 0) {
                            Alert.fire("Error has occurred:", validation_errors[0].messages[0].replace("GraphQL error: ", "").trim(), "error");
                          } else {
                            SuccessAlert.fire("Wave was set for importing. Now you can add additional products to wave.").then((ok) => {
                              history.go(0);
                            });
                          }
                        })
                          .catch(error => {
                            // Catch Promise.
                            // TODO Add 500 component.
                            Alert.fire("Error has occurred:", error.message.replace("GraphQL error: ", "").trim(), "error");
                          })
                      }
                    })
                    }>
    <FontAwesomeIcon icon={faPlusCircle} className="button-icon"/>Append data
</Dropdown.Item>
                    <Dropdown.Item className="button-icon" onClick={() => InfoAlert.fire({
                      title: "Delete last wave",
                      text: "Are you sure you want to delete last wave for following project ?",
                      showCancelButton: true,
                      confirmButtonText: "Delete",
                      cancelButtonText: "No",
                      allowOutsideClick: false,
                    }).then((deleteWaveSelected) => {
                      if (deleteWaveSelected.isConfirmed) {
                        deleteLastWave({variables: {"projectId": project.id}}
                        ).then(({data}) => {
                          const validation_errors = data.deleteLastWave.errors;
                          if (validation_errors != null && validation_errors.length !== 0) {
                            Alert.fire("Error has occurred:", validation_errors[0].messages[0].replace("GraphQL error: ", "").trim(), "error");
                          } else {
                            SuccessAlert.fire("Last wave for project was deleted. Such operation may take several minutes.").then((ok) => {
                              history.go(0);
                            });
                          }
                        })
                          .catch(error => {
                            // Catch Promise.
                            // TODO Add 500 component.
                            Alert.fire("Error has occurred:", error.message.replace("GraphQL error: ", "").trim(), "error");
                          })
                      }
                    })
                    }>
    <FontAwesomeIcon icon={faTimesCircle} className="button-icon"/>Delete last wave
</Dropdown.Item>
                </Dropdown.Menu>
            </EPackDropdown>
        </span>
    </div>
  )
}


interface ProjectTasksDropdownToggleProps {
  accordionKey: number,
}

function ProjectTasksDropdownToggle({accordionKey}: ProjectTasksDropdownToggleProps) {
  const isToggled = useContext(AccordionContext) === accordionKey.toString();

  return (
    <Accordion.Toggle
      as={Button}
      variant="link"
      eventKey={accordionKey.toString()}
      className="project-accordion-toggle"
      onClick={() => {
        document.dispatchEvent(new Event('toggle_' + accordionKey.toString()))
      }}
    >
      {
        isToggled ?
          <FontAwesomeIcon icon={faChevronUp} size="2x"/> :
          <FontAwesomeIcon icon={faChevronDown} size="2x"/>
      }
    </Accordion.Toggle>
  )
}


function ProjectTasksInfo({project}: SingleProjectProps) {

  return (
    <div className="data-row tasks-info">
      {project.waveDataInfo.taskStats.unassigned !== 0 ? <><span
        className="project-tasks-unassigned">Unassigned {project.waveDataInfo.taskStats.unassigned}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</> : <></>}
      {project.waveDataInfo.taskStats.inProgress !== 0 ? <><span
        className="project-tasks-in-progress">In progress {project.waveDataInfo.taskStats.inProgress}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</> : <></>}
      {project.waveDataInfo.taskStats.todo !== 0 ? <><span
        className="project-tasks-todo">Todo {project.waveDataInfo.taskStats.todo}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</> : <></>}
      {project.waveDataInfo.taskStats.paused !== 0 ? <><span
        className="project-tasks-paused">Paused {project.waveDataInfo.taskStats.paused}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</> : <></>}
      {project.waveDataInfo.taskStats.done !== 0 ? <><span
        className="project-tasks-done">Done {project.waveDataInfo.taskStats.done}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</> : <></>}
      {project.waveDataInfo.taskStats.total !== 0 ? <><span
        className="project-tasks-total">Total {project.waveDataInfo.taskStats.total}</span></> : <><span
        className="project-tasks-total">No tasks found</span></>}
    </div>
  )
}


function ProjectButtons({project}: SingleProjectProps) {
  return (
    <span className="col display-flex">
            <span className="pr10">
                <EPackDropdown className="project-configuration-dropdown">
                <Dropdown.Toggle variant="outline-primary">
                    <FontAwesomeIcon icon={faPoll} className="button-icon"/>Statistics
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-options">
                    <Dropdown.Item href={'/user-stats/' + project.waveDataInfo.currentWave.pk}>
                        <FontAwesomeIcon icon={faUserClock} className="button-icon"/>Auditors work time
                    </Dropdown.Item>
                    <Dropdown.Item href={'/wave-stats/' + project.waveDataInfo.currentWave.pk}>
                        <FontAwesomeIcon icon={faClock} className="button-icon"/>Wave performance stats
                    </Dropdown.Item>

                </Dropdown.Menu>

            </EPackDropdown>
            </span>
            <span className="pr10">
                <EPackButton href={'/project-results/' + project.id} label='Results' icon={faTasks}
                             variant="outline-primary"/>
            </span>
        </span>
  )
}


function ProjectConfigurationDropdown({project, handleMakeWaveUrgent}: SingleProjectProps) {
  const [setPublicWaveStatus, {loading}] = useMutation(SET_PUBLIC_WAVE_STATUS_MUTATION, {errorPolicy: 'all'});
  const history = useHistory();
  const isPublicText = project.waveDataInfo.public ? "unpublish" : "publish"
  if (loading) {
    return <EpackLoader loaderType="ballcliprotate"/>
  }
  const publishProject = () => {
    WarningAlert.fire({
      title: isPublicText.charAt(0).toUpperCase() + isPublicText.slice(1) + " project ",
      text: "Are you sure you want to " + isPublicText + " project?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
    }).then((setPublicSelected) => {
      if (setPublicSelected.isConfirmed) {
        setPublicWaveStatus({variables: {"current_wave_id": project.waveDataInfo.currentWave.pk}}).then(({data}) => {
          const validation_errors = data.setPublicWaveStatus.errors;
          if (validation_errors != null && validation_errors.length !== 0) {
            Alert.fire("Error has occurred:", validation_errors[0].messages[0].replace("GraphQL error: ", "").trim(), "error");
          } else {
            if (project.waveDataInfo.public) {
              SuccessAlert.fire("Project has been unpublished.").then((ok) => {
                history.go(0);
              });
            } else {
              SuccessAlert.fire("Project has been set as public.").then((ok) => {
                history.go(0);
              });
            }
          }
        })
          .catch(error => {
            // Catch Promise.
            Alert.fire("Error has occurred:", error.message.replace("GraphQL error: ", "").trim(), "error");
          });
      } else {
        history.go(0);
      }
    })
  }
  return (
    <span className="pl10 display-flex">
            <EPackDropdown className="project-configuration-dropdown">
                <Dropdown.Toggle variant={project.waveDataInfo.currentWave.isUrgent ? "outline-primary" : "light"}>
                    <FontAwesomeIcon icon={faCog} className="button-icon"/>Configuration
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-options">
                    <CreateCustomTaskItem project={project}/>
                    <Dropdown.Item href={'/update-project/' + project.id}>
                        <FontAwesomeIcon icon={faEdit} className="button-icon"/>Edit project
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => InfoAlert.fire({
                      title: "Generate XLSX report for last wave",
                      text: "Report will provide information about all comparisons, which were not successful.",
                      showCancelButton: false,
                      confirmButtonText: "Generate report",
                      cancelButtonText: "No",
                      allowOutsideClick: true,
                    }).then((generateReportSelected) => {
                      if (generateReportSelected.isConfirmed) {
                        const reportUrl = window.location.protocol + "//" + window.location.hostname + '/comparisons/failed-comparisons-report/' + project.id + '/'
                        // const reportUrl = process.env.REACT_APP_ROOT_HOST_URL + '/comparisons/failed-comparisons-report/' + project.id + '/'
                        window.open(reportUrl, "_blank")
                      }
                    })
                    }>
                        <FontAwesomeIcon icon={faFileExcel} className="button-icon"/>Generate report
                    </Dropdown.Item>
                  {project.waveDataInfo.public ?
                    <Dropdown.Item className={!project.waveDataInfo.currentWave.alterable ? "disabled-configuration-link" : ""}
                                   onClick={() => publishProject()}>
                      <FontAwesomeIcon icon={faNewspaper} className="button-icon"/>Unpublish
                    </Dropdown.Item> :
                    <Dropdown.Item className={!project.waveDataInfo.currentWave.alterable ? "disabled-configuration-link" : ""}
                                   onClick={() => publishProject()}>
                      <FontAwesomeIcon icon={faNewspaper} className="button-icon"/>Publish
                    </Dropdown.Item>}

                  <Dropdown.Divider/>

                    <RetryWaveItem project={project} handleMakeWaveUrgent={handleMakeWaveUrgent}/>

                </Dropdown.Menu>

            </EPackDropdown>
        </span>
  )
}


function CreateCustomTaskItem({project}: SingleProjectProps) {

  const shopOptions = project.waveDataInfo.shops.map((shop: any) => {
    return {value: shop, label: shop};
  });

  interface selectOptions {
    label: string
    value: string
  }

  const algorithmAnswerOptions = [
    {value: 30, label: 'Not sure'},
    {value: 10, label: 'True'},
    {value: 20, label: 'False'},
  ]

  const [modalShow, setModalShow] = useState(false);
  const toggleModalShow = () => setModalShow(!modalShow);
  const [validated, setValidated] = useState(false);

  const [name, setName] = useState('');
  const [assignee, setAssignee] = useState<string>('');
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [product_codes, setProductCodes] = useState('');
  const [shops, setShops] = useState<object>([]);
  const [packshotTypes, setPackshotTypes] = useState<object>([]);
  const [packshotTypeOptions, setPackshotTypeOptions] = useState([]);
  const [auditorsInvolved, setAuditorsInvolved] = useState<object>([]);
  const [auditorsInvolvedOptions, setAuditorsInvolvedOptions] = useState([]);
  const [algorithmAnswers, setAlgorithmAnswers] = useState<object>([]);
  const history = useHistory();

  const [isUrgentChecked, setIsUrgentChecked] = useState(false);
  const toggleIsUrgentChecked = () => setIsUrgentChecked(!isUrgentChecked);

  const [isChangedChecked, setIsChangedChecked] = useState(false);
  const toggleIsChangedChecked = () => setIsChangedChecked(!isChangedChecked);

  const [changedAnswerChecked, setChangedAnswerChecked] = useState(false);
  const toggleChangedAnswer = () => setChangedAnswerChecked(!changedAnswerChecked);


  const [sliderValue, setSliderValue] = useState(10);
  // Get comparisons and users list.
  const [getComparisonData, {data: comparisonData}] = useLazyQuery(COMPARISON_GET_BY_PROJECT_ID_QUERY);
  // Create task mutation.
  const [
    createTask,
    {data, error: mutationError, loading: mutationLoading},
  ] = useMutation(TASK_CREATE_TASK_QUERY, {errorPolicy: 'all'});
  let errors: { [msg: string]: string } = {};


  useEffect(() => {
    const fetchPackshotTypesAndAssignee = async () => {
      // @ts-ignore
      const shopArray = shops.map(({value}) => value)
      getComparisonData({
        variables: {
          projectId: project.id,
          shops: shopArray
        }
      })
      if (comparisonData && comparisonData.packshotTypes) {
        setPackshotTypeOptions(comparisonData.packshotTypes);
      }
      if (comparisonData && comparisonData.auditors) {
        setAssigneeOptions(comparisonData.auditors);
      }
      if (comparisonData && comparisonData.auditorsInvolved) {
        setAuditorsInvolvedOptions(comparisonData.auditorsInvolved);
      }
    };
    fetchPackshotTypesAndAssignee();
  }, [shops, comparisonData, getComparisonData, project.id]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // @ts-ignore
    const packshotTypesMapped = packshotTypes.map(({value}) => value)
    // @ts-ignore
    const algorithmAnswersMapped = algorithmAnswers.map(({value}) => value)
    // @ts-ignore
    const shopsMapped = shops.map(({value}) => value)
    // @ts-ignore
    const auditorsInvolvedMapped = auditorsInvolved.map(({value}) => value)
    createTask({
      variables: {
        customName: name,
        productCodes: product_codes,
        projectId: project.id,
        assignee: assignee || null,
        comparisonsPercent: sliderValue,
        isUrgent: isUrgentChecked,
        isChanged: isChangedChecked,
        changedAnswer: changedAnswerChecked,
        shops: shopsMapped,
        packshotTypes: packshotTypesMapped,
        algorithmAnswers: algorithmAnswersMapped,
        auditorsInvolved: auditorsInvolvedMapped
      }
    })
      .then(({data}) => {
        const validation_errors = data.createTask.errors;
        if (validation_errors != null && validation_errors.length !== 0) {
          setValidated(false);
        } else {
          if (data.createTask.task === undefined || data.createTask.task === null) {
           InfoAlert.fire("There are no comparisons to fulfil requirement. Task will not be created.").then((ok) => {
            toggleModalShow()
            history.go(0);
          });
          } else {
          SuccessAlert.fire("Task has been created.").then((ok) => {
            toggleModalShow()
            history.go(0);
          });
          }
        }
      })
      .catch(error => {
        // Catch Promise.
        Alert.fire("Error has occurred:", error.message.replace("GraphQL error: ", "").trim(), "error");
      })
    e.preventDefault();
  };
  if (mutationError) {
    Alert.fire("There is something went wrong...", "Please contact administrator.", "error");
    // TODO For production environment error codes could be added (user friendly).
    return <div>{mutationError && <p>Error : <b>{mutationError.message}</b></p>}</div>;
  }

  if (data) {
    const validation_errors = data.createTask.errors;
    if (validation_errors != null && validation_errors.length !== 0) {
      for (const err of validation_errors) errors[err.field] = err.messages[0];
    }
  }

  const className = project.waveDataInfo.public ? "disabled-configuration-link" : ""

  // This is workaround  for react Select, which could not be validated by Form.Control.
  // Changes border color of select.
  const customStyles = (hasError: boolean) => ({
    control: (styles: any) => ({
      ...styles,
      ...(hasError && {borderColor: 'red'}),
    }),
  });

  return (
    <>
      <Dropdown.Item className={className} onClick={toggleModalShow}>
        <FontAwesomeIcon icon={faPlus} className="button-icon"/>Create new task
      </Dropdown.Item>

      <Modal size="xl" show={modalShow} onHide={toggleModalShow}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create custom task</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 pl0 border-right">
                  {/* * Name */}
                  <Form.Group>
                    <Form.Label>Name:</Form.Label>
                    <Form.Control type="text" placeholder="Task name"
                                  isInvalid={Boolean(errors['custom_name'])}
                                  onChange={
                                    (e) => setName(e.target.value)}/>
                    {errors['custom_name'] && (
                      <Form.Control.Feedback type="invalid">
                        {errors['custom_name']}
                      </Form.Control.Feedback>)}
                  </Form.Group>
                  {/* * Assignee */}
                  <Form.Group>
                    {/* TODO: More aesthetic select. */}
                    {/* TODO: Null option */}
                    <Form.Label>Assignee:</Form.Label>
                    <Select
                      styles={customStyles(errors.hasOwnProperty("assignee")
                      )}
                      isClearable
                      name="assigneeSelect"
                      options={assigneeOptions}
                      placeholder="Select assignee..."
                      className="assignee-select"
                      classNamePrefix="select"
                      onChange={(val: any) => {
                        if (val !== null) {
                          setAssignee(val.value)
                        } else {
                          setAssignee('')
                        }
                      }}
                    />
                    <div className="select-validation-error">
                      {errors['assignee'] && (
                        errors['assignee'])}</div>
                  </Form.Group>
                  {/* * Auditors involved */}
                  <Form.Group>
                    {/* TODO: More aesthetic select. */}
                    <Form.Label>Auditors involved:</Form.Label>
                    <Select
                      styles={customStyles(errors.hasOwnProperty("auditors_involved")
                      )}
                      isMulti
                      isClearable
                      name="auditorsInvolvedSelect"
                      options={auditorsInvolvedOptions}
                      placeholder=""
                      className="auditors-involved-select"
                      classNamePrefix="select"
                      onChange={(val: any) => {
                        setAuditorsInvolved(val as selectOptions || [])
                      }}
                    />
                    <div className="select-validation-error">
                      {errors['auditors_involved'] && (
                        errors['auditors_involved'])}</div>
                  </Form.Group>
                  {/* * Product Codes */}
                  <Form.Group>
                    <Form.Label>Product codes:</Form.Label>
                    <Form.Control type="text" placeholder="Comma separated product codes"
                                  isInvalid={Boolean(errors['product_codes'])}
                                  onChange={
                                    (e) => setProductCodes(e.target.value)}/>
                    {errors['product_codes'] && (
                      <Form.Control.Feedback type="invalid">
                        {errors['product_codes']}
                      </Form.Control.Feedback>)}
                  </Form.Group>
                  {/* * Comparison percentage */}
                  <Form.Label>How many comparisons:</Form.Label>
                  <Form.Group as={Row}>
                    <Col xs="3">
                      <Form.Control value={`${sliderValue}%`}/>
                    </Col>
                    <Col xs="9">
                      <RangeSlider
                        value={sliderValue}
                        min={1}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          setSliderValue(parseInt(event.target.value))}
                        tooltipLabel={(sliderValue: number) => `${sliderValue}%`}

                      />
                    </Col>
                  </Form.Group>
                  {/* * Is urgent */}
                  <Form.Group>
                    <Form.Check type="checkbox"
                                label="Is urgent"
                                isInvalid={Boolean(errors['is_urgent'])}
                                onClick={toggleIsUrgentChecked}
                    />
                    <div className="select-validation-error">
                      {errors['is_urgent'] && (
                        errors['is_urgent'])}</div>
                  </Form.Group>
                </div>
                <div className="col-6 pr0">
                  {/* * Shops */}
                  <Form.Group>
                    {/*
                                        TODO: More aesthetic select.
                                        TODO: There are many options for it, such as react-select or react-bootstrap-typeahead,
                                        TODO: but it is very hard to make them required.
                                        */}
                    <Form.Label>Shops:</Form.Label>
                    <Select
                      styles={customStyles(errors.hasOwnProperty("shops")
                      )}
                      isMulti
                      isClearable
                      name="shopsSelect"
                      options={shopOptions}
                      placeholder="Leave empty to pick all..."
                      className="shops-select"
                      classNamePrefix="select"
                      onChange={(val: any) => {
                        setShops(val as selectOptions || [])
                      }}
                    />
                    <div className="select-validation-error">
                      {errors['shops'] && (
                        errors['shops'])}</div>
                  </Form.Group>
                  {/* * Packshot types */}
                  <Form.Group>
                    {/* TODO: More aesthetic select. */}
                    <Form.Label>Packshot types:</Form.Label>
                    <Select
                      styles={customStyles(errors.hasOwnProperty("packshot_types")
                      )}
                      isMulti
                      isClearable
                      name="packshotTypesSelect"
                      options={packshotTypeOptions}
                      placeholder="Leave empty to pick all..."
                      className="packshot-types-select"
                      classNamePrefix="select"
                      onChange={(val: any) => {
                        setPackshotTypes(val as selectOptions || [])
                      }}
                    />
                    <div className="select-validation-error">
                      {errors['packshot_types'] && (
                        errors['packshot_types'])}</div>
                  </Form.Group>
                  {/* * Algorithm answers */}
                  <Form.Group>
                    {/* TODO: More aesthetic select. */}
                    <Form.Label>Algorithm answers:</Form.Label>
                    <Select
                      styles={customStyles(errors.hasOwnProperty("algorithm_answers")
                      )}
                      isMulti
                      isClearable
                      name="algorithmAnswersSelect"
                      options={algorithmAnswerOptions}
                      placeholder="Leave empty to pick all..."
                      className="algorithm-answers-select"
                      classNamePrefix="select"
                      onChange={(val: any) => {
                        setAlgorithmAnswers(val as selectOptions || [])
                      }}
                    />
                    <div className="select-validation-error">
                      {errors['algorithm_answers'] && (
                        errors['algorithm_answers'])}</div>
                  </Form.Group>
                  {/* * Take only changed comparisons */}
                  <Form.Group>
                    <Form.Check type="checkbox"
                                label="Take only changed comparisons"
                                isInvalid={Boolean(errors['is_changed'])}
                                onClick={toggleIsChangedChecked}
                    />
                    <div className="select-validation-error">
                      {errors['is_changed'] && (
                        errors['is_changed'])}</div>
                  </Form.Group>
                  {/* * Take only comparisons with changed answer vs previous wave */}
                  <Form.Group>
                    <Form.Check type="checkbox"
                                label="Take only comparisons with changed answer vs previous wave"
                                isInvalid={Boolean(errors['changed_answer'])}
                                onClick={toggleChangedAnswer}
                    />
                    <div className="select-validation-error">
                      {errors['changed_answer'] && (
                        errors['changed_answer'])}</div>
                  </Form.Group>

                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" type="submit">Save</Button>
          </Modal.Footer>
          <div>{mutationLoading && (<EpackLoader loaderType="ballcliprotate"/>)}</div>
        </Form>
      </Modal>
    </>
  )
}


function RetryWaveItem({project, handleMakeWaveUrgent}: SingleProjectProps) {

  // Mapping shops for options in select input.
  // @ts-ignore
  const shopOptions = project.waveDataInfo.shops.map((shop: any) => {
    return {value: shop, name: shop};
  });
  const [deleteLastWave, {loading}] = useMutation(WAVE_DELETE_MUTATION, {errorPolicy: 'all'});
  const [appendDataLastWave, {loading: appendDataLastWaveLoading}] = useMutation(WAVE_APPEND_DATA_MUTATION, {errorPolicy: 'all'});

  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const toggleModalShow = () => setModalShow(!modalShow);

  const [shopList, setShopList] = useState([]);
  const projectId = project.id

  const addShop = (event: any) => {
    event.preventDefault();

    const selectedHashTags = Array.from(event.target.options)
      .reduce((selectedShops, selectedShop) => {
        // @ts-ignore
        if (selectedShop.selected) {
          // @ts-ignore
          selectedShops.push(selectedShop.value);
        }
        return selectedShops;
      }, []);

    // @ts-ignore
    setShopList(selectedHashTags)
  }
  const [validated, setValidated] = useState(false);
  const [
    retryWave,
    {loading: retryWaveLoading},
  ] = useMutation(RETRY_WAVE_MUTATION, {errorPolicy: 'all'});

  if (loading) {
    return <EpackLoader loaderType="ballcliprotate"/>
  }
  const handleSubmit = (event: FormEvent) => {
    const form = event.currentTarget as HTMLInputElement;
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      retryWave({
        variables: {
          projectId,
          shopList
        },
      }).then(({data}) => {
        const validation_errors = data.retryWave.errors;
        if (validation_errors != null && validation_errors.length !== 0) {
          setValidated(false);
          Alert.fire("Error has occurred:", validation_errors[0].messages[0].replace("GraphQL error: ", "").trim(), "error");
        } else {
          SuccessAlert.fire("Wave has been retried.").then((ok) => {
            history.go(0);
          });
        }
      })
        .catch(e => {
          setValidated(false);
          Alert.fire("Error has occurred.", e.message.replace("GraphQL error: ", "").trim(), "error");
        })
      toggleModalShow()
      event.preventDefault();
    }
    setValidated(true);
  };
  const className = project.waveDataInfo.public ? "disabled-configuration-link" : ""

  return (
    <>
      <Dropdown.Item className={className}
                     onClick={() => handleMakeWaveUrgent(project.waveDataInfo.currentWave.pk, !project.waveDataInfo.currentWave.isUrgent)
                     }>
        <FontAwesomeIcon icon={faExclamationTriangle}
                         className="button-icon"/>{project.waveDataInfo.currentWave.isUrgent ? 'Mark as unimportant' : 'Mark as urgent'}
      </Dropdown.Item>
      <Dropdown.Item className={className} onClick={toggleModalShow}>
        <FontAwesomeIcon icon={faRedo} className="button-icon"/>Retry wave
      </Dropdown.Item>
      <Dropdown.Item className="button-icon" onClick={() => InfoAlert.fire({
        title: "Append data to wave",
        text: "Wave will be set for importing state. Are you sure ?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
      }).then((appendDataWaveSelected) => {
        if (appendDataWaveSelected.isConfirmed) {
          appendDataLastWave({variables: {"current_wave_id": project.waveDataInfo.currentWave.pk}}
          ).then(({data}) => {
            const validation_errors = data.appendDataLastWave.errors;
            if (validation_errors != null && validation_errors.length !== 0) {
              Alert.fire("Error has occurred:", validation_errors[0].messages[0].replace("GraphQL error: ", "").trim(), "error");
            } else {
              SuccessAlert.fire("Wave was set for importing. Now you can add additional products to wave.").then((ok) => {
                history.go(0);
              });
            }
          })
            .catch(error => {
              // Catch Promise.
              // TODO Add 500 component.
              Alert.fire("Error has occurred:", error.message.replace("GraphQL error: ", "").trim(), "error");
            })
        }
      })
      }>
        <FontAwesomeIcon icon={faPlusCircle} className="button-icon"/>Append data
      </Dropdown.Item>
      <Dropdown.Item className={className} onClick={() => WarningAlert.fire({
        title: "Delete last wave",
        text: "Are you sure you want to delete last wave for following project ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "No",
        allowOutsideClick: false,
      }).then((deleteWaveSelected) => {
        if (deleteWaveSelected.isConfirmed) {
          deleteLastWave({variables: {"projectId": project.id}}
          ).then(({data}) => {
            const validation_errors = data.deleteLastWave.errors;
            if (validation_errors != null && validation_errors.length !== 0) {
              Alert.fire("Error has occurred:", validation_errors[0].messages[0].replace("GraphQL error: ", "").trim(), "error");
            } else {
              SuccessAlert.fire("Last wave for project was deleted. Such operation may take several minutes.").then((ok) => {
                history.go(0);
              });
            }
          })
            .catch(error => {
              // Catch Promise.
              // TODO Add 500 component.
              Alert.fire("Error has occurred:", error.message.replace("GraphQL error: ", "").trim(), "error");
            })
        }
      })
      }>
        <FontAwesomeIcon icon={faTimesCircle} className="button-icon"/>Delete last wave
      </Dropdown.Item>

      <Modal show={modalShow} onHide={toggleModalShow}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Retry wave</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              {/*
                                                TODO: More aesthetic select.
                                                TODO: There are many options for it, such as react-select or react-bootstrap-typeahead,
                                                TODO: but it is very hard to make them required.
                                                */}
              <Form.Label>Select shops:</Form.Label>

              <Form.Control required as="select" multiple onChange={addShop}>
                {
                  // @ts-ignore
                  shopOptions.map((option, index) => {
                    return (
                      <option key={index} value={option.name}>{option.name}</option>
                    )
                  })
                }
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select at least one shop
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {retryWaveLoading && (<EpackLoader loaderType="ballcliprotate"/>)}
    </>
  );
}


export default ProjectListView;
