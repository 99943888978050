import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {useQuery} from "@apollo/react-hooks";
import {Alert} from "constants/swal";
import {PROJECT_WAVE_STATS_QUERY} from 'queries/stats';
import {Col, Container, Row} from "react-bootstrap";
import './ProjectWaveStats.css'
import EpackLoader from 'components/loaders/Loaders';
import {faEquals, faExclamationTriangle, faEdit, faSpinner, faFileImport, faBrain, faCopy, faQuestionCircle, faTasks, faHourglass, faUndo, faSearch, faClock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


interface projectsWavesStatsSection {
    comparisonsCount: number,
    failedComparisonsCount: number,
    comparisonsNotSureCount: number,
    comparisonsIsChangedCount: number,
    waveImportTime: string,
    waveWaitingToStartTime: string,
    waveInProgressTime: string,
    waveTasksCreationTime: string,
    waveAlgorithmCompareTime: string,
    waveComparisonsCopyTime: string
    waveAuditingTime: string
    waveProcessingTime: string
    waveNumberOfCycles: number
}


function ProjectUserStatsView(props: any) {
    const {
        loading: projectWaveStatsLoading,
        error: projectWaveStatsError,
        data: projectWaveStatsData
    } = useQuery(PROJECT_WAVE_STATS_QUERY, {
        variables: {
            waveId: props.match.params.id,
        }
    });
    if (projectWaveStatsLoading) {
        return <EpackLoader/>
    }
    if (projectWaveStatsError) {
        Alert.fire(projectWaveStatsError.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
        return <div>Error: {projectWaveStatsError.message}</div>;
    }

    return (
        <Container className="main-container container-white">
            <Container className="stats-header">
                <Row>
                    <Col>
                        <h2><b>Wave performance statistics</b></h2>
                        <h5>Wave: {projectWaveStatsData.wave.fullName}</h5>
                    </Col>
                </Row>
            </Container>
            <hr/>

                <Row>
                    <Col>
                        <ProjectWaveStatsValues comparisonsCount={projectWaveStatsData.projectWaveStats.comparisonsCount}
                         comparisonsIsChangedCount={projectWaveStatsData.projectWaveStats.comparisonsIsChangedCount} failedComparisonsCount={projectWaveStatsData.projectWaveStats.failedComparisonsCount} comparisonsNotSureCount={projectWaveStatsData.projectWaveStats.comparisonsNotSureCount} waveAlgorithmCompareTime={projectWaveStatsData.projectWaveStats.waveAlgorithmCompareTime} waveComparisonsCopyTime={projectWaveStatsData.projectWaveStats.waveComparisonsCopyTime} waveWaitingToStartTime={projectWaveStatsData.projectWaveStats.waveWaitingToStartTime} waveImportTime={projectWaveStatsData.projectWaveStats.waveImportTime} waveInProgressTime={projectWaveStatsData.projectWaveStats.waveInProgressTime} waveNumberOfCycles={projectWaveStatsData.projectWaveStats.waveNumberOfCycles} waveTasksCreationTime={projectWaveStatsData.projectWaveStats.waveTasksCreationTime} waveProcessingTime={projectWaveStatsData.projectWaveStats.waveProcessingTime} waveAuditingTime={projectWaveStatsData.projectWaveStats.waveAuditingTime}/>
                    </Col>
                    <hr/>
                </Row>
        </Container>
    )
}


function ProjectWaveStatsValues(stats: projectsWavesStatsSection) {
    return (
        <Container className="project-user-stats text-center">
            <Container className="stats-single-box">
                <Row><Col className="h5 text-left text-muted"><FontAwesomeIcon icon={faEquals} className="comparisons-count-icon"/>Comparisons count</Col><Col className="project-user-stats-values">{stats.comparisonsCount}</Col></Row>
                <Row><Col className="h5 text-left text-muted"><FontAwesomeIcon icon={faExclamationTriangle} className="comparisons-failed-count-icon"/>Failed comparisons count</Col><Col className="project-user-stats-values">{stats.failedComparisonsCount}</Col></Row>
                <Row><Col className="h5 text-left text-muted"><FontAwesomeIcon icon={faQuestionCircle} className="comparisons-not-sure-count-icon"/> Not answered comparisons count</Col><Col className={stats.comparisonsNotSureCount ? "project-user-stats-values-danger" : "project-user-stats-values"}>{stats.comparisonsNotSureCount}</Col></Row>
                <Row><Col className="h5 text-left text-muted"><FontAwesomeIcon icon={faEdit} className="comparisons-changed-count-icon"/>Changed comparisons count</Col><Col className="project-user-stats-values">{stats.comparisonsIsChangedCount}</Col></Row>
                <Row><Col className="h5 text-left text-muted"><FontAwesomeIcon icon={faFileImport} className="comparisons-import-time-icon"/>Comparisons import from mapet time</Col><Col className="project-user-stats-values">{stats.waveImportTime}</Col></Row>
                <Row><Col className="h5 text-left text-muted"><FontAwesomeIcon icon={faHourglass} className="comparisons-copy-time-icon"/>Waiting for wave to start time</Col><Col className="project-user-stats-values">{stats.waveWaitingToStartTime}</Col></Row>
                <Row><Col className="h5 text-left text-muted"><FontAwesomeIcon icon={faSpinner} className="wave-in-progress-icon"/>Wave in progress time</Col><Col className="project-user-stats-values">{stats.waveInProgressTime}</Col></Row>
                <Row><Col className="h5 text-left text-muted"><FontAwesomeIcon icon={faTasks} className="tasks-creation-icon"/>Tasks creation time</Col><Col className="project-user-stats-values">{stats.waveTasksCreationTime}</Col></Row>
                <Row><Col className="h5 text-left text-muted"><FontAwesomeIcon icon={faSearch} className="comparisons-changed-count-icon"/>Auditing time</Col><Col className="project-user-stats-values">{stats.waveAuditingTime}</Col></Row>
                <Row><Col className="h5 text-left text-muted"><FontAwesomeIcon icon={faClock} className="comparisons-failed-count-icon"/>Total wave processing time</Col><Col className="project-user-stats-values">{stats.waveProcessingTime}</Col></Row>
                <Row><Col className="h5 text-left text-muted"><FontAwesomeIcon icon={faUndo} className="comparisons-not-sure-count-icon"/>Number of wave cycles</Col><Col className={stats.waveNumberOfCycles > 1 ? "project-user-stats-values-warning" : "project-user-stats-values"}>{stats.waveNumberOfCycles}</Col></Row>
                <Row><Col className="h5 text-left text-muted"><FontAwesomeIcon icon={faBrain} className="comparisons-algorithm-compare-icon "/>Average algorithm compare time</Col><Col className="project-user-stats-values">{stats.waveAlgorithmCompareTime}</Col></Row>
                <Row><Col className="h5 text-left text-muted"><FontAwesomeIcon icon={faCopy} className="comparisons-copy-time-icon"/>Average comparisons copy time</Col><Col className="project-user-stats-values">{stats.waveComparisonsCopyTime}</Col></Row>
            </Container>
        </Container>
    )
}

export default ProjectUserStatsView;
