import React, {ChangeEvent, FormEvent, useEffect} from 'react';
import {PROJECT_UPDATE_MUTATION, PROJECT_GET_BY_ID_QUERY} from 'queries/projects';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import 'components/base/EPackForm/EPackForm.css';
import './Project.css';
import 'base.css';
import prepare_options from "components/helpers/formOptions";
import {CLIENTS_LIST_QUERY} from "queries/clients";
import {ClientInterface, LanguageInterface, ProjectInterface} from "components/helpers/interfaces";
import {Alert} from "constants/swal";
import EpackLoader from 'components/loaders/Loaders';

const {useState} = require('react');

export function UpdateProjectInitial(props: RouteComponentProps<any>) {
    const project_id = parseInt(props.match.params.id, 10);
    const {loading: queryLoading, error: queryError, data} = useQuery(PROJECT_GET_BY_ID_QUERY, {
        variables: {id: project_id},
    });
    if (queryLoading) {
        return <EpackLoader />
    }
    if (queryError) {
        Alert.fire("Ups...Project could not be found.", "Please contact administrator.", "error");
        return <p>We can not find project :(</p>;
    }

    if (data) {
        return <UpdateProject props={data.project}/>;
    }
    return <UpdateProject/>;
}

function UpdateProject(props: any) {
    // This is Update projectComponent, which is being called from UpdateProjectInitial component to omit
    // react infinite loop problem.
    // Setting initial values from props.
    const init_values = props.props;

    const id = init_values.id;
    const [name, setName] = useState(init_values.name);
    const [escProject, setEscProject] = useState(init_values.escProject);
    const [client, setClient] = useState(init_values.client.id);
    const [projectOptions, setProjectOptions] = useState({projectOpts: []});
    const [mapetProject, setMapetProject] = useState(init_values.mapetProject);
    const [languageCode, setLanguageCode] = useState(init_values.languageCode);
    const [languageOptions, setLanguageOptions] = useState({langOpts: []});
    const [algorithm,] = useState(4);
    const [config, setConfig] = useState(JSON.stringify(JSON.parse(init_values.config), undefined, 4));
    let errors: any = {};

    const [validated, setValidated] = useState(false);

    const {data: dataClient} = useQuery(CLIENTS_LIST_QUERY);

    let clientOptions = {options: []};
    if (dataClient) {
        // Map data from API.
        const clientsToRender = dataClient.clients.edges;
        let clientsToRenderMapped = clientsToRender.map((client: ClientInterface) => {
            return {
                value: client.node.id,
                label: client.node.name,
            };
        });
        clientOptions = prepare_options(clientsToRenderMapped)
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            editProject({
                variables: {
                    id, name, escProject, mapetProject, languageCode, algorithm, client, config
                },
            }).then(({data}) => {
                const validation_errors = data.editProject.errors;
                if (validation_errors != null && validation_errors.length !== 0) {
                    setValidated(false);
                }
            })
                .catch(mutationError => {
                    // Catch Promise.
                    Alert.fire("Error has occurred:", mutationError.message.replace("GraphQL error: ", "").trim(), "error");
                });
        }

        setValidated(true);
    };

    // Apennding state with choices (options) for project and language select fields.
    useEffect(() => {
        // Fetching languages from eSc i1 API (without pagination)..
        const fetchLanguageOptions = async () => {
            let allData: Array<{ [field: string]: { code: string, name: string } }> = [];
            let morePagesAvailable = true;
            let currentPage = 0;
            let stop = false;
            const targetUrl = window.location.protocol + "//" + window.location.hostname + '/api/projects/get-language-list/?token=cc0a21c03693cd69636e17005f1c6af040608a55'
            // const targetUrl = process.env.REACT_APP_ROOT_HOST_URL + '/api/projects/get-language-list/?token=cc0a21c03693cd69636e17005f1c6af040608a55'
            while (morePagesAvailable && !stop) {
                currentPage++;
                try {
                    const result = await fetch(
                        targetUrl + "&page=" + currentPage,
                    );
                    let {results, total_pages} = await result.json();
                    results.forEach((e: any) => allData.unshift(e));
                    morePagesAvailable = currentPage < total_pages;
                } catch (error) {
                    stop = true;
                    Alert.fire("Languages list could not be fetched from eSC server.", "Please check connection and try again.", "warning");
                }
            }
            // Mapping results for options in select input.
            // @ts-ignore
            let languagesFromApi = allData.sort((a, b) => a.name.localeCompare(b.name)).map((language: LanguageInterface) => {
                return {value: String(language.code).toUpperCase(), label: language.name};
            });
            if (!stop) {
                prepare_options(languagesFromApi)
                // Setting state for language options.
                setLanguageOptions(languagesFromApi);
            }
        };
        // Fetching projects from eSc i1 API (without pagination)..
        const fetchProjectOptions = async () => {
            let allData: Array<{ [field: string]: { code: string, name: string } }> = [];
            let morePagesAvailable = true;
            let currentPage = 0;
            let stop = false;
            const targetUrl = window.location.protocol + "//" + window.location.hostname + '/api/projects/get-esc-project-list/?token=cc0a21c03693cd69636e17005f1c6af040608a55'
            // const targetUrl = process.env.REACT_APP_ROOT_HOST_URL + '/api/projects/get-esc-project-list/?token=cc0a21c03693cd69636e17005f1c6af040608a55'
            while (morePagesAvailable && !stop) {
                currentPage++;
                try {
                    const result = await fetch(
                        targetUrl + "&page=" + currentPage,
                    );
                    let {results, total_pages} = await result.json();
                    results.forEach((e: any) => allData.unshift(e));
                    morePagesAvailable = currentPage < total_pages;
                } catch (error) {
                    Alert.fire("Projects could not be fetched from eSC server.", "Please check connection and try again.", "warning");
                    stop = true;
                }
            }
            // Mapping results for options in select input.
            // @ts-ignore
            let projectsFromApi = allData.sort((a, b) => a.name.localeCompare(b.name)).map((project: ProjectInterface) => {
                return {value: project.id, label: project.name};
            });

            if (!stop) {
                // TODO: general cleaning, cause its a mess in here. those localStorages and weird filtering???
                prepare_options(projectsFromApi)
                // Setting state for project options.
                setProjectOptions(prepare_options(projectsFromApi));
            }
        };
        fetchLanguageOptions();
        fetchProjectOptions();
    }, [escProject, languageCode]);

    // We are using history in order to make redirects.
    let history = useHistory();

    // Performing mutation on Project.
    const [
        editProject,
        {data, error: mutationError, loading: mutationLoading},
    ] = useMutation(PROJECT_UPDATE_MUTATION, {errorPolicy: 'all'});

    if (mutationError) {
        Alert.fire("There is something went wrong...", "Please contact administrator.", "error");
        // TODO For production environment error codes could be added (user friendly).
        return <div>{mutationError && <p>Error : <b>{mutationError.message}</b></p>}</div>;
    }

    if (data) {
        const validation_errors = data.editProject.errors;
        if (validation_errors.length !== 0) {
            for (const err of validation_errors) errors[err.field] = err.messages[0];
        } else {
            history.push('/projects');
        }
    }

    return (
        <div>
            <div className="project-form-wrapper">
                <Form
                    id="editProjectForm"
                    className="p-4"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                >
                    <p className="h4 mb-4 f-1">
                        <b>Edit project</b>
                    </p>
                    <hr/>
                    {errors['__all__'] && (
                        <div className="alert alert-danger" role="alert">
                            {errors['__all__']}
                        </div>)}
                    <Form.Group controlId="formHorizontalName">
                        <Form.Label column className="required">
                            <b>Name:</b>
                        </Form.Label>
                        <Col>
                            <Form.Control
                                required
                                title="Name"
                                placeholder="Name"
                                name="name"
                                value={name}
                                isInvalid={Boolean(errors['name'])}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                            />
                            {errors['name'] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors['name']}
                                </Form.Control.Feedback>)}
                        </Col>
                    </Form.Group>
                    <Form.Group controlId="formHorizontalProjects">
                        <Form.Label column sm={6} className="required">
                            <b>eStoreContent project:</b>
                        </Form.Label>
                        <Form.Label column sm={6} className="required">
                            <b>Mapet project ID:</b>
                        </Form.Label>
                        <Form.Group as={Row}>
                            <Col>
                                <Form.Control
                                    as="select"
                                    custom
                                    value={escProject}
                                    title="eSC project"
                                    placeholder="eSC project"
                                    name="escProject"
                                    isInvalid={Boolean(errors['esc_project'])}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setEscProject(e.target.value)}
                                    required>
                                    {projectOptions.options}
                                </Form.Control>
                                {errors['esc_project'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['esc_project']}
                                    </Form.Control.Feedback>)}
                            </Col>
                            <Col sm={6}>
                                <Form.Control
                                    required
                                    type="number"
                                    title="Mapet project ID"
                                    placeholder="Mapet project ID"
                                    name="mapetProject"
                                    value={mapetProject}
                                    isInvalid={Boolean(errors['mapet_project'])}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setMapetProject(e.target.value)}
                                />
                                {errors['mapet_project'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['mapet_project']}
                                    </Form.Control.Feedback>)}
                            </Col>
                        </Form.Group>
                    </Form.Group>
                    <Form.Group controlId="formHorizontalClientAndLanguage">
                        <Form.Label column sm={6} className="required">
                            <b>Client:</b>
                        </Form.Label>
                        <Form.Label column sm={3} className="required">
                            <b>Language:</b>
                        </Form.Label>
                        <Form.Group as={Row}>
                            <Col sm={6}>
                                <Form.Control
                                    as="select"
                                    custom
                                    value={client}
                                    title="Client"
                                    placeholder="Client"
                                    name="client"
                                    isInvalid={Boolean(errors['client'])}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setClient(e.target.value)}
                                    required
                                >
                                    {clientOptions.options}
                                </Form.Control>
                                {errors['client'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['client']}
                                    </Form.Control.Feedback>)}

                            </Col>

                            <Col sm={3}>
                                <Form.Control
                                    as="select"
                                    custom
                                    value={languageCode}
                                    title="Language"
                                    placeholder="Language"
                                    name="languageCode"
                                    isInvalid={Boolean(errors['language_code'])}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setLanguageCode(e.target.value)}
                                    required
                                >
                                    {languageOptions.options}
                                </Form.Control>
                                {errors['language_code'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['language_code']}
                                    </Form.Control.Feedback>)}
                            </Col>
                        </Form.Group>
                    </Form.Group>
                    <Form.Group controlId="formHorizontalConfig">
                        <Form.Label column>
                            <b>Config:</b>
                        </Form.Label>
                        <Col>
                            <Form.Control as="textarea" rows={10}
                                title="Config"
                                placeholder="Config"
                                name="config"
                                value={config}
                                isInvalid={Boolean(errors['config'])}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setConfig(e.target.value)}
                            />
                            {errors['config'] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors['config']}
                                </Form.Control.Feedback>)}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="create-form-submit-btn my-4">
                        <Col>
                            <Button type="submit">Save</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </div>
            <div>{mutationLoading && (<EpackLoader loaderType="ballcliprotate"/>)}</div>
        </div>
    );
}

export default UpdateProjectInitial;
