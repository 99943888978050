import { gql } from 'apollo-boost';

// Query constant for IssueListView.
// It is possible to get all users with pagination, perform search and ordering.
// TODO: status: "10" hardcoded. Status Constants from backend.
export const ISSUE_LIST_QUERY = gql`
    query IssueList($first: Int = 7, $after: String, $search: String, $order: [String]) {
        issues(first: $first, after: $after, search: $search, order: $order) {
            edges {
                node {
                    id
                    description
                    createDate
                    status
                    reporter {
                        id
                        email
                        name
                    }
                    task {
                        id
                        name
                        status
                    }
                    wave {
                        id
                        status
                    }
                    client {
                        id
                        name
                    }
                    shops {
                        id
                        name
                    }
                    comparisons {
                        id
                    }
                    project {
                        name
                    }
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;
export const ISSUE_UNRESOLVED_COUNT = gql`
    query IssueQuery {
        statusCount
    }
`;

export const SET_ISSUE_STATUS_MUTATION = gql`
    mutation SetIssueStatusMutation($issue_id: Int!, $status: Int!) {
        setIssueStatus(issueId: $issue_id, status: $status) {
            issue {
                id
                status
            }
            errors {
                field
                messages
            }
        }
    }
`;

export const ISSUE_CREATE_MUTATION = gql`
    mutation CreateIssueMutation($task: ID!, $description: String!) {
        createIssue(input: { task: $task, description: $description }) {
            issue {
                id
            }
            errors {
                field
                messages
            }
        }
    }
`;

export const ISSUE_PROJECTS_TASKS_QUERY = gql`
    query IssueProjectsTasks($search: String, $order: [String]) {
        issueProjectsTasks(search: $search, order: $order) {
            edges {
                node {
                    name
                    taskStats {
                      total
                      productsCount
                      unassigned
                      inProgress
                      todo
                      paused
                      done
                    }
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;
