import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// Bootstrap // Moved up front to allow overriding without !important
import 'bootstrap/dist/css/bootstrap.min.css';
import './base.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// Apollo
import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';

const cache = new InMemoryCache();

const client = new ApolloClient({
    uri: window.location.protocol + '//' + window.location.hostname + '/gpql/',
    // uri: process.env.REACT_APP_ROOT_HOST_URL + '/gpql/',
    credentials: 'include',
    cache: cache,
    // In order to catch errors in console.
    onError: ({ networkError, graphQLErrors }) => {
        console.log('graphQLErrors', graphQLErrors);
        console.log('networkError', networkError);

        if (networkError && networkError.statusCode === 500) {
            window.location = '/500';
        }
    },
});

const Init = () => (
    <ApolloProvider client={client}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ApolloProvider>
);
ReactDOM.render(<Init></Init>, document.getElementById('root'));

serviceWorker.unregister();
