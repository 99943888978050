import { gql } from 'apollo-boost';

export const USER_STATS_QUERY = gql`
    query($userId: Int, $startDate: DateTime, $endDate: DateTime) {
        auditorStats(userId: $userId, startDate: $startDate, endDate: $endDate) {
            comparisonCount
            workTime
            comparisonsPerHour
        }
    }
`;

export const MY_STATS_QUERY = gql`
    query MyStats($startDate: DateTime, $endDate: DateTime) {
        myStats(startDate: $startDate, endDate: $endDate) {
            comparisonCount
            workTime
            comparisonsPerHour
        }
    }
`;

export const STATS_FOR_ALL_QUERY = gql`
    query StatsForAll($startDate: DateTime, $endDate: DateTime, $sort: String) {
        statsForAll(startDate: $startDate, endDate: $endDate, sort: $sort) {
            id
            name
            workTime
        }
    }
`;

export const USER_DETAILED_STATS_QUERY = gql`
    query($userId: Int, $date: Date, $sort: String) {
        auditorComparisonsWithIdleTimes(userId: $userId, date: $date, sort: $sort)
    }
`;

export const PROJECT_USER_STATS_QUERY = gql`
    query ProjectUserStats($waveId: Int!) {
        wave(id: $waveId) {
            fullName
            projectId
        }
        projectUserStats(waveId: $waveId) {
            id
            name
            comparisonCount
            workTime
            comparisonsPerHour
        }
    }
`;

export const PROJECT_WAVE_STATS_QUERY = gql`
    query ProjectWaveStats($waveId: Int!) {
        wave(id: $waveId) {
            fullName
            projectId
        }
        projectWaveStats(waveId: $waveId) {
            comparisonsCount
            failedComparisonsCount
            comparisonsNotSureCount
            comparisonsIsChangedCount
            waveImportTime
            waveWaitingToStartTime
            waveInProgressTime
            waveTasksCreationTime
            waveAlgorithmCompareTime
            waveComparisonsCopyTime
            waveAuditingTime
            waveProcessingTime
            waveNumberOfCycles
        }
    }
`;
