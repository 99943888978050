import ePackLogo from 'media/logo.png';
import Dropdown from 'react-bootstrap/Dropdown';
import {NavLink} from 'react-router-dom';
import React from 'react';
import './GlobalHeader.css';
import 'components/base/HeaderBar/HeaderBar.css';
import EPackDropdown from "components/base/EPackDropdown/EPackDropdown";
import {Badge} from "react-bootstrap";
import {useQuery} from "@apollo/react-hooks";
import {ISSUE_UNRESOLVED_COUNT} from "queries/issues";
import {USER_ROLE} from "constants/baseConstants";
import { Link } from 'react-router-dom';


function GlobalHeader() {
    return (
        <div id="header-global" className="header-container">
            <Logo/>
            <Navbar/>
            <UserDropdown/>
        </div>
    );
}


function Logo() {
    return (
        <a href="/">
            <img className="epack-logo" src={ePackLogo} alt="Logo"/>
        </a>
    );
}


function Navbar() {
    const role = localStorage.getItem('userRole');
    const showNavbar = role === USER_ROLE.MANAGER || role === USER_ROLE.SUPERUSER

    if (showNavbar) {
        return (
        <div className="main-navbar">
            <ul className="nav">
                <NavbarItem label="Tasks" path="/tasks"/>
                <NavbarItem label="Projects" path="/projects"/>
                <NavbarItem label="Users" path="/users"/>
                <NavbarItem label="Issues" path="/issues">
                    <IssuesCountBadge className={"issues-count-badge"}/>
                </NavbarItem>
            </ul>
        </div>
        )
    } else {
        return null
    }
}


interface NavbarItemProps {
    label: string,
    path: string
    children?: React.ReactNode
}

function NavbarItem({label, path, children}: NavbarItemProps) {
    return (
        <li>
            <NavLink to={path} activeClassName="navbar-active">
                {label}
                {children}
            </NavLink>
        </li>
    );
}

function logOut() {
    const logoutUrl = localStorage.getItem('logoutUrl')
    if (logoutUrl != null) {
        window.open(logoutUrl, '_self');
    }
}

function UserDropdown() {
    const userFullName = localStorage.getItem('userFullName')
    // TODO Dark mode.
    return (
        <EPackDropdown className="user-dropdown">
            <Dropdown.Toggle variant="light">{userFullName}</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item >Dark mode</Dropdown.Item>
                <Dropdown.Item  as={Link} to="/my-stats">My stats</Dropdown.Item>
                <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
            </Dropdown.Menu>
        </EPackDropdown>
    );
}


function IssuesCountBadge({...props}: any) {
    // Get unresolved issue count from API.
    const {data} = useQuery(ISSUE_UNRESOLVED_COUNT);

    // Conditional rendering.
    if (data === undefined || !data.statusCount)
        return <noscript/>

    return <Badge {...props} pill variant="danger">{data.statusCount}</Badge>
}

export default GlobalHeader;
