import React, {useState} from 'react';
import 'Shortcuts.css';
import {useQuery} from "@apollo/react-hooks";
import {Alert} from 'constants/swal';
import {Col, Container, Row} from "react-bootstrap";
import UserInfo from "components/users/UserStats/helpers/UserInfo";
import DatePicker from 'react-datepicker/dist/react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import {USER_DETAILED_STATS_QUERY} from "queries/stats";
import moment from 'moment';
import './UserDetailedStats.css';
import EpackLoader from 'components/loaders/Loaders';
import Table from 'react-bootstrap/Table'
import Button from "react-bootstrap/Button";
import {faCaretUp, faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


function UserDetailedStatsView(props: any) {
    const [pickedDate, setPickedDate] = useState(new Date());
    const [sortType, setSortType] = useState('');

    const {
        loading: detailedStatsLoading,
        error: detailedStatsError,
        data: detailedStatsData,
        refetch: detailedStatsRefetch
    } = useQuery(USER_DETAILED_STATS_QUERY, {
        variables: {
            userId: props.match.params.id,
            date: moment(pickedDate).format("YYYY-MM-DD"),
            sort: sortType
        }
    });
    if (detailedStatsLoading) {
        return <EpackLoader/>
    }
    if (detailedStatsError) {
        Alert.fire("Error occured.", "Please contact administrator.", "error");
        return <p>Error occured :(</p>;
    }

    return (
        <Container className="main-container container-white">
            <Container className="stats-header">
                <Row>
                    <Col>
                        <h2><b>Stats</b></h2>
                        <UserInfo userId={props.match.params.id}/>
                    </Col>
                    <Col>
                        <div className="detailed-sort-button">
                            <Button variant="outline-secondary"
                                    onClick={() => {
                                        setSortType('iddle_time_dsc');
                                        detailedStatsRefetch();
                                    }}
                            ><FontAwesomeIcon
                        title="Sort idle time descending" icon={faCaretDown}
                        className="button-icon"/>Sort desc
                            </Button>
                        </div>
                        <div className="detailed-sort-button">
                            <Button variant="outline-primary"
                                    onClick={() => {
                                        setSortType('iddle_time_asc');
                                        detailedStatsRefetch();
                                    }}
                            ><FontAwesomeIcon
                        title="Sort idle time ascending" icon={faCaretUp}
                        className="button-icon"/>Sort asc
                            </Button>
                        </div>
                    </Col>
                    <Col>
                        <div className="detailed-stats-button">
                            <DatePicker
                                dateFormat="eeee dd/MM/yyyy"
                                closeOnScroll={true}
                                selected={pickedDate}
                                // @ts-ignore
                                onChange={date => setPickedDate(date)}
                                customInput={<PickDateButton/>}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <hr/>
            <h4 className="text-center">Comparisons: {detailedStatsData.auditorComparisonsWithIdleTimes.total_count}</h4>
            <ComparisonDetailsTable detailedStatsData={detailedStatsData} loading={detailedStatsLoading}
                                    error={detailedStatsError}/>
        </Container>

    )
}


function PickDateButton({value, onClick}: any) {
    return (
        <button className="btn btn-primary" onClick={onClick}>
            {value}
        </button>
    )
}


function ComparisonDetailsTable({detailedStatsData, detailedStatsLoading, detailedStatsError}: any) {
    if (detailedStatsLoading) {
        return <EpackLoader/>
    }
    if (detailedStatsError) {
        Alert.fire("Error occured.", "Please contact administrator.", "error");
        return <p>Error occured :(</p>;
    }

    if (detailedStatsData.auditorComparisonsWithIdleTimes.total_count === 0) {
        return null
    } else {
        return (
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Comparison ID</th>
                    <th>Product version ID</th>
                    <th>Product</th>
                    <th>Project</th>
                    <th>Hour</th>
                    <th>Idle time</th>
                </tr>
                </thead>
                <tbody>
                {detailedStatsData.auditorComparisonsWithIdleTimes.comparisons.map(function (comparison: any) {
                    return (
                        <tr key={comparison.comparison_id}>
                            <td width="5%">{comparison.comparison_id}</td>
                            <td width="5%">{comparison.product_version_id}</td>
                            <td width="20%">{comparison.product}</td>
                            <td width="25%">{comparison.project}</td>
                            <td width="10%">{comparison.time}</td>
                            <td width="10%">{comparison.idle_time} min</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        )
    }
}


export default UserDetailedStatsView;
