import React from "react";

export function prepare_options(list: any) {
    list.options = list.map((value: any, index: number) => {
        return <option key={index} value={value['value']}>{value['label']}</option>
    });
    list.options.unshift(0)
    list.options[0] =
        <option disabled key={-1} value={"DEFAULT"}> -- select an option -- </option>
    return list;
}

export default prepare_options;
