// Success SweetAlert modal configuration.
import Swal from 'sweetalert2';

export const Alert = Swal.mixin({
    position: 'top',
    timer: 100000,
});

export const SuccessAlert = Alert.mixin({
    icon: 'success',
    showConfirmButton: false,
    timer: 2500,
});

export const InfoAlert = Alert.mixin({
    icon: 'info',
});

export const WarningAlert = Alert.mixin({
    icon: 'warning',
});
