import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import './Shortcuts.css';
import GlobalHeader from 'components/base/HeaderBar/GlobalHeader/GlobalHeader';
import UserListView from 'components/users/UserList/UserList';
import ProjectListView from 'components/projects/ProjectList/ProjectList';
import ProjectResultsView from 'components/projects/ProjectList/ProjectResults/ProjectResults';
import ProjectUserStatsView from 'components/projects/ProjectList/ProjectUserStats/ProjectUserStats';
import ProjectWaveStatsView from 'components/projects/ProjectList/ProjectWaveStats/ProjectWaveStats';
import CreateProject from 'components/projects/CreateProject';
import CreateUser from 'components/users/CreateUser';
import UpdateProjectInitial from 'components/projects/UpdateProject';
import UpdateUserInitial from 'components/users/UpdateUser';
import PrivateRoute from 'components/auth/auth';
import TaskListView from 'components/tasks/TaskList/TaskList';
import MainPage from 'components/base/MainPage/MainPage';
import PageNotFoundError from './components/flatpages/errorCodes/404/404';
import InternalServerError from './components/flatpages/errorCodes/500/500';
import IssueListView from 'components/issues/IssueList/IssueList';
import UserStatsView from 'components/users/UserStats/UserStats/UserStats';
import MyStatsView from 'components/users/UserStats/MyStats/MyStats';
import StatsForAllView from 'components/users/UserStats/StatsForAll/StatsForAll';
import UserDetailedStatsView from './components/users/UserStats/UserDetailedStats/UserDetailedStats';

function App() {
    return (
        <Router>
            <div className="App">
                <PrivateRoute>
                    <GlobalHeader />
                    <div className="pt64">
                        <Switch>
                            <Route exact path="/">
                                <MainPage />
                            </Route>
                            <Route path="/tasks">
                                <TaskListView />
                            </Route>
                            <Route path="/users">
                                <UserListView />
                            </Route>
                            <Route exact path="/my-stats" component={MyStatsView} />
                            <Route exact path="/stats-for-all" component={StatsForAllView} />
                            <Route exact path="/stats/:id" component={UserStatsView} />
                            <Route exact path="/stats/:id/details" component={UserDetailedStatsView} />
                            <Route path="/projects">
                                <ProjectListView />
                            </Route>
                            <Route path="/issues">
                                <IssueListView />
                            </Route>
                            <Route path="/500">
                                <InternalServerError />
                            </Route>
                            <Route exact path="/add-user" render={(props) => <CreateUser {...props} />} />
                            <Route
                                exact
                                path="/add-project"
                                render={(props) => <CreateProject {...props} />}
                            />
                            <Route
                                exact
                                path="/update-project/:id"
                                render={(props) => <UpdateProjectInitial {...props} />}
                            />
                            <Route
                                exact
                                path="/user-stats/:id"
                                render={(props) => <ProjectUserStatsView {...props} />}
                            />
                            <Route
                                exact
                                path="/wave-stats/:id"
                                render={(props) => <ProjectWaveStatsView {...props} />}
                            />
                            <Route
                                exact
                                path="/project-results/:id"
                                render={(props) => <ProjectResultsView {...props} />}
                            />
                            <Route
                                exact
                                path="/update-user/:id"
                                render={(props) => <UpdateUserInitial {...props} />}
                            />
                            <Route exact path="/500" component={InternalServerError} />
                            {/* TODO: Should we display GlobalHeader on 404? */}
                            <Route component={PageNotFoundError} />
                        </Switch>
                    </div>
                </PrivateRoute>
            </div>
        </Router>
    );
}

export default App;
