import Form from "react-bootstrap/Form";
import React from "react";
import 'components/base/EPackSelect/EPackSelect.css';
import {InputGroup} from "react-bootstrap";

declare type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

interface EPackSelectProps {
    label?: string;
    className?: string;
    value?: string;
    onChangeInput?: React.ChangeEventHandler<FormControlElement>;
    onEnter?: React.KeyboardEventHandler<FormControlElement>;
    selectOptions?: any;
}

function EPackSelect({label, className, value, onChangeInput, onEnter, selectOptions}: EPackSelectProps) {
    let defaultClassName = "epack-select "
    if (className) defaultClassName += className
    return (
        <Form inline className={defaultClassName}>
            <InputGroup>
                <Form.Control
                    as="select"
                    custom
                    value={value}
                    title={label || 'Select'}
                    placeholder={label || 'Select'}
                    name={label || 'Select'}
                    onKeyDown={(event: React.KeyboardEvent<FormControlElement>) => {
                        if (event.key === 'Enter') {
                            event.preventDefault()
                            event.stopPropagation()
                            if (onEnter)
                                onEnter(event)
                        }
                    }}
                    onChange={onChangeInput}
                    required>{selectOptions.options}
                </Form.Control>
            </InputGroup>
        </Form>
    )
}


export default EPackSelect;
