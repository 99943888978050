import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {default as BootstrapButton} from 'react-bootstrap/Button';
import React, {RefObject} from 'react';
import 'components/base/EPackButton/EPackButton.css'
import {IconProp} from '@fortawesome/fontawesome-svg-core';


interface EPackButtonProps {
    size?: 'sm' | 'lg',
    label: string,
    icon?: IconProp,
    variant?: string,
    href?: string,
    className?: string,
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    reference?: RefObject<HTMLButtonElement>
}

function EPackButton({size, label, icon, variant, href, className, onClick, reference}: EPackButtonProps) {
    let optional_icon = (icon) ? <FontAwesomeIcon icon={icon} className="button-icon"/> : undefined

    return (    
        <BootstrapButton variant={variant || 'primary'} size={size} href={href} className={className} onClick={onClick} ref={reference}>
            {optional_icon}{label}
        </BootstrapButton>

    )
}

export default EPackButton;
