import React, {useState} from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {DateRangePicker} from "react-date-range";
import {subDays} from 'date-fns';
import {useQuery} from "@apollo/react-hooks";
import {Alert} from "constants/swal";
import {MY_STATS_QUERY} from 'queries/stats';
import {Col, Container, Row} from "react-bootstrap";
import 'components/users/UserStats/MyStats/MyStats.css'
import EpackLoader from 'components/loaders/Loaders';


interface myStatsSection {
    workTime: number,
    comparisonCount: number,
    comparisonsPerHour: number,
}


function MyStatsView(props: any) {

    const [dateRange, setDateRange] = useState([
        {
            startDate: subDays(new Date(), 7),
            endDate: subDays(new Date(), 1),
            key: 'selection'
        }
    ]);

    const {
        loading: globalStatsLoading,
        error: globalStatsError,
        data: globalStatsData
    } = useQuery(MY_STATS_QUERY, {
        variables: {
            startDate: dateRange[0].startDate,
            endDate: dateRange[0].endDate
        }
    });
    if (globalStatsLoading) {
        return <EpackLoader/>
    }
    if (globalStatsError) {
        Alert.fire(globalStatsError.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
        return <div>Error: {globalStatsError.message}</div>;
    }
    return (
        <Container className="main-container container-white">
            <Container className="stats-header">
                <Row>
                    <Col>
                        <h2><b>My Statistics</b></h2>
                    </Col>
                </Row>
            </Container>
            <hr/>
            {globalStatsData.myStats.length ?
                <Row>
                    <Col>
                        <StatsValues workTime={globalStatsData.myStats[0].workTime}
                                     comparisonCount={globalStatsData.myStats[0].comparisonCount}
                                     comparisonsPerHour={globalStatsData.myStats[0].comparisonsPerHour}
                        />
                    </Col>
                    <Col>
                        <DateRangePicker
                            // @ts-ignore
                            onChange={item => setDateRange([item.selection])}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            ranges={dateRange}
                            direction="horizontal"
                        />
                    </Col>
                </Row>
            :
                            <Row>

                    <Col>
                        There is no data to show for selected day.
                    </Col>
                    <Col>
                        <DateRangePicker
                            // @ts-ignore
                            onChange={item => setDateRange([item.selection])}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            ranges={dateRange}
                            direction="horizontal"
                        />
                    </Col>
                </Row>
            }
        </Container>
    )
}


function StatsValues(stats: myStatsSection) {
    return (
        <Container className="auditor-global-stats text-center">
            <Container className="stats-single-box">
                <Row><Col className="auditor-global-stats-values">{stats.workTime}h</Col></Row>
                <Row><Col className="text-muted">Work time</Col></Row>
            </Container>
            <br/>
            <Container className="stats-single-box">
                <Row><Col className="auditor-global-stats-values">{stats.comparisonCount}</Col></Row>
                <Row><Col className="text-muted">Comparisons</Col></Row>
            </Container>
            <br/>
            <Container className="stats-single-box">
                <Row> <Col className="auditor-global-stats-values">{stats.comparisonsPerHour}</Col></Row>
                <Row><Col className="text-muted">Comparisons/hr</Col></Row>
            </Container>
        </Container>
    )
}


export default MyStatsView;
