import React, {useContext, useState, useEffect} from 'react';
import './IssueList.css';
import 'Shortcuts.css';
import LocalHeader from "components/base/HeaderBar/LocalHeader/LocalHeader";
import SearchBox from "components/base/SearchBox/SearchBox";
import {useMutation, useQuery} from "@apollo/react-hooks";
import Button from "react-bootstrap/Button";
import {SweetAlertOptions} from 'sweetalert2';
import {ISSUE_LIST_QUERY, SET_ISSUE_STATUS_MUTATION} from "queries/issues";
import {Issue, IssueInterface} from "components/helpers/interfaces";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import {AccordionContext, Container} from "react-bootstrap";
import {TASK_STATUS} from "constants/baseConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {Alert, SuccessAlert} from "constants/swal";
import EPackButton from "components/base/EPackButton/EPackButton";
import {faAngleDoubleDown, faClipboardCheck, faPause} from "@fortawesome/free-solid-svg-icons";
import {SET_TASK_STATUS_MUTATION} from "queries/tasks";
import EpackLoader from 'components/loaders/Loaders';

// The list of queries for issues list.
function _getQueryVariables() {
    // TODO: constants for similar list views
    const first = 10; // The number of issues per page.
    const order = ['status', '-createDate']; // Order issue by.
    return {first, order};
}

function IssueListView() {
    // Set initial state.
    const [search, setSearch] = useState('')
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(true)
    const [showLoadMoreButtonSearch, setShowLoadMoreButtonSearch] = useState(false)
    const [isFetching, setIsFetching] = useState(false);

    function isScrolling() {
        // Finding the bottom of the page.
        const bottom = document.documentElement.scrollHeight - document.documentElement.scrollTop === window.innerHeight;
        if (bottom) {
            setIsFetching(true)
        }
    }

    const moreData = () => {
        let variables = {
            search: search,
        }
        if (data && data.issues.pageInfo.endCursor != null) {
            // @ts-ignore
            variables["after"] = data.issues.pageInfo.endCursor
        }
        // Prevent loading results from beginning.
        if (variables.hasOwnProperty('after')) {
            fetchMore({
                variables: variables,
                updateQuery: (prevResult, {fetchMoreResult}) => {
                    // if (!fetchMoreResult)
                    //     return prevResult;

                    // @ts-ignore
                    fetchMoreResult.issues.edges = [
                        // @ts-ignore
                        ...prevResult.issues.edges,
                        // @ts-ignore
                        ...fetchMoreResult.issues.edges,
                    ]
                    setIsFetching(false)
                    window.scrollTo(0, document.documentElement.scrollHeight);
                    return fetchMoreResult;
                },
            });
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", isScrolling);
        return () => window.removeEventListener("scroll", isScrolling);
    }, [])

    useEffect(() => {
        if (isFetching) {
            moreData();
        }
    }, [isFetching]);

    const {loading, error, data, fetchMore} = useQuery(ISSUE_LIST_QUERY, {
        variables: _getQueryVariables(),
        fetchPolicy: 'cache-and-network',
    });
    if (loading) {
        return <EpackLoader/>
    }
    if (error) {
        Alert.fire(error.message, "Please try again later.", "error");
        return <div>Error: {error.message}</div>;
    }
    // TODO Place Search button to SearchBox.
    // TODO Place Show more button in separate function and pass it to buttons onClick.
    if (data) {
        // Map data from API.
        const issuesToRender = data.issues.edges;
        const issuesResultsHasNextPage = data.issues.pageInfo.hasNextPage
        let issuesToRenderMapped = issuesToRender.map((issue: IssueInterface) => {
            return {
                id: issue.node.id,
                description: issue.node.description,
                createDate: issue.node.createDate,
                status: issue.node.status,
                reporter: issue.node.reporter,
                task: issue.node.task,
                wave: issue.node.wave,
                client: issue.node.client,
                shops: issue.node.shops,
                comparisons: issue.node.comparisons,
                project: issue.node.project,
            };
        });
        return (
            <>
                {/* TODO: LocalHeader must be present even during loading and errors. */}
                <LocalHeader>
                    <SearchBox onChangeInput={(e) => setSearch(e.target.value)} onEnter={() => {
                        setShowLoadMoreButtonSearch(true);
                        setShowLoadMoreButton(false);
                        fetchMore({
                            variables: {search: search},
                            updateQuery: (prevResult, {fetchMoreResult}) => {
                                // @ts-ignore
                                fetchMoreResult.issues.edges = [
                                    // @ts-ignore
                                    ...fetchMoreResult.issues.edges,
                                ];
                                return fetchMoreResult;
                            },
                        });

                    }}/>
                    <Button variant="primary"
                            onClick={() => {
                                setShowLoadMoreButtonSearch(true);
                                setShowLoadMoreButton(false);
                                fetchMore({
                                    variables: {search: search},
                                    updateQuery: (prevResult, {fetchMoreResult}) => {
                                        // @ts-ignore
                                        fetchMoreResult.issues.edges = [
                                            // @ts-ignore
                                            ...fetchMoreResult.issues.edges,
                                        ];
                                        return fetchMoreResult;
                                    },
                                });
                            }}
                    >
                        Search
                    </Button>

                </LocalHeader>
                <div className="main-container">
                    {issuesToRenderMapped.length > 0 ?
                        <IssueList issues={issuesToRenderMapped}/>
                        :
                        <Card className="text-center">
                            <Card.Body className="h5">There are no issues found.</Card.Body>
                        </Card>}
                    {issuesResultsHasNextPage ?
                        <span className="d-flex justify-content-center">
                            <span className="pt-3 mb-3">
                                                    {showLoadMoreButton && !showLoadMoreButtonSearch && (
                                                        <div className="ml-1">
                                                            <EPackButton variant="primary"
                                                                         label="Show more Issues"
                                                                         icon={faAngleDoubleDown}
                                                                         onClick={() => {
                                                                             const endCursor = data.issues.pageInfo.endCursor;
                                                                             if (endCursor != null) {
                                                                                 fetchMore({
                                                                                     variables: {
                                                                                         after: endCursor,
                                                                                     },
                                                                                     updateQuery: (prevResult, {fetchMoreResult}) => {
                                                                                         if (!fetchMoreResult)
                                                                                             return prevResult;

                                                                                         // @ts-ignore
                                                                                         fetchMoreResult.issues.edges = [
                                                                                             // @ts-ignore
                                                                                             ...prevResult.issues.edges,
                                                                                             // @ts-ignore
                                                                                             ...fetchMoreResult.issues.edges,
                                                                                         ]
                                                                                         return fetchMoreResult;
                                                                                     },
                                                                                 });
                                                                             }
                                                                         }}
                                                            />
                                                        </div>
                                                    )}
                                {showLoadMoreButtonSearch && !showLoadMoreButton && (
                                    <div className="ml-1">
                                        <EPackButton variant="primary" icon={faAngleDoubleDown}
                                                     label="Show more from Search"
                                                     onClick={() => {
                                                         const endCursor = data.issues.pageInfo.endCursor;
                                                         if (endCursor != null) {
                                                             fetchMore({
                                                                 variables: {
                                                                     after: endCursor,
                                                                     search: search,
                                                                 },
                                                                 updateQuery: (prevResult, {fetchMoreResult}) => {
                                                                     // @ts-ignore

                                                                     fetchMoreResult.issues.edges = [
                                                                         // @ts-ignore
                                                                         ...prevResult.issues.edges,
                                                                         // @ts-ignore
                                                                         ...fetchMoreResult.issues.edges,
                                                                     ];
                                                                     return fetchMoreResult;
                                                                 },
                                                             });
                                                         }
                                                     }}
                                        />
                                    </div>
                                )}
                            </span></span>
                        :
                        <></>}
                </div>
            </>
        )
    }
}


interface IssueListProps {
    issues: Issue[]
}

function IssueList({issues}: IssueListProps) {
    const objects = []
    for (const [pk, issue] of issues.entries()) {
        objects.push(
            <Card key={pk}>
                <Card.Header>
                    <SingleIssueBox issue={issue} key={pk}/>
                </Card.Header>
            </Card>
        )
    }

    if (objects.length === 0) {
        return (<Card className="text-center">
                <Card.Body className="h5">There are no issues found.</Card.Body>
            </Card>
        )
    } else {
        return (
            <Accordion>{objects}</Accordion>
        )
    }
}


interface SingleIssueBoxProps {
    issue: Issue
}

function SingleIssueBox({issue}: SingleIssueBoxProps) {
    return (
        <div className="white-box white-box-list display-block single-issue-box">
            <IssueHeader issue={issue}/>
            <IssueBody issue={issue}/>
            <IssueFooter issue={issue}/>
        </div>
    )
}


interface SingleIssueProps {
    issue: Issue
}

function IssueHeader({issue}: SingleIssueProps) {
    return (
        <div className="first-row">
            <h3 className="issue-name">{issue.task.name}</h3>
        </div>
    )
}

function IssueBody({issue}: SingleIssueProps) {
    return (
        <div className="second-row">
            <div className="issue-accordion">
                <Accordion.Collapse eventKey={issue.id}>
                    <Card.Body>
                        {issue.description}
                        <br/>
                        <br/>
                        <div className="text-muted issue-additional-text">
                            Client: <b>{issue.client.name}</b>
                            <br/>
                            Project: <b>{issue.project.name}</b>
                            <br/>
                            Task ID: <b>{issue.task.id}</b>
                            <br/>
                            Wave ID: <b>{issue.wave.id}</b>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle
                    as={Container} className={`issue-description`} eventKey={issue.id}
                >
                    <div className="description-collapsed">
                        {issue.description}
                    </div>
                </Accordion.Toggle>

            </div>
            <div className="resolve-dropdown-wrapper">
                <IssueDropdown issue={issue}/>
            </div>
            <div className="dropdown-toggle-wrapper">
                <IssueAccordionToggle accordionKey={issue.id}/>
            </div>
        </div>
    )
}

interface IssueDropdownToggleProps {
    accordionKey: string
}

function IssueAccordionToggle({accordionKey}: IssueDropdownToggleProps) {
    const isToggled = useContext(AccordionContext) === accordionKey.toString();
    return (
        <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={accordionKey.toString()}
            className="issue-accordion-toggle"
        >
            {
                isToggled ?
                    <FontAwesomeIcon icon={faChevronUp} size="2x"/> :
                    <FontAwesomeIcon icon={faChevronDown} size="2x"/>
            }
        </Accordion.Toggle>
    )
}

function IssueFooter({issue}: SingleIssueProps) {
    let issueStatusOpen: any;
    issueStatusOpen = issue.status === 10;
    return (
        <div className="third-row">
            <span className="pl0 issue-additional-text text-muted">
                <b>{issue.reporter.name}</b>
            </span>
            <span className="pl10 issue-additional-text text-muted">
                {issue.createDate}
            </span>
            <span className="pl10 issue-additional-text text-muted">
                <div
                    className={issueStatusOpen ? "issue-status-badge open badge" : "issue-status-badge resolved badge"}>
                {issueStatusOpen ? "Open" : "Resolved"}
                </div>
            </span>
        </div>
    )
}

function IssueDropdown({issue}: SingleIssueProps) {
    const [
        setIssueStatus, {loading: setIssueStatusLoading}
    ] = useMutation(SET_ISSUE_STATUS_MUTATION, {errorPolicy: 'all'});


    const handleResolveClick = (event: any) => {
        if (setIssueStatusLoading) {
            return <EpackLoader loaderType="ballcliprotate"/>
        }
        setIssueStatus({
            variables: {issue_id: issue.id, status: 20}
        })
            .then(({data}) => {
                Alert.fire({
                    title: 'Issue has been resolved.',
                    ...SuccessAlert
                } as SweetAlertOptions).then(() => {
                    window.location.reload();
                });
            })
            .catch(error => {
                // Catch Promise.
                Alert.fire("Error has occurred:", error.message, "error");
            })
    };
    const [
        setTaskStatus, {loading}
    ] = useMutation(SET_TASK_STATUS_MUTATION, {errorPolicy: 'all'});
    if (loading) {
        return <EpackLoader loaderType="ballcliprotate"/>
    }
    const handleFreezeClick = (event: any) => {
        setTaskStatus({
            variables: {task_id: issue.task.id, status: TASK_STATUS.PAUSED}
        })
            .catch(error => {
                // Catch Promise.
                Alert.fire("Error has occurred:", error.message, "error");
            })
    };

    let pauseButtonConfiguration: any;
    let issueStatusOpen: any;
    issueStatusOpen = issue.status === 10;
    if (issue.task.status === TASK_STATUS.PAUSED) {
        pauseButtonConfiguration = {
            label: "Paused Task",
            variant: "danger",
            className: "issue-body-button task-badge badge badge-pill",
        }
    } else {
        pauseButtonConfiguration = {
            label: "Pause Task",
            icon: faPause,
            variant: "outline-danger",
            className: "issue-body-button",
            onClick: handleFreezeClick
        }
    }

    return (
        <>
            {issueStatusOpen &&
            <EPackButton
                label="Resolve"
                icon={faClipboardCheck}
                variant="outline-primary"
                className="issue-body-button"
                onClick={handleResolveClick}
            />}
            {issueStatusOpen &&
            <EPackButton {...pauseButtonConfiguration}/>}
        </>
    )
}


export default IssueListView;
