import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {useQuery} from "@apollo/react-hooks";
import {Alert} from "constants/swal";
import {PROJECT_USER_STATS_QUERY} from 'queries/stats';
import {Col, Container, Row} from "react-bootstrap";
import './ProjectUserStats.css'
import EpackLoader from 'components/loaders/Loaders';
import EPackButton from "../../../base/EPackButton/EPackButton";
import {faTasks} from "@fortawesome/free-solid-svg-icons";
import Card from "react-bootstrap/Card";


interface projectsUsersStatsSection {
    id: number,
    name: string,
    workTime: number,
    comparisonCount: number,
    comparisonsPerHour: number,
}


function ProjectUserStatsView(props: any) {
    const {
        loading: projectUserStatsLoading,
        error: projectUserStatsError,
        data: projectUserStatsData
    } = useQuery(PROJECT_USER_STATS_QUERY, {
        variables: {
            waveId: props.match.params.id,
        }
    });
    if (projectUserStatsLoading) {
        return <EpackLoader/>
    }
    if (projectUserStatsError) {
        Alert.fire(projectUserStatsError.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
        return <div>Error: {projectUserStatsError.message}</div>;
    }

    return (
        <Container className="main-container container-white">
            <Container className="stats-header">
                <Row>
                    <Col>
                        <h2><b>Auditors work time statistics</b></h2>
                        <h5>Wave: {projectUserStatsData.wave.fullName}</h5>
                    </Col>
                    <Col>
                        <div className="detailed-stats-button">
                            <EPackButton label="Project results" icon={faTasks} variant="primary"
                                         href={"/project-results/" + projectUserStatsData.wave.projectId}
                                         className="pull-right"/>
                        </div>
                    </Col>
                </Row>
            </Container>
            <hr/>
            {projectUserStatsData.projectUserStats.length > 0 ? projectUserStatsData.projectUserStats.map((item: any) => (
                <Row>
                    <Col>
                        <ProjectUserStatsValues id={item.id} name={item.name} workTime={item.workTime}
                                                comparisonCount={item.comparisonCount}
                                                comparisonsPerHour={item.comparisonsPerHour}
                        />
                    </Col>
                    <hr/>
                </Row>
            )) : <Card className="text-center">
                    <Card.Body className="h5">Nobody has worked in this wave yet.</Card.Body>
                </Card>}
        </Container>
    )
}


function ProjectUserStatsValues(stats: projectsUsersStatsSection) {
    return (
        <Container className="project-user-stats text-center">
            <Container className="stats-single-box">
                <a href={'/stats/' + stats.id}>
                <Row><Col ><h1>{stats.name}</h1></Col></Row>
                </a>
            </Container>
            <Container className="stats-single-box">
                <Row><Col className="project-user-stats-values">{stats.workTime}h</Col></Row>
                <Row><Col className="text-muted">Work time</Col></Row>
            </Container>
            <br/>
            <Container className="stats-single-box">
                <Row><Col className="project-user-stats-values">{stats.comparisonCount}</Col></Row>
                <Row><Col className="text-muted">Comparisons</Col></Row>
            </Container>
            <br/>
            <Container className="stats-single-box">
                <Row> <Col className="project-user-stats-values">{stats.comparisonsPerHour}</Col></Row>
                <Row><Col className="text-muted">Comparisons/hr</Col></Row>
            </Container>
        </Container>
    )
}

export default ProjectUserStatsView;
