import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { Alert } from 'constants/swal';
import EpackLoader from 'components/loaders/Loaders';

async function getLoginStatus() {
    const axios = require('axios');
    try {
        let response = await axios({
            url: window.location.protocol + '//' + window.location.hostname + '/gpql-login-check/',
            // url: process.env.REACT_APP_ROOT_HOST_URL + '/gpql-login-check/',
            method: 'post',
            withCredentials: true,
            data: {
                query: `
    query UserLoginQuery {
        loginStatus {
            logged
            userFullName
            loginUrl
            logoutUrl
            userRole
            epackMediaHostUrl
        }
    }
      `,
            },
        });
        return response.data;
    } catch (err) {
        Alert.fire('Ups...Something went wrong', 'Please contact administrator.', 'error');
    }
}

const PrivateRoute = (props) => {
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loginUrl, setLoginUrl] = useState('');

    const { comp: Component, ...rest } = props;

    useEffect(() => {
        const fetchData = async () => {
            const result = await getLoginStatus();
            const loginStatus = result.data.loginStatus[0].logged;
            if (loginStatus) {
                // Set user credentials to localStorage in order to use them in GlobalHeader component.
                localStorage.setItem('logoutUrl', result.data.loginStatus[0].logoutUrl);
                localStorage.setItem('userFullName', result.data.loginStatus[0].userFullName);
                localStorage.setItem('userRole', result.data.loginStatus[0].userRole);
                localStorage.setItem('epackMediaHostUrl', result.data.loginStatus[0].epackMediaHostUrl);
            } else {
                setLoginUrl(result.data.loginStatus[0].loginUrl);
            }
            setIsAuthenticated(result.data.loginStatus[0].logged);
            // setLoginUrl(result.data.loginStatus[0].loginUrl);
            setLoading(false);
        };
        fetchData();
    }, []);
    if (isAuthenticated) {
        return <Route {...rest} render={(props) => <Component {...props} />} />;
    } else {
        if (loading) {
            return <EpackLoader />;
        } else {
            window.location.replace(loginUrl);
            return null;
        }
    }
};

export default PrivateRoute;
