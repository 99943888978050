import React, {useContext, useState} from 'react';
import Card from "react-bootstrap/Card";
import EPackButton from "components/base/EPackButton/EPackButton";
import './TaskList.css'
import {useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import {TASK_ASSIGNMENT_QUERY, TASK_ASSIGN_TASK_QUERY, GET_TASK_BY_PROJECT_TASK_LIST} from 'queries/tasks';
import {Alert, SuccessAlert} from 'constants/swal';
import ComparisonView from "components/comparisons/Comparison";
import EpackLoader from 'components/loaders/Loaders';
import {PROJECTS_LIST_TASK_VIEW_QUERY} from "queries/projects";
import LocalHeader from "components/base/HeaderBar/LocalHeader/LocalHeader";
import Button from "react-bootstrap/Button";
import SearchBox from "../../base/SearchBox/SearchBox";
import Accordion from "react-bootstrap/Accordion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AccordionContext} from "react-bootstrap";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "react-bootstrap/ProgressBar";

interface Task {
    id: string
    custom_name?: string | null
    create_date: string
    product_count: number
    shops: string
    project: string
    progress: number
}

interface Project {
    id: string;
    name: string;
    createDate: string;
    createDateLastWave: string;
    taskStatsTasks: Record<string, number>;
    currentWave: {
        pk: number,
        alterable: boolean,
        status: number,
        isUrgent: boolean
    };
}

interface ProjectListProps {
    projects: Project[];
    hasTaskAssignedRefetch: any
}

interface SingleProjectTasksBoxProps {
    project: Project
    accordionKey: number
}

interface ProjectTasksListDropdownToggleProps {
    accordionKey: number,
}

interface SingleProjectProps {
    project: Project
}

interface ProjectTasksProps {
    project_pk: string
    accordionKey: number
    hasTaskAssignedRefetch: any
}


function ProjectTasks({project_pk, accordionKey, hasTaskAssignedRefetch}: ProjectTasksProps) {
    const [getTasksData, {called, loading, data}] = useLazyQuery(GET_TASK_BY_PROJECT_TASK_LIST, {
        variables: {project_id: project_pk}
    });
    const fetchTasks = () => {
        // Custom event sent by accordion toggle
        const _fetchTasks = (async () => {
            if (!called) getTasksData()
        });
        _fetchTasks().then();
    }
    // Custom event listener to listen for accordion open. Destroyed on fetching data
    document.addEventListener("toggle_" + accordionKey, fetchTasks)

    if (loading) return <EpackLoader/>

    if (data) {
        document.removeEventListener("toggle_" + accordionKey, fetchTasks)

        const tasksToRender = data.tasksByProjectTaskList.edges;
        let tasksToRenderMapped = tasksToRender.map((task: {
            node: {
                id: any;
                customName: any;
                createDate: any;
                productCount: any;
                shops: any;
                project: any;
                progress: any;
            }
        }) => {
            return {
                id: task.node.id,
                custom_name: task.node.customName,
                create_date: task.node.createDate,
                product_count: task.node.productCount,
                shops: task.node.shops,
                project: task.node.project,
                progress: task.node.progress
            };
        });

        const objects = []
        for (const [pk, task] of tasksToRenderMapped.entries()) {
            objects.push(
                <div className="col-*-*" key={pk}>
                    <SingleTaskCard task={task} key={pk} hasTaskAssignedRefetch={hasTaskAssignedRefetch}/>
                </div>
            )
        }

        return (
            <div className="row task-cards-container">{objects}</div>
        )
    }
    return <noscript/>
}


function ProjectTasksListDropdownToggle({accordionKey}: ProjectTasksListDropdownToggleProps) {
    const isToggled = useContext(AccordionContext) === accordionKey.toString();

    return (
        <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={accordionKey.toString()}
            className="project-accordion-toggle"
            onClick={() => {
                document.dispatchEvent(new Event('toggle_' + accordionKey.toString()))
            }}
        >
            {
                isToggled ?
                    <FontAwesomeIcon icon={faChevronUp} size="2x"/> :
                    <FontAwesomeIcon icon={faChevronDown} size="2x"/>
            }
        </Accordion.Toggle>
    )
}

function ProjectTasksMiddleRow({project, accordionKey}: SingleProjectTasksBoxProps) {
    return (
        <div className="data-row">
            <span className="col pl0 project-create-date text-muted">
                Last wave created: {project.createDateLastWave}
            </span>
            <div className="display-flex">
                <ProjectTasksListDropdownToggle accordionKey={accordionKey}/>
            </div>
        </div>
    )
}

function ProjectTasksInfo({project}: SingleProjectProps) {
    return (
        <div className="data-row tasks-info">
            <span className="project-tasks-unassigned">Unassigned tasks: {project.taskStatsTasks.unassigned}</span>
        </div>
    )
}

function SingleProjectTasksBox({project, accordionKey}: SingleProjectTasksBoxProps) {
    return (
        <>
            <div
                className={project.currentWave.isUrgent ? "urgent-box white-box-list display-block single-project-box" : "white-box white-box-list display-block single-project-box"}>
                <div className="project-name">{project.currentWave.isUrgent ? <span className="is-urgent"><FontAwesomeIcon title="Urgent project" icon={faExclamationTriangle} className="button-icon"/></span> : <></>}{project.name}</div>
                <ProjectTasksMiddleRow project={project} accordionKey={accordionKey}/>
                <ProjectTasksInfo project={project}/>
            </div>
        </>
    )
}

function ProjectList({projects, hasTaskAssignedRefetch}: ProjectListProps) {
    const objects = []
    for (const [pk, project] of projects.entries()) {
        objects.push(
            <Card key={pk}>
                <Card.Header>
                    <SingleProjectTasksBox project={project} key={pk} accordionKey={pk}/>
                </Card.Header>
                <Accordion.Collapse eventKey={pk.toString()}>
                    <Card.Body><ProjectTasks project_pk={project.id} accordionKey={pk} hasTaskAssignedRefetch={hasTaskAssignedRefetch}/></Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }
    if (objects.length === 0) {
        return (<Card className="text-center">
                <Card.Body className="h5">There are no unassigned tasks found.</Card.Body>
            </Card>
        )
    } else {
        return (
            <Accordion>{objects}</Accordion>
        )
    }
}

// The list of queries for project list.
function _getQueryVariables() {
    const order = null; // We want to use conditional ordering mechanism from backend.
    return {order};
}


function TaskListView() {

    // Get project list from API.
    const {loading, error, data, fetchMore} = useQuery(PROJECTS_LIST_TASK_VIEW_QUERY, {
        variables: _getQueryVariables(),
    });
    // Check if user has tasks assigned.
    const {
        loading: hasTaskAssignedLoading,
        error: hasTaskAssignedError,
        data: hasTaskAssignedData,
        refetch: hasTaskAssignedRefetch
    } = useQuery(TASK_ASSIGNMENT_QUERY,);

    const [search, setSearch] = useState('')

    if (hasTaskAssignedLoading) {
        return <EpackLoader/>
    }
    if (hasTaskAssignedError) {
        Alert.fire(hasTaskAssignedError.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
        return <div>Error: {hasTaskAssignedError.message}</div>;
    }
    if (hasTaskAssignedData) {
        const userHasTasksAssigned = hasTaskAssignedData.tasksAssigned.edges
        // Redirect user to a task view if task has been assigned to him
        if (userHasTasksAssigned && userHasTasksAssigned.length) {
            return <ComparisonView/>
        }
    }
    if (loading) {
        return <EpackLoader/>
    }
    if (error) {
        Alert.fire(error.message.replace('GraphQL error: ', '').trim(), 'Please try again later.', 'error');
        return <div>Error: {error.message}</div>;
    }
    // TODO Place Search button to SearchBox.
    if (data) {
        // Map data from API.
        const projectsToRender = data.projectsForTaskList.edges;
        let projectsToRenderMapped = projectsToRender.map((project: {
            node: Project;
        }) => {
            return {
                id: project.node.id,
                name: project.node.name,
                createDate: project.node.createDate,
                createDateLastWave: project.node.createDateLastWave,
                taskStatsTasks: project.node.taskStatsTasks[0],
                currentWave: project.node.currentWave,
            };
        });
        return (
            <>
                <LocalHeader>
                    <SearchBox onChangeInput={(e) => setSearch(e.target.value)} onEnter={() => {
                        fetchMore({
                            variables: {search: search},
                            updateQuery: (prevResult, {fetchMoreResult}) => {
                                // @ts-ignore
                                fetchMoreResult.projectsForTaskList.edges = [
                                    // @ts-ignore
                                    ...fetchMoreResult.projectsForTaskList.edges,
                                ];
                                return fetchMoreResult;
                            },
                        });

                    }}/>
                    <Button variant="primary"
                            onClick={() => {
                                fetchMore({
                                    variables: {search: search},
                                    updateQuery: (prevResult, {fetchMoreResult}) => {
                                        // @ts-ignore
                                        fetchMoreResult.projectsForTaskList.edges = [
                                            // @ts-ignore
                                            ...fetchMoreResult.projectsForTaskList.edges,
                                        ];
                                        return fetchMoreResult;
                                    },
                                });
                            }}
                    >
                        Search
                    </Button>
                </LocalHeader>
                <div className="main-container">
                    <ProjectList projects={projectsToRenderMapped} hasTaskAssignedRefetch={hasTaskAssignedRefetch}/>
                </div>
            </>
        )
    }
}

interface SingleTaskCardProps {
    task: Task
    hasTaskAssignedRefetch: any
}

function SingleTaskCard({task, hasTaskAssignedRefetch}: SingleTaskCardProps) {
    // Assign task to user mutation.
    const [
        assignTask,
        {data: assignTaskData, error: assignTaskError, loading: assignTaskLoading},
    ] = useMutation(TASK_ASSIGN_TASK_QUERY, {errorPolicy: 'all'});

    if (assignTaskLoading) {
        return <EpackLoader loaderType="ballcliprotate"/>
    }

    if (assignTaskError) {
        // Alert user about errors.
        Alert.fire("There is something went wrong...", "Please contact administrator.", "error");
        // TODO For production environment error codes could be added (user friendly).
        return <div>{assignTaskError && <p>Error : <b>{assignTaskError.message}</b></p>}</div>;
    }

    if (assignTaskData) {
        const validation_errors = assignTaskData.assignTask.errors;
        if (validation_errors != null && validation_errors.length !== 0) {
            // Alert user about errors.
            Alert.fire(validation_errors[0].messages[0].replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
        } else {
            SuccessAlert.fire("Task has been assigned to you.").then((ok) => {
                hasTaskAssignedRefetch()
            });

        }
    }

    return (
        <Card className="single-task-card">
            <Card.Body className="task-card">
                <Card.Title className="single-task-display-name">
                    <TaskDisplayName task={task}/>
                </Card.Title>
                <div className="single-task-card-info text-muted pb30">
                    <Card.Text className="mb0">
                        Created: {task.create_date}
                    </Card.Text>
                    <Card.Text className="mb0">
                        Products: {task.product_count}
                    </Card.Text>
                    <Card.Text className="mb0">
                        Completion: {task.progress == 0 ? "Not started yet" : task.progress + "%"}
                    </Card.Text>
                    <Card.Text>
                       <div className="col pl30 pr30 mt-4">
                            <ProgressBar label=""
                                         variant="success"
                                         now={task.progress}
                                         className="task-progress-bar"/>
                        </div>
                    </Card.Text>
                </div>

                <div className="single-task-take-it-btn">
                    <EPackButton label="TAKE IT" className="pl30 pr30 mb0"
                                 onClick={() => {
                                     assignTask({variables: {id: task.id}})
                                         .then(({data}) => {
                                         })
                                         .catch(e => {
                                             // Catch Promise.
                                             console.log(e.message)
                                             Alert.fire("Error has occurred:", e.message.replace("GraphQL error: ", "").trim(), "error");
                                         })
                                 }}/>
                </div>
            </Card.Body>
        </Card>
    )
}


interface TaskDisplayNameProps {
    task: Task
}

function TaskDisplayName({task}: TaskDisplayNameProps) {
    if (task.custom_name) {
        return <p>{task.custom_name}</p>
    } else {
        return <><p>{task.project}</p><p>{task.shops}</p></>
    }
}

export default TaskListView;
