import {useQuery} from "@apollo/react-hooks";
import {USER_GET_BY_ID_QUERY} from "queries/users";
import {Alert} from "constants/swal";
import React from "react";
import EpackLoader from 'components/loaders/Loaders';

interface UserInfoProps {
    userId: number
}

function UserInfo({userId}: UserInfoProps) {
    const {loading: userLoading, error: userError, data: userData} = useQuery(USER_GET_BY_ID_QUERY, {
        variables: {id: userId}
    });

    if (userLoading) {
        return <EpackLoader />
    }
    if (userError) {
        Alert.fire("Error occured.", "Please contact administrator.", "error");
        return <p>Error occured :(</p>;
    }

    return <h5>{userData.user.name}</h5>
}

export default UserInfo;
