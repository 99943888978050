import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ReactDOM from "react-dom";
import {PORTAL_ROOT_ID} from "constants/baseConstants";


interface EPackDropdownProps {
    children: Array<any>,
    className?: string,
}


function EPackDropdown({children, className = ""}: EPackDropdownProps) {
    const portalContainer = document.getElementById(PORTAL_ROOT_ID);

    const menuChild = children.filter(function (child: any) {
        return child.type === Dropdown.Menu
    })

    const toggleChild = children.filter(function (child: any) {
        return child.type === Dropdown.Toggle
    })

    return (
        <Dropdown className={className}>
            {toggleChild}
            {ReactDOM.createPortal(menuChild, portalContainer as Element)}
        </Dropdown>
    )
}

export default EPackDropdown;
