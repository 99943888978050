import { gql } from 'apollo-boost';

export const CLIENTS_LIST_QUERY = gql`
    query ClientList($first: Int = 100, $after: String, $search: String, $order: [String]) {
        clients(first: $first, after: $after, search: $search, order: $order) {
            edges {
                node {
                    id
                    name
                    createDate
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;
