import React from "react";

export function prepare_options_waves(list: any) {
    list.options = list.map((value: any, index: number) => {
        return <option key={index} value={value['value']}>{value['label']}</option>
    });
    // list.options.unshift(0)
    // list.options[0] =
    //     <option key={-1} value="0"> Select wave </option>
    return list;
}

export default prepare_options_waves;
