import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import React from "react";
import './SearchBox.css';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

declare type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

interface SearchBoxProps {
    label?: string;
    className?: string;
    value?: string;
    onChangeInput?: React.ChangeEventHandler<FormControlElement>;
    onEnter?: React.KeyboardEventHandler<FormControlElement>;
    typeControl?: string;
}

function SearchBox({label, className, value, onChangeInput, onEnter, typeControl}: SearchBoxProps) {
    let defaultClassName = "search-box "
    if (className) defaultClassName += className

    return (
        <Form inline className={defaultClassName}>
            <InputGroup>
                <FontAwesomeIcon icon={faSearch} className="search-icon"/>
                <FormControl type={typeControl || "text"} placeholder={label || 'Search'}
                             onChange={onChangeInput}
                             value={value}
                             onKeyDown={(event: React.KeyboardEvent<FormControlElement>) => {
                                 if (event.key === 'Enter') {
                                     event.preventDefault()
                                     event.stopPropagation()
                                     if (onEnter)
                                         onEnter(event)
                                 }
                             }}/>
            </InputGroup>
        </Form>
    )
}


export default SearchBox;
