import React from 'react';
import {Pacman, BallPulse, BallClipRotate, LineScale} from 'react-pure-loaders';
import "./Loaders.css"

function EpackLoader(props: any) {
    /**
     * Presents customizable loading animation (https://reactpureloaders.io/).
     *
     * @loaderType is a name of loading animation.
     */

    const defaultAnimationColor = "#57aec7"
    const defaultText = ""

    let loaderProps = {
        color: defaultAnimationColor,
        loading: true
    }

    return (
        <div className={props.loaderStyle ? props.loaderStyle : "loading-text-centered"}>
            {
                props.loaderType === "pacman" ? <Pacman {...loaderProps}  />
                    : props.loaderType === "ballpulse" ? <BallPulse {...loaderProps}  />
                    : props.loaderType === "ballcliprotate" ? <BallClipRotate {...loaderProps}  />
                        : <LineScale {...loaderProps}  />
            }
            {defaultText}
        </div>
    )

}

export default EpackLoader;
