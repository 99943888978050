import React, {ChangeEvent, FormEvent, useEffect} from 'react';
import {USER_UPDATE_MUTATION, USER_GET_BY_ID_QUERY} from 'queries/users';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import 'components/base/EPackForm/EPackForm.css';
import './User.css';
import 'base.css';
import {UserInterface} from "components/helpers/interfaces";
import {Alert} from "constants/swal";
import EpackLoader from 'components/loaders/Loaders';

const {useState} = require('react');

export function UpdateUserInitial(props: RouteComponentProps<any>) {
    const user_id = parseInt(props.match.params.id, 10);
    const {loading: queryLoading, error: queryError, data} = useQuery(USER_GET_BY_ID_QUERY, {
        variables: {id: user_id},
    });
    if (queryLoading) {
        return <EpackLoader />
    }
    if (queryError) {
        Alert.fire("Ups...User could not be found.", "Please contact administrator.", "error");
        return <p>We can not find user :(</p>;
    }

    if (data) {
        return <UpdateUser user={data.user}/>;
    }
}


function UpdateUser({user}: UserInterface) {
    // This is Update user Component, which is being called from UpdateUserInitial component to omit
    // react infinite loop problem.
    // Setting initial values from props.
    const id = user.id
    const [email, setEmail] = useState(user.email);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [isManager, setIsManager] = useState(user.isManager);
    const [isAuditor, setIsAuditor] = useState(user.isAuditor);

    let errors: { [msg: string]: string } = {};
    const [validated, setValidated] = useState(false);

    // Handles state of checkbox group validness. At least one of two is required.
    const [checkboxGroupValid, setCheckboxGroupValid] = useState(isAuditor || isManager);
    useEffect(() => {
        setCheckboxGroupValid(isManager || isAuditor);
    }, [isManager, isAuditor]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            editUser({variables: {id, email, firstName, lastName, isManager, isAuditor}}).then(({data}) => {
                const validation_errors = data.editUser.errors;
                if (validation_errors != null && validation_errors.length !== 0) {
                    setValidated(false);
                }
            })
                .catch(mutationError => {
                    // Catch Promise.
                    Alert.fire("Error has occurred:", mutationError.message.replace("GraphQL error: ", "").trim(), "error");
                });

        }
        setValidated(true);
    };

    // We are using history in order to make redirects.
    let history = useHistory();

    // Performing mutation on User.
    const [
        editUser,
        {data, error: mutationError, loading: mutationLoading},
    ] = useMutation(USER_UPDATE_MUTATION, {errorPolicy: 'all'});

    if (mutationError) {
        Alert.fire("There is something went wrong...", "Please contact administrator.", "error");
        // TODO For production environment error codes could be added (user friendly).
        return <div>{mutationError && <p>Error : <b>{mutationError.message}</b></p>}</div>;
    }

    if (data) {
        const validation_errors = data.editUser.errors;
        if (validation_errors != null && validation_errors.length !== 0) {
            for (const err of validation_errors) errors[err.field] = err.messages[0];
        } else {
            history.push('/users');
        }
    }

    return (
        <div>
            <div className="user-form-wrapper">
                <Form
                    id="editUserForm"
                    className="p-4"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                >
                    <p className="h4 mb-4 f-1">
                        <b>Edit user</b>
                    </p>
                    <hr/>
                    {errors['__all__'] && (
                        <div className="alert alert-danger" role="alert">
                            {errors['__all__']}
                        </div>)}
                    <Form.Group controlId="formHorizontalEmail">
                        <Form.Label column>
                            <b>Email:</b>
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control
                                required
                                type="email"
                                placeholder="Email"
                                value={email}
                                isInvalid={Boolean(errors['email'])}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                            />
                            {errors['email'] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors['email']}
                                </Form.Control.Feedback>)}
                        </Col>
                    </Form.Group>
                    <Form.Group controlId="formHorizontalName">
                        <Form.Label column sm={2}>
                            <b>Full Name:</b>
                        </Form.Label>
                        <Form.Group as={Row}>
                            <Col>
                                <Form.Control
                                    title="First name"
                                    placeholder="First name"
                                    name="firstName"
                                    isInvalid={Boolean(errors['first_name'])}
                                    value={firstName}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                                />
                                {errors['first_name'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['first_name']}
                                    </Form.Control.Feedback>)}
                            </Col>
                            <Col>
                                <Form.Control
                                    title="Last name"
                                    placeholder="Last name"
                                    name="lastName"
                                    isInvalid={Boolean(errors['last_name'])}
                                    value={lastName}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                                />
                                {errors['last_name'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['last_name']}
                                    </Form.Control.Feedback>)}
                            </Col>
                        </Form.Group>
                    </Form.Group>
                    <Form.Group controlId="formHorizontalPermission">
                        <Form.Label column className="required">
                            <b>Permissions:</b>
                        </Form.Label>

                        <Col className="permission-checkbox-wrapper">
                            <Form.Check
                                custom
                                inline
                                type="checkbox"
                                label="Is manager"
                                // id="isManager"
                                checked={isManager}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setIsManager(e.target.checked)}
                                required={!checkboxGroupValid}
                            />

                            <Form.Check custom inline type="checkbox">
                                <Form.Check.Input
                                    required={!checkboxGroupValid}
                                    checked={isAuditor}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setIsAuditor(e.target.checked)}
                                />
                                <Form.Check.Label>Is auditor</Form.Check.Label>

                                <Form.Control.Feedback type="invalid" className="checkbox-feedback">
                                    Please select at least one option
                                </Form.Control.Feedback>
                            </Form.Check>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="create-form-submit-btn my-4">
                        <Col>
                            <Button type="submit">Save</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </div>
            <div>{mutationLoading && (<EpackLoader loaderType="ballcliprotate"/>)}</div>
        </div>
    );
}

export default UpdateUserInitial;
