import React, {MouseEventHandler, useEffect, useState} from 'react';
import './UserList.css';
import {faAngleDoubleDown, faEdit, faPlus, faPoll, faUserSlash} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import 'Shortcuts.css';
import LocalHeader from "components/base/HeaderBar/LocalHeader/LocalHeader";
import EPackButton from "components/base/EPackButton/EPackButton";
import {USER_DELETE_MUTATION, USER_LIST_QUERY} from 'queries/users';
import SearchBox from "components/base/SearchBox/SearchBox";
import {useHistory} from 'react-router-dom';
import {useMutation, useQuery} from "@apollo/react-hooks";
import Button from "react-bootstrap/Button";
import {Alert, SuccessAlert} from 'constants/swal';
import {USERS_PER_PAGE} from 'constants/baseConstants';
import EpackLoader from 'components/loaders/Loaders';
import Card from "react-bootstrap/Card";


// The list of queries for user list.
function _getQueryVariables() {
    const first = USERS_PER_PAGE; // The number of users per page.
    const order = '-createDate'; // Order users by.
    return {first, order};
};

function UserListView() {
    // Set initial state.
    const [search, setSearch] = useState('')
    const [isFetching, setIsFetching] = useState(false);
    const [isManualFetching, setManualIsFetching] = useState(false);

    function isScrolling() {
        // Finding the bottom of the page.
        const bottom = document.documentElement.scrollHeight - document.documentElement.scrollTop === window.innerHeight;
        if (bottom) {
            setIsFetching(true)
        }
    }

    const moreData = () => {
        let variables = {
            search: search,
        }
        if (data && data.users.pageInfo.endCursor != null) {
            // @ts-ignore
            variables["after"] = data.users.pageInfo.endCursor
        }
        // Prevent loading results from beginning.
        if (variables.hasOwnProperty('after')) {
            fetchMore({
                variables: variables,
                updateQuery: (prevResult, {fetchMoreResult}) => {
                    // @ts-ignore

                    fetchMoreResult.users.edges = [
                        // @ts-ignore
                        ...prevResult.users.edges,
                        // @ts-ignore
                        ...fetchMoreResult.users.edges,
                    ];
                    setIsFetching(false)
                    window.scrollTo(0, window.pageYOffset);
                    return fetchMoreResult;
                },
            });
        }
    }

    const moreManualData = () => {
        let variables = {
            search: search,
        }
            fetchMore({
                variables: variables,
                updateQuery: (prevResult, {fetchMoreResult}) => {
                    // @ts-ignore
                    fetchMoreResult.users.edges = [
                        // @ts-ignore
                        ...fetchMoreResult.users.edges,
                    ];
                    setManualIsFetching(false)
                    return fetchMoreResult;
                },
            });
    }

    useEffect(() => {
        window.addEventListener("scroll", isScrolling);
        return () => window.removeEventListener("scroll", isScrolling);
    }, [])

    useEffect(() => {
        if (isFetching) {
            moreData();
        }
        if (isManualFetching) {
            moreManualData();
        }
    }, [moreData, isFetching, moreManualData, isManualFetching]);

    // Get user list from API.
    const {loading, error, data, fetchMore} = useQuery(USER_LIST_QUERY, {
        variables: _getQueryVariables(),
    });
    if (loading) {
        return <EpackLoader />
    }
    if (error) {
        Alert.fire(error.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
        return <div>Error: {error.message}</div>;
    }
    // TODO Place Search button to SearchBox.
    // TODO Place Show more button in separate function and pass it to buttons onClick.
    if (data) {
        // Map data from API.
        const usersToRender = data.users.edges;
        const usersResultsHasNextPage = data.users.pageInfo.hasNextPage
        let usersToRenderMapped = usersToRender.map((user: {
            node: {
                id: any;
                role: any;
                createDate: any;
                fullName: any;
                email: any;
            };
        }) => {
            return {
                id: user.node.id,
                email: user.node.email,
                name: user.node.fullName,
                create_date: user.node.createDate,
                role: user.node.role
            };
        });
        return (
            <>
                {/* TODO: LocalHeader must be present even during loading and errors. */}
                <LocalHeader>
                    <EPackButton label="Add user" icon={faPlus} href="/add-user"/>
                    <EPackButton className="ml-1" label="Time statistics for all users" icon={faPoll} href="/stats-for-all"/>
                    <SearchBox onChangeInput={(e) => setSearch(e.target.value)} onEnter={() => {
                        setManualIsFetching(true)
                    }}/>
                    <Button variant="primary"
                            onClick={() => {
                                setManualIsFetching(true)
                            }}
                    >
                        Search
                    </Button>
                </LocalHeader>
                <div className="main-container">
                    {usersToRenderMapped.length > 0 ?
                        <UserList users={usersToRenderMapped}/>
                        :
                        <Card className="text-center">
                            <Card.Body className="h5">There are no users to show.</Card.Body>
                        </Card>}
                    <div>
                        {isFetching && usersResultsHasNextPage &&
                        <EpackLoader loaderType="ballcliprotate" loaderStyle="loading-text"/>}
                        {isManualFetching && <EpackLoader loaderType="ballcliprotate"/>}</div>
                    {usersResultsHasNextPage ?
                        <span className="d-flex justify-content-center">
                            <span className="pt-3 mb-3">

                                                        <div className="ml-1">
                                                            <EPackButton variant="primary"
                                                                         label="Show more Users"
                                                                         icon={faAngleDoubleDown}
                                                                         onClick={() => {
                                                                             setIsFetching(true)
                                                                         }}
                                                            />
                                                        </div>
                            </span></span>
                        :
                        <></>}
                </div>
            </>
        )
    }
}


interface User {
    id: string;
    name: string;
    email: string;
    role: string;
    create_date: string
}

interface UserListProps {
    users: User[]
}

function UserList({users}: UserListProps) {
    const objects = []
    for (const [pk, user] of users.entries()) {
        objects.push(<SingleUserBox user={user} key={pk}/>)
    }

    return <>{objects}</>
}


interface SingleUserBoxProps {
    user: User
}

function SingleUserBox({user}: SingleUserBoxProps) {
    return (
        <div className="white-box white-box-list">
            <div className="pl0 col pl0">
                <UserDataSection className="user-name" data={user.name}/>
                <UserDataSection className="user-email" data={user.email}/>
            </div>
            <div className="col">
                <UserDataSection className="user-role" data={user.role}/>
                <UserDataSection className="user-create-date" data={user.create_date}/>
            </div>
            <UserControlButtons user={user}/>
        </div>
    )
}


interface UserDataSectionProps {
    className: string,
    data: string
}

function UserDataSection({className, data}: UserDataSectionProps) {
    return <div className={className}>{data}</div>
}


function UserControlButtons(user: any) {
    // Deactivate user mutation.
    const history = useHistory();
    const [
        deleteUser,
        {data: deleteUserData, error: deleteUserError, loading: deleteUserLoading},
    ] = useMutation(USER_DELETE_MUTATION, {errorPolicy: 'all'});

    if (deleteUserLoading) {
        return <EpackLoader loaderType="ballcliprotate"/>
    }

    if (deleteUserError) {
        // Alert user about errors.
        Alert.fire("There is something went wrong...", "Please contact administrator.", "error");
        // TODO For production environment error codes could be added (user friendly).
        return <div>{deleteUserError && <p>Error : <b>{deleteUserError.message}</b></p>}</div>;
    }

    if (deleteUserData) {
        const validation_errors = deleteUserData.deleteUser.errors;
        if (validation_errors != null && validation_errors.length !== 0) {
            // Alert user about errors.
            Alert.fire(validation_errors[0].messages[0].replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
        } else {
            // TODO refetch() function from useQuery must be used. But there was a problem with TypeError: refetch is not a function.
            // refetch()
            SuccessAlert.fire("User " + user.user.name + " was deleted!").then((ok) => {
                history.go(0);
            });
        }
    }
    return (
        <div className="col user-control-buttons">
            <UserControlSingleButton label="Stats" icon={faPoll} variant="outline-primary"
                                     href={'/stats/' + user.user.id}/>
            <UserControlSingleButton label="Edit" icon={faEdit} variant="outline-primary"
                                     href={'/update-user/' + user.user.id}/>
            <UserControlSingleButton label="Delete" icon={faUserSlash} variant="outline-danger"
                                     onClick={() => Alert.fire({
                                         title: "User deletion",
                                         text: "Are you sure you want to delete user: " + user.user.name + "?",
                                         showCancelButton: true,
                                         confirmButtonText: "Yes",
                                         cancelButtonText: 'No',
                                         allowOutsideClick: false,
                                         icon: "warning"
                                     })
                                         .then((deleteSelected) => {
                                             if (deleteSelected.isConfirmed) {
                                                 deleteUser({variables: {id: user.user.id}})
                                                     .then(({data}) => {
                                                     })
                                                     .catch(e => {
                                                         // Catch Promise.
                                                         Alert.fire("Error has occurred:", e.message.replace("GraphQL error: ", "").trim(), "error");
                                                     })
                                             }
                                         })}/>
        </div>
    )
}


interface UserControlSingleButtonProps {
    label: string,
    icon: IconProp,
    variant?: string,
    href?: string
    onClick?: MouseEventHandler
}

function UserControlSingleButton({label, icon, variant, href, onClick}: UserControlSingleButtonProps) {
    return (
        <div className="col">
            <EPackButton label={label} icon={icon} variant={variant} href={href} onClick={onClick}/>
        </div>
    )
}


export default UserListView;
