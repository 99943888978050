import React, {FormEvent, RefObject, useState} from 'react';
import "./Comparison.css"
import LocalHeader from "components/base/HeaderBar/LocalHeader/LocalHeader";
import EPackButton from "components/base/EPackButton/EPackButton";
import {useHistory} from "react-router-dom";
import {
    faCheck,
    faComment,
    faExclamationTriangle,
    faList,
    faRedo,
    faSignOutAlt,
    faCheckDouble
} from "@fortawesome/free-solid-svg-icons";
import {Container, Form} from 'react-bootstrap';
import {useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import {
    COMPARISON_GET_BY_USER,
    SET_COMPARISON_AUDITOR_ANSWER_MUTATION,
    UNDO_LAST_AUDITOR_ANSWER_MUTATION
} from "queries/comparisons";
import {Alert, SuccessAlert, WarningAlert} from "constants/swal";
import {Redirect} from "react-router";
import {ShopInterface} from "components/helpers/interfaces";
import {ISSUE_CREATE_MUTATION, ISSUE_PROJECTS_TASKS_QUERY} from "queries/issues";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/cjs/Modal";
import {PORTAL_ROOT_ID} from "constants/baseConstants";
import EpackLoader from 'components/loaders/Loaders';
import {LEAVE_TASK_MUTATION} from "queries/tasks";

interface CreateIssueModalProps {
    taskId?: string
}

function CreateIssueModal({taskId}: CreateIssueModalProps) {
    const [modalShow, setModalShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const toggleModalShow = () => setModalShow(!modalShow);

    const [description, setDescription] = useState('');

    // Create issue mutation.
    const [
        createIssue, {loading}
    ] = useMutation(ISSUE_CREATE_MUTATION, {errorPolicy: 'all'});

    if (loading) {
        return <EpackLoader loaderType="ballcliprotate"/>
    }

    const handleSubmit = (event: FormEvent) => {
        const form = event.currentTarget as HTMLInputElement;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            createIssue({
                variables: {
                    description: description,
                    task: taskId
                }
            }).then(({data}) => {
                const validation_errors = data.createIssue.errors;
                if (validation_errors != null && validation_errors.length !== 0) {
                    setValidated(false);
                    Alert.fire("Error has occurred:", validation_errors[0].messages[0].replace("GraphQL error: ", "").trim(), "error");
                } else {
                    SuccessAlert.fire("Issue has been created.").then((ok) => {
                        toggleModalShow()
                    });
                }
            })
                .catch(error => {
                    // Catch Promise.
                    Alert.fire("Error has occurred:", error.message.replace("GraphQL error: ", "").trim(), "error");
                })
            toggleModalShow()
            event.preventDefault();
        }
        setValidated(true);
    };

    return (
        <>
            <EPackButton
                label="Report a problem"
                icon={faComment}
                className="inverted-button"
                onClick={toggleModalShow}
            />

            <Modal size="xl" show={modalShow} onHide={toggleModalShow}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create issue</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container-fluid">
                            {/* * Description */}
                            <Form.Group>
                                <Form.Label>Description:</Form.Label>
                                <Form.Control required as="textarea" placeholder="Description"
                                              onChange={
                                                  (e) => setDescription(e.target.value)}/>
                            </Form.Group>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" type="submit">Submit Issue</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

interface ProjectsTask {
    name: string;
    taskStats: any
}

interface ProjectsTasksListProps {
    projects: ProjectsTask[]
}

function ProjectsTasksList({projects}: ProjectsTasksListProps) {
    const objects: never[] = []
    for (const [pk, project] of projects.entries()) {
        // @ts-ignore
        objects.push(<><div className="project-name">{project.pk}{project.name}</div>
            <div className="data-row tasks-info">
                {project.taskStats[0].unassigned !== 0 ? <><span
                    className="project-tasks-unassigned">Unassigned {project.taskStats[0].unassigned}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</> : <></>}
                {project.taskStats[0].inProgress !== 0 ? <><span className="project-tasks-in-progress">In progress {project.taskStats[0].inProgress}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</> : <></>}
                {project.taskStats[0].todo !== 0 ? <><span
                    className="project-tasks-todo">Todo {project.taskStats[0].todo}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</> : <></>}
                {project.taskStats[0].paused !== 0 ? <><span
                    className="project-tasks-paused">Paused {project.taskStats[0].paused}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</> : <></>}
                {project.taskStats[0].done !== 0 ? <><span
                    className="project-tasks-done">Done {project.taskStats[0].done}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</> : <></>}
                {project.taskStats[0].total !== 0 ? <><span
                    className="project-tasks-total">Total {project.taskStats[0].total}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</> : <><span
                    className="project-tasks-total">No tasks found</span></>}
              {project.taskStats[0].productsCount !== 0 ? <><span
                className="project-tasks-comparisons-count">Total comparisons to check: {project.taskStats[0].productsCount}</span></> : <><span
                className="project-tasks-comparisons-count">No comparisons found</span></>}
            </div>
        </>)
    }
    return <>{objects}</>
}

function TaskListModal() {
    const [taskListModalShow, setTaskListModalShow] = useState(false);
    const toggleTasksModalShow = () => setTaskListModalShow(!taskListModalShow);

    // Query all tasks for projects.
    const [getProjectsTasksData, {
        loading: issueProjectsTasksLoading,
        error: issueProjectsTasksError,
        data: issueProjectsTasksData
    }] = useLazyQuery(ISSUE_PROJECTS_TASKS_QUERY);

    function getIssueProjectsTasks() {
        getProjectsTasksData()
        toggleTasksModalShow()
    }

    if (issueProjectsTasksLoading) {
        return <EpackLoader loaderType="ballcliprotate"/>
    }

    if (issueProjectsTasksError) {
        Alert.fire(issueProjectsTasksError.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
        return <div>Error: {issueProjectsTasksError.message}</div>;
    }

    if (issueProjectsTasksData && issueProjectsTasksData.issueProjectsTasks.edges) {
        const issueProjectsTasks = issueProjectsTasksData.issueProjectsTasks.edges
        let issueProjectsTasksMapped = issueProjectsTasks.map((project: {
            node: {
                name: any;
                taskStats: any;
            };
        }) => {
            return {
                name: project.node.name,
                taskStats: project.node.taskStats,
            };
        });

        return (
            <><EPackButton
                label="Task list"
                icon={faList}
                className="inverted-button"
                onClick={() => getIssueProjectsTasks()}
            />
            <Modal size="xl" show={taskListModalShow} onHide={toggleTasksModalShow}>
            <Modal.Header closeButton>
                <Modal.Title>Task list</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <ProjectsTasksList projects={issueProjectsTasksMapped}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" type="submit" onClick={toggleTasksModalShow}>Close</Button>
            </Modal.Footer>
        </Modal></>)
    }
    return (
        <>
            <EPackButton
                label="Task list"
                icon={faList}
                className="inverted-button"
                onClick={() => getIssueProjectsTasks()}
            />
        </>
    )
}

interface AssignedComparison {
    taskId: string
    customName: string
    shops: Array<ShopInterface>
    comparisons: {
        id: string;
        lastComparisonId?: string
        referenceImgUrl: string;
        referenceImg: string; // URL to image from epack media storage.
        progressPercentage: number;
        code: string;
        parsedImgComparisons: Array<{
            id: string;
            parsedImgUrl: string
            parsedImg: string   // // URL to image from epack media storage.
        }>
    }
}

function ComparisonView() {
    const {loading, error, data, fetchMore} = useQuery(COMPARISON_GET_BY_USER, {
        variables: {first: 1},
    });
    if (loading) {
        return <EpackLoader/>
    }
    if (error) {
        Alert.fire(error.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
        return <div>Error: {error.message}</div>;
    }

    if (data && !!data.taskInProgress.edges.length) {
        if (data.taskInProgress.edges[0].node.comparisonsAssigned === null) return <Redirect to='tasks'/>

        const endCursor = data.taskInProgress.pageInfo.endCursor.replace('=', '')

        const comparison: AssignedComparison = {
            taskId: data.taskInProgress.edges[0].node.id,
            customName: data.taskInProgress.edges[0].node.customName,
            shops: data.taskInProgress.edges[0].node.shops,
            comparisons: data.taskInProgress.edges[0].node.comparisonsAssigned
        }
        return (
            <>
                <GlobalHeaderOverride
                    comparison={comparison.comparisons}
                    customName={comparison.customName}
                    shops={comparison.shops}
                />
                <ComparisonLocalHeader comparison={comparison.comparisons} taskId={comparison.taskId}/>
                <ComparisonBody comparison={comparison.comparisons} task_id={comparison.taskId}
                                fetchMoreComparisons={{fetchMore: fetchMore, endCursor: endCursor}}/>
            </>
        )
    }

    return <noscript/>
}

interface SingleComparisonProps {
    comparison: AssignedComparison["comparisons"]
    taskId?: AssignedComparison['taskId']
    customName?: AssignedComparison['customName']
    shops?: AssignedComparison['shops']
}

function GlobalHeaderOverride({comparison, customName, shops}: SingleComparisonProps) {
    const shopName = shops ? shops[0].name : "";

    return (
        <div className="global-header-override">
            <div className="project-header">{customName}</div>
            <div className="shop-header">{shopName}</div>
            <div className="project-header">Code: {comparison.code}</div>
        </div>
    )
}

function ComparisonLocalHeader({comparison, taskId}: SingleComparisonProps) {
    let history = useHistory();
    const [
        undoLastAuditorAnswer, {error, loading},
    ] = useMutation(UNDO_LAST_AUDITOR_ANSWER_MUTATION, {errorPolicy: 'all'});
    const [
        leaveTask, {
            data: leaveTaskMutationData,
            error: leaveTaskMutationError,
            loading: leaveTaskMutationLoading
        }
    ] = useMutation(LEAVE_TASK_MUTATION, {errorPolicy: 'all'});

    if (leaveTaskMutationLoading) {
        return <EpackLoader loaderType="ballcliprotate"/>
    }

    if (leaveTaskMutationError) {
        // Alert user about errors.
        Alert.fire('There is something went wrong...', 'Please contact administrator.', 'error');
        // TODO Add 500 component.
        return <div>{leaveTaskMutationError &&
        <p>Error : <b>{leaveTaskMutationError.message}</b></p>}</div>;
    }

    if (leaveTaskMutationData) {
        const validation_errors = leaveTaskMutationData.leaveTask.errors;
        if (validation_errors != null && validation_errors.length !== 0) {
            // Alert user about errors.
            Alert.fire(validation_errors[0].messages[0], 'Please try again later.', 'error');
        } else {
            SuccessAlert.fire('Task status was changed!').then((ok) => {
            });
        }
    }
    if (loading) {
        return <EpackLoader loaderType="ballcliprotate"/>
    }
    if (error) {
        Alert.fire(error.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
        return <div>Error: {error.message}</div>;
    }

    const onUndoClick = () => {
        undoLastAuditorAnswer({
            variables: {
                comparisonId: comparison.lastComparisonId,
            }
        })
            .then(() => {
                window.location.href = "/tasks";
            })
            .catch(e => {
                // Catch Promise.
                console.log(e.message)
                Alert.fire("Error has occurred:", e.message.replace("GraphQL error: ", "").trim(), "error");
            })
    }


    return (
        <LocalHeader>
            <div className="header-wrapper">
                <div>
                    {
                        comparison.lastComparisonId &&
                        <EPackButton
                            className="undo-button"
                            label="Oops, undo!"
                            icon={faRedo}
                            onClick={onUndoClick}
                        />
                    }
                    <EPackButton
                        label="Leave task"
                        className="leave-task-button"
                        icon={faSignOutAlt}
                        onClick={() => WarningAlert.fire({
                            title: "Leave task.",
                            text: "Are you sure you want leave following task?",
                            showCancelButton: true,
                            confirmButtonText: "Leave",
                            cancelButtonText: "No",
                            allowOutsideClick: false,
                        }).then((leaveSelected) => {
                            if (leaveSelected.isConfirmed) {
                                leaveTask({variables: {"task_id": taskId}}
                                ).then(({data}) => {
                                    const validation_errors = data.leaveTask.errors;
                                    if (validation_errors != null && validation_errors.length !== 0) {
                                        Alert.fire("Error has occurred:", validation_errors[0].messages[0].replace("GraphQL error: ", "").trim(), "error");
                                    } else {
                                        SuccessAlert.fire("You successfully left task.").then((ok) => {
                                            history.go(0);
                                        });
                                    }
                                })
                                    .catch(error => {
                                        // Catch Promise.
                                        // TODO Add 500 component.
                                        Alert.fire("Error has occurred:", error.message.replace("GraphQL error: ", "").trim(), "error");
                                    })
                            }
                        })
                        }
                    />
            </div>
            <div>
                <TaskProgressBar comparison={comparison}/>
            </div>
            <div>
                <div className="info-buttons">
                    <CreateIssueModal taskId={taskId}/>
                    <TaskListModal/>
                </div>
            </div>
        </div>
</LocalHeader>
)
}


function TaskProgressBar({comparison}: SingleComparisonProps) {
    let bodyStyles = document.body.style;
    const percentage = comparison.progressPercentage + "%"
    bodyStyles.setProperty('--progress-bar-completion', percentage);

    return (
        <div className="task-progress-bar-wrapper">
            <div className="task-progress-bar"/>
        </div>
    )
}

interface BodyComparisonProps {
    comparison: AssignedComparison["comparisons"]
    task_id: AssignedComparison['taskId'],
    fetchMoreComparisons: {
        fetchMore: any,
        endCursor: string
    },
}

function ComparisonBody({comparison, task_id, fetchMoreComparisons}: BodyComparisonProps) {
    const [multiple, setMultiple] = useState(false)
    const toggleMultiple = () => setMultiple(!multiple);

    const [activeItems, setActiveItems] = useState<string[]>([])

    // Helper func to manipulate activeItems State
    const toggleActiveItems = (id: string) => {
        const appendActiveItem = (id: string) => {
            setActiveItems((prevState) => {
                prevState.push(id)
                return prevState
            })
        }
        const removeActiveItem = (id: string) => {
            setActiveItems((prevState) => {
                const index = prevState.indexOf(id)
                if (index > -1) prevState.splice(index, 1);
                return prevState
            })
        }

        if (activeItems.indexOf(id) < 0) appendActiveItem(id)
        else removeActiveItem(id)
    }

    const [
        setAuditorAnswer, {error: auditorAnswerError, loading: auditorAnswerLoading},
    ] = useMutation(SET_COMPARISON_AUDITOR_ANSWER_MUTATION, {errorPolicy: 'all'});
    if (auditorAnswerLoading) {
        return <EpackLoader loaderType="ballcliprotate"/>
    }
    if (auditorAnswerError) {
        Alert.fire(auditorAnswerError.message.replace("GraphQL error: ", "").trim(), "Please try again later.", "error");
        return <div>Error: {auditorAnswerError.message}</div>;
    }

    const gridItems = []
    const onGridItemClick = (ref: RefObject<HTMLDivElement>, parsedComparisonId: string) => {
        if (ref.current === null) return

        ref.current.classList.toggle("active-grid-item")
        toggleActiveItems(parsedComparisonId)

        if (!multiple) sendAuditorAnswer()
    }
    const resetImages = () => {
        // @ts-ignore
        // document.getElementsByClassName("reference-img")[0].src = ""
        document.getElementsByClassName("reference-img")[0].style.display = "none"

        for (let gridItem of document.getElementsByClassName("grid-item")) {
            // @ts-ignore
            gridItem.children[0].src = ""
        }
    }
    const sendAuditorAnswer = () => {
        setAuditorAnswer({
            variables: {
                comparisonId: comparison.id,
                answerComparisonList: activeItems,
                taskId: task_id
            }
        })
            .then(() => {
                resetImages()
                setMultiple(false);
                fetchMoreComparisons.fetchMore({
                    variables: {
                        after: fetchMoreComparisons.endCursor
                    },
                    // @ts-ignore
                    updateQuery: (prevResult, {fetchMoreResult}) => {
                        if (!fetchMoreResult.taskInProgress.edges.length) window.location.reload();
                        else return fetchMoreResult;
                    },
                }
                )
                // @ts-ignore
                document.getElementsByClassName("reference-img")[0].style.display = ""
            })
            .catch(e => {
                // Catch Promise.
                console.log(e.message)
                Alert.fire("Error has occurred:", e.message.replace("GraphQL error: ", "").trim(), "error");
            })
    }

    const portalContainer: HTMLElement | null = document.getElementById(PORTAL_ROOT_ID);
    const constructPortalItem = (event: any, ref: RefObject<HTMLDivElement>, parsedComparisonID: string) => {
        if (!portalContainer || portalContainer.firstChild) return;

        const el = event.currentTarget;
        const clone = el.cloneNode(true);
        const rect = el.getBoundingClientRect();
        const wrapper = document.getElementsByClassName("parsed-img-wrapper")[0];
        const referenceImg = document.getElementsByClassName("reference-img")[0];
        const referenceRect = referenceImg.getBoundingClientRect();
        const translateScale = parseFloat(getComputedStyle(document.body).getPropertyValue('--translate-scale'))
        const halfTranslatedWidth = el.offsetWidth * (translateScale - 1) / 2
        const translatedLeft = rect.left - halfTranslatedWidth

        clone.style.top = rect.top + "px";
        clone.style.left = (referenceRect.right > translatedLeft ? rect.left + halfTranslatedWidth / 2 : rect.left) + "px";
        clone.style.height = el.offsetHeight + "px";
        clone.style.width = el.offsetWidth + "px";
        clone.classList.add("grid-item-clone");

        // Handle events
        clone.addEventListener('mouseleave', clone.remove);
        clone.addEventListener('click', function () {
            clone.classList.toggle("active-grid-item")
            onGridItemClick(ref, parsedComparisonID)
            clone.remove()
        });
        clone.addEventListener('wheel', function (e: WheelEvent) {
            // @ts-ignore
            if ((e.deltaY > 0 && wrapper.scrollTop === wrapper.scrollTopMax) ||
                (e.deltaY < 0 && wrapper.scrollTop === 0)) return;
            clone.remove()
        });

        // Append prepared clone to portal-root
        portalContainer.appendChild(clone);
    }

    for (const parsedComparison of comparison.parsedImgComparisons) {
        const ref: RefObject<HTMLDivElement> = React.createRef();
        gridItems.push(
            <Container
                onMouseMove={(event: any) => {
                    constructPortalItem(event, ref, parsedComparison.id)
                }}
                className="grid-item"
                ref={ref}
                key={parsedComparison.id}
            >
                <img src={localStorage.getItem("epackMediaHostUrl") + parsedComparison.parsedImg}
                     alt=""
                />
            </Container>
        )
    }

    const nextButtonRef: RefObject<HTMLButtonElement> = React.createRef()
    const pickAllPhotosButtonRef: RefObject<HTMLButtonElement> = React.createRef()

    return (
        <div className="comparison-wrapper">
            <div className="reference-wrapper">
                <p className="reference-header">Reference</p>
                <img className="reference-img"
                     src={localStorage.getItem("epackMediaHostUrl") + comparison.referenceImg}
                     alt="" title="Click to open image in a new tab."
                     onClick={() => {
                         // TS throws error that object might be null.
                         //      This is due to the fact that popups might be blocked in browser. So, ignored.
                         // @ts-ignore
                         window.open(localStorage.getItem("epackMediaHostUrl") + comparison.referenceImg,).focus();
                     }}
                />
            </div>

            <div className="parsed-img-wrapper">
                <div className="top-grid-buttons">
                    <Form.Check inline custom type="checkbox" id="choose-multiple-check">
                        <Form.Check.Input

                            onChange={() => {
                            }}
                            onClick={() => {
                                if (nextButtonRef.current === null) return
                                nextButtonRef.current.classList.toggle("disabled")
                                if (pickAllPhotosButtonRef.current === null) return
                                pickAllPhotosButtonRef.current.classList.toggle("disabled")
                                toggleMultiple()
                            }}
                        />
                        <Form.Check.Label>Choose multiple</Form.Check.Label>
                    </Form.Check>
                    <div className="bottom-grid-buttons">
                        <div className="info-buttons">
                            <EPackButton
                                label="Pick all photos"
                                icon={faCheckDouble}
                                reference={pickAllPhotosButtonRef}
                                className="inverted-button all-next-button"
                                onClick={() => {
                                    if (multiple) return
                                    for (const parsedComparison of comparison.parsedImgComparisons) {
                                    toggleActiveItems(parsedComparison.id)
                                    }
                                    sendAuditorAnswer()
                                }}
                            />
                            <EPackButton
                                label="Next one"
                                icon={faCheck}
                                className="inverted-button next-button disabled"
                                reference={nextButtonRef}
                                onClick={() => {
                                    if (!multiple) return
                                    sendAuditorAnswer()
                                }}
                            />
                            <EPackButton
                                label="Photo not found"
                                icon={faExclamationTriangle}
                                className="inverted-button danger-button"
                                onClick={() => {
                                    setActiveItems([])
                                    sendAuditorAnswer()
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid-items-wrapper">
                    {gridItems}
                </div>
            </div>
        </div>
    )
}

export default ComparisonView;
