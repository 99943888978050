import React from 'react';
import './LocalHeader.css';
import 'components/base/HeaderBar/HeaderBar.css'
interface LocalHeaderProps {children?: React.ReactNode}

function LocalHeader({children}: LocalHeaderProps) {
    return (
        <div>
          <Header>
            {children}
          </Header>
            {/*We render empty div in order to prevent header from overlapping main page content, */}
            {/*because it is set to position:fixed*/}
            <Filler />
        </div>
    );
}


interface HeaderProps {children?: React.ReactNode}

function Header({children}: HeaderProps) {
    return (
        <div id="header-local" className="header-container">
            {children}
        </div>
    );
}


function Filler() {
    return <div className="pt64" />;
}


export default LocalHeader;
