import React from "react";
import {Redirect} from "react-router";
import {USER_ROLE} from "constants/baseConstants";
import {Alert} from "constants/swal";

export function MainPage() {
    const role = localStorage.getItem('userRole');
    let path: string = ""

    if (role === USER_ROLE.MANAGER || role === USER_ROLE.SUPERUSER) path = '/projects';
    else if (role === USER_ROLE.AUDITOR) path = '/tasks';
    else {
        Alert.fire('Unknown role', 'Please contact administrator.', 'error');
        return <div>Unknown Role</div>;
    }

    return (<Redirect to={path}/>)
}

export default MainPage
