import React from "react";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import './ProjectTasks.css'
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'Shortcuts.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowClose, faPause, faPlay, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {
    GET_TASK_BY_PROJECT,
    LEAVE_TASK_MUTATION,
    SET_TASK_STATUS_MUTATION,
    TASK_DELETE_MUTATION
} from "queries/tasks";
import {Alert, SuccessAlert, WarningAlert} from 'constants/swal';
import {useHistory} from 'react-router-dom';
import {TASK_STATUS} from "constants/baseConstants";
import EpackLoader from 'components/loaders/Loaders';


interface Task {
    id: number;
    name: string;
    assignee?: string;
    assigneeId?: number;
    createDate: string;
    productCount: number;
    progress: number;
    status: number;
}

interface ProjectTasksProps {
    project_pk: string
    accordionKey: number
}


function ProjectTasks({project_pk, accordionKey}: ProjectTasksProps) {
    const [getTasksData, {called, loading, data}] = useLazyQuery(GET_TASK_BY_PROJECT, {
        variables: {project_id: project_pk},
        fetchPolicy: "network-only"
    });
    const fetchTasks = () => {
        // Custom event sent by accordion toggle
        const _fetchTasks = (async () => {
            if (!called) getTasksData()
        });
        _fetchTasks().then();
    }
    // Custom event listener to listen for accordion open. Destroyed on fetching data
    document.addEventListener("toggle_" + accordionKey, fetchTasks)

    if (loading) return <EpackLoader/>

    if (data) {
        document.removeEventListener("toggle_" + accordionKey, fetchTasks)

        const tasksToRender = data.tasksByProject.edges;
        let tasksToRenderMapped = tasksToRender.map((task: {
            node: {
                id: number,
                name?: string,
                assignee: string | null,
                assigneeId: number | null,
                productCount: number,
                progress: number,
                createDate: string,
                status: number
            }
        }) => {
            return {
                id: task.node.id,
                name: task.node.name,
                assignee: task.node.assignee,
                assigneeId: task.node.assigneeId,
                progress: task.node.progress,
                productCount: task.node.productCount,
                createDate: task.node.createDate,
                status: task.node.status
            };
        });

        const objects = []
        for (const [pk, task] of tasksToRenderMapped.entries()) {
            objects.push(<SingleProjectTaskBox task={task} key={pk}/>)
        }

        return <>{objects}</>
    }

    return <noscript/>

}


interface SingleTaskProps {
    task: Task
}

function SingleProjectTaskBox({task}: SingleTaskProps) {
    const history = useHistory();
    const [
        deleteTask,
        {data: deleteTaskMutationData, error: deleteTaskMutationError, loading: deleteTaskMutationLoading},
    ] = useMutation(TASK_DELETE_MUTATION, {errorPolicy: 'all'});

    const [
        setTaskStatus, {
            error: setTaskMutationError,
            loading: setTaskMutationLoading
        }
    ] = useMutation(SET_TASK_STATUS_MUTATION, {errorPolicy: 'all'});


    const [
        leaveTask, {
            data: leaveTaskMutationData,
            error: leaveTaskMutationError,
            loading: leaveTaskMutationLoading
        }
    ] = useMutation(LEAVE_TASK_MUTATION, {errorPolicy: 'all'});

    if (leaveTaskMutationLoading) {
        return <EpackLoader loaderType="ballcliprotate"/>
    }

    if (leaveTaskMutationError) {
        // Alert user about errors.
        Alert.fire('There is something went wrong...', 'Please contact administrator.', 'error');
        // TODO Add 500 component.
        return <div>{leaveTaskMutationError &&
        <p>Error : <b>{leaveTaskMutationError.message}</b></p>}</div>;
    }

    if (leaveTaskMutationData) {
        const validation_errors = leaveTaskMutationData.leaveTask.errors;
        if (validation_errors != null && validation_errors.length !== 0) {
            // Alert user about errors.
            Alert.fire(validation_errors[0].messages[0], 'Please try again later.', 'error');
        } else {
            SuccessAlert.fire('Task status was changed!').then((ok) => {
            });
        }
    }

    if (setTaskMutationLoading) {
        return <EpackLoader loaderType="ballcliprotate"/>
    }

    if (setTaskMutationError) {
        // Alert user about errors.
        Alert.fire('There is something went wrong...', 'Please contact administrator.', 'error');
        // TODO Add 500 component.
        return <div>{setTaskMutationError &&
        <p>Error : <b>{setTaskMutationError.message}</b></p>}</div>;
    }

    if (deleteTaskMutationLoading) {
        return <EpackLoader loaderType="ballcliprotate"/>
    }

    if (deleteTaskMutationError) {
        // Alert user about errors.
        Alert.fire('There is something went wrong...', 'Please contact administrator.', 'error');
        // TODO Add 500 component.
        return <div>{deleteTaskMutationError &&
        <p>Error : <b>{deleteTaskMutationError.message}</b></p>}</div>;
    }

    if (deleteTaskMutationData) {
        const validation_errors = deleteTaskMutationData.deleteTask.errors;
        if (validation_errors != null && validation_errors.length !== 0) {
            // Alert user about errors.
            Alert.fire(validation_errors[0].messages[0], 'Please try again later.', 'error');
        } else {
            SuccessAlert.fire('Task was deleted!').then((ok) => {
                history.go(0);
            });
        }
    }
    const isPausedTask = task.status === TASK_STATUS.PAUSED;

    return (
        <div className="white-box white-box-list white-box-list-nested single-project-task-box">
            <div className="col pl0">
                <div className="task-name">
                    {task.name}
                </div>
            </div>
            <TaskInfoColumn task={task}/>
            <div className="col pr0">
                {/*TODO: Proper colors*/}
                <ProgressBar label={isPausedTask && "Paused task"}
                             variant={!isPausedTask ? "success" : "info"}
                             now={isPausedTask ? 100 : task.progress} className="task-progress-bar"/>
            </div>
            <>
                <div className="task-progress-percent">{task.progress}%</div>
            </>
            {isPausedTask ? <FontAwesomeIcon icon={faPlay} className="task-resume-button" size="2x"
                                             title="Resume task"
                                             onClick={() => Alert.fire({
                                                 title: "Task resume",
                                                 text: "Are you sure you want resume this task ?",
                                                 showCancelButton: true,
                                                 confirmButtonText: "Yes",
                                                 cancelButtonText: "No",
                                                 allowOutsideClick: false,
                                                 icon: "question",
                                             }).then((resumeSelected) => {
                                                 if (resumeSelected.isConfirmed) {
                                                     // @ts-ignore
                                                     setTaskStatus({
                                                             variables: {
                                                                 "task_id": task.id,
                                                                 "status": TASK_STATUS.INPROGRESS
                                                             }
                                                         }
                                                     )
                                                         .then(({data}) => {
                                                             const validation_errors = data.setTaskStatus.errors;
                                                             if (validation_errors != null && validation_errors.length !== 0) {
                                                                 // Alert user about errors.
                                                                 Alert.fire(validation_errors[0].messages[0], 'Please try again later.', 'error');
                                                             } else {
                                                                 SuccessAlert.fire('Task status was changed!').then((ok) => {
                                                                 });
                                                             }
                                                         })
                                                         .catch(e => {
                                                             // Catch Promise.
                                                             // TODO Add 500 component.
                                                             Alert.fire(e.message, 'Please try again later.', 'error');
                                                         })
                                                 }
                                             })}
            /> : [(task.status === TASK_STATUS.DONE
                    ? <>
                        <div className="task-blank-button"></div>
                    </>
                    :
                    <FontAwesomeIcon icon={faPause} className="task-pause-button" size="2x"
                                     title="Pause task"
                                     onClick={() => Alert.fire({
                                         title: "Task pause",
                                         text: "Are you sure you want to pause this task ?",
                                         showCancelButton: true,
                                         confirmButtonText: "Yes",
                                         cancelButtonText: "No",
                                         allowOutsideClick: false,
                                         icon: "question",
                                     }).then((pauseSelected) => {
                                         if (pauseSelected.isConfirmed) {
                                             // @ts-ignore
                                             setTaskStatus({
                                                     variables: {
                                                         "task_id": task.id,
                                                         "status": TASK_STATUS.PAUSED
                                                     }
                                                 }
                                             )
                                                 .then(({data}) => {
                                                     const validation_errors = data.setTaskStatus.errors;
                                                     if (validation_errors != null && validation_errors.length !== 0) {
                                                         // Alert user about errors.
                                                         Alert.fire(validation_errors[0].messages[0], 'Please try again later.', 'error');
                                                     } else {
                                                         SuccessAlert.fire('Task status was changed!').then((ok) => {
                                                         });
                                                     }
                                                 })
                                                 .catch(e => {
                                                     // Catch Promise.
                                                     // TODO Add 500 component.
                                                     Alert.fire(e.message, 'Please try again later.', 'error');
                                                 })
                                         }
                                     })}
                    />
            )]
            }
            {
                !(task.status === TASK_STATUS.UNASSIGNED || task.status === TASK_STATUS.DONE) ?
                    <FontAwesomeIcon icon={faSignOutAlt} className="task-leave-button" size="2x"
                                     title="Cancel assignment"
                                     onClick={() => WarningAlert.fire({
                                         title: "Cancel assignment.",
                                         text: "Are you sure you cancel assignment for following task ?",
                                         showCancelButton: true,
                                         confirmButtonText: "Unassign",
                                         cancelButtonText: "No",
                                         allowOutsideClick: false,
                                     }).then((leaveSelected) => {
                                         if (leaveSelected.isConfirmed) {
                                             leaveTask({variables: {"task_id": task.id}}
                                             ).then(({data}) => {
                                                 const validation_errors = data.leaveTask.errors;
                                                 if (validation_errors != null && validation_errors.length !== 0) {
                                                     Alert.fire("Error has occurred:", validation_errors[0].messages[0].replace("GraphQL error: ", "").trim(), "error");
                                                 } else {
                                                     SuccessAlert.fire("Task assignment was successfully canceled.").then((ok) => {
                                                         history.go(0);
                                                     });
                                                 }
                                             })
                                                 .catch(error => {
                                                     // Catch Promise.
                                                     // TODO Add 500 component.
                                                     Alert.fire("Error has occurred:", error.message.replace("GraphQL error: ", "").trim(), "error");
                                                 })
                                         }
                                     })
                                     }
                    />
                    :
                    <>
                        <div className="task-blank-button"></div>
                    </>
            }
            <FontAwesomeIcon icon={faWindowClose} className="task-delete-button" size="2x"
                             title="Delete task"
                             onClick={() => WarningAlert.fire({
                                 title: "Task deletion",
                                 text: "Are you sure you want to delete this task ?",
                                 showCancelButton: true,
                                 confirmButtonText: "Yes",
                                 cancelButtonText: "No",
                                 allowOutsideClick: false,
                             }).then((deleteSelected) => {
                                 if (deleteSelected.isConfirmed) {

                                     deleteTask({variables: {"id": task.id}}
                                     )
                                         .then(({data}) => {
                                         })
                                         .catch(e => {
                                             // Catch Promise.
                                             // TODO Add 500 component.
                                             Alert.fire(e.message, 'Please try again later.', 'error');
                                         })
                                 }
                             })}
            />
        </div>
    )
}


function TaskInfoColumn({task}: SingleTaskProps) {
    return (
        <div className="col display-flex">
            <TaskInfoHeaders/>
            <TaskInfoData task={task}/>
        </div>
    )
}


function TaskInfoHeaders() {
    return (
        <div className="col-4 task-info">
            <div className="data-row">Assignee:</div>
            <div className="data-row">Created:</div>
            <div className="data-row">Products:</div>
        </div>
    )
}


function TaskInfoData({task}: SingleTaskProps) {
    return (
        <div className="col-8 task-info-data">
            <div className={task.assignee ? 'data-row-assignee' : 'data-row text-muted'}>
                {task.assignee ? <a href={"/stats/" + task.assigneeId} target="_blank">{task.assignee}</a> : 'Unassigned'}
            </div>
            <div className="data-row">{task.createDate}</div>
            <div className="data-row">{task.productCount} </div>
        </div>
    )
}


export default ProjectTasks;
