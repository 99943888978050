import { gql } from 'apollo-boost';

// It is possible to get all projects with pagination, perform search and ordering.
export const PROJECTS_LIST_QUERY = gql`
    query ProjectList(
        $first: Int = 7
        $after: String
        $search: String
        $waveStatuses: [String] = []
        $projectClients: [String] = []
        $order: [String]
    ) {
        projects(
            first: $first
            after: $after
            search: $search
            waveStatuses: $waveStatuses
            projectClients: $projectClients
            order: $order
        ) {
            edges {
                node {
                    id
                    name
                    createDate
                    waveDataInfo {
                        public
                        taskStats {
                            total
                            unassigned
                            inProgress
                            todo
                            paused
                            done
                        }
                        shops
                        currentWave {
                            pk
                            alterable
                            status
                            isUrgent
                        }
                        disabled
                        queuedWavesCount
                        createDateLastWave
                    }
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
        clients(first: 100) {
            edges {
                node {
                    id
                    name
                    createDate
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;

// Project list query for task view.
export const PROJECTS_LIST_TASK_VIEW_QUERY = gql`
    query ProjectListForTasks($after: String, $search: String, $order: [String]) {
        projectsForTaskList(after: $after, search: $search, order: $order) {
            edges {
                node {
                    id
                    name
                    createDate
                    createDateLastWave
                    taskStatsTasks {
                        unassigned
                    }
                    currentWave {
                        pk
                        alterable
                        status
                        isUrgent
                    }
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;

// Query to get project by id.
export const PROJECT_GET_BY_ID_QUERY = gql`
    query getProject($id: ID!) {
        project(id: $id) {
            id
            name
            escProject
            languageCode
            mapetProject
            algorithm {
                id
            }
            client {
                id
            }
            config
        }
    }
`;
// Get all packshot types and image positions by project id.
export const PROJECT_RESULTS_PACKSHOT_PARSED_IMAGES_CHOICES_QUERY = gql`
    query getProjectResultsChoices($id: ID!) {
        project(id: $id) {
            projectResultsChoices {
                availableAuditors {
                    id
                    fullName
                }
                availableShops
                availablePackshotTypes
                availableParsedImgPositions
                availableAlgorithmAnswers
            }
        }
    }
`;

// Mutation query for project creation.
export const PROJECT_CREATE_MUTATION = gql`
    mutation CreateProjectMutation(
        $name: String!
        $escProject: Int!
        $mapetProject: Int!
        $languageCode: String!
        $algorithm: ID!
        $client: ID!
        $config: String
    ) {
        createProject(
            input: {
                name: $name
                escProject: $escProject
                mapetProject: $mapetProject
                languageCode: $languageCode
                algorithm: $algorithm
                client: $client
                config: $config
            }
        ) {
            project {
                name
            }
            errors {
                field
                messages
            }
        }
    }
`;

// Mutation query for project update.
export const PROJECT_UPDATE_MUTATION = gql`
    mutation EditProjectMutation(
        $id: ID!
        $name: String!
        $escProject: Int!
        $mapetProject: Int!
        $languageCode: String!
        $algorithm: ID!
        $client: ID!
        $config: String
    ) {
        editProject(
            input: {
                id: $id
                name: $name
                escProject: $escProject
                mapetProject: $mapetProject
                languageCode: $languageCode
                algorithm: $algorithm
                client: $client
                config: $config
            }
        ) {
            project {
                id
                name
            }
            errors {
                field
                messages
            }
        }
    }
`;
