import { gql } from 'apollo-boost';

// Mutation query for retry wave.
export const RETRY_WAVE_MUTATION = gql`
    mutation RetryWave($projectId: ID!, $shopList: [String]!) {
        retryWave(projectId: $projectId, shopList: $shopList) {
            wave {
                id
            }
            errors {
                field
                messages
            }
        }
    }
`;

export const SET_PUBLIC_WAVE_STATUS_MUTATION = gql`
    mutation SetPublicWaveStatusMutation($current_wave_id: ID!) {
        setPublicWaveStatus(currentWaveId: $current_wave_id) {
            wave {
                id
            }
            errors {
                field
                messages
            }
        }
    }
`;

export const RE_INITIALIZE_IMAGE_DOWNLOADS_MUTATION = gql`
    mutation ReInitializeImageDownloadsMutation($current_wave_id: ID!) {
        reInitializeImageDownloads(currentWaveId: $current_wave_id) {
            wave {
                id
            }
            errors {
                field
                messages
            }
        }
    }
`;

// Query to get all waves as choices by project id.
export const WAVE_CHOICES_BY_PROJECT_ID_QUERY = gql`
    query GetWaveData($projectId: Int!) {
        wavesChoices(projectId: $projectId) {
            value
            label
        }
    }
`;
// Query to get wave progress data.
export const WAVE_PROGRESS = gql`
    query getWaveProgress($id: Int!) {
        wave(id: $id) {
            waveProgress {
                progress
                comparisonCount
                answeredComparisonsCount
                downloadedComparisonsCount
                endTime
                status
            }
        }
    }
`;
// Delete last wave for project.
export const WAVE_DELETE_MUTATION = gql`
    mutation DeleteWaveMutation($projectId: Int!) {
        deleteLastWave(projectId: $projectId) {
            ok
            errors {
                field
                messages
            }
        }
    }
`;
// Append data to wave.
export const WAVE_APPEND_DATA_MUTATION = gql`
    mutation AppendDataWaveMutation($current_wave_id: ID!) {
        appendDataLastWave(currentWaveId: $current_wave_id) {
            ok
            errors {
                field
                messages
            }
        }
    }
`;
// Make public all waves with Done status.
export const PUBLISH_DONE_WAVES_MUTATION = gql`
    mutation PublishAllDoneWavesMutation {
        publishAllDoneWaves {
            errors {
                field
                messages
            }
        }
    }
`;
// Set wave as urgent ot unimportant.
export const WAVE_MAKE_URGENT_MUTATION = gql`
    mutation DeleteWaveMutation($waveId: Int!, $setUrgent: Boolean) {
        makeUrgentLastWave(waveId: $waveId, setUrgent: $setUrgent) {
            ok
            errors {
                field
                messages
            }
        }
    }
`;
