import { gql } from 'apollo-boost';

// Query for task assignment check.
export const TASK_ASSIGNMENT_QUERY = gql`
    query TasksAssigned {
        tasksAssigned {
            edges {
                node {
                    id
                }
            }
        }
    }
`;

// Query for task list view.
export const TASK_LIST_QUERY = gql`
    query TaskList($first: Int = 50, $after: String, $order: [String]) {
        tasks(first: $first, after: $after, order: $order) {
            edges {
                node {
                    id
                    customName
                    createDate
                    productCount
                    shops
                    project
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;

export const TASK_GET_BY_ID_QUERY = gql`
    query getTask($id: ID!) {
        task(id: $id) {
            customName
            createDate
        }
    }
`;

export const TASK_ASSIGN_TASK_QUERY = gql`
    mutation AssignTaskMutation($id: ID!) {
        assignTask(id: $id) {
            task {
                id
                customName
            }
            errors {
                field
                messages
            }
        }
    }
`;

export const TASK_CREATE_TASK_QUERY = gql`
    mutation CreateTaskMutation(
        $customName: String
        $productCodes: String
        $projectId: Int!
        $assignee: Int
        $comparisonsPercent: Int
        $isUrgent: Boolean
        $isChanged: Boolean
        $changedAnswer: Boolean
        $shops: [String] = []
        $packshotTypes: [String] = []
        $algorithmAnswers: [Int] = []
        $auditorsInvolved: [String] = []
    ) {
        createTask(
            customName: $customName
            productCodes: $productCodes
            projectId: $projectId
            assignee: $assignee
            comparisonsPercent: $comparisonsPercent
            isUrgent: $isUrgent
            isChanged: $isChanged
            changedAnswer: $changedAnswer
            shops: $shops
            packshotTypes: $packshotTypes
            algorithmAnswers: $algorithmAnswers
            auditorsInvolved: $auditorsInvolved
        ) {
            task {
                id
                customName
            }
            errors {
                field
                messages
            }
        }
    }
`;

// Mutation query for task deletion.
export const TASK_DELETE_MUTATION = gql`
    mutation DeleteTaskMutation($id: ID!) {
        deleteTask(id: $id) {
            ok
            errors {
                field
                messages
            }
        }
    }
`;

export const SET_TASK_STATUS_MUTATION = gql`
    mutation SetTaskStatusMutation($task_id: Int!, $status: Int!) {
        setTaskStatus(taskId: $task_id, status: $status) {
            task {
                id
                status
            }
            errors {
                field
                messages
            }
        }
    }
`;

export const LEAVE_TASK_MUTATION = gql`
    mutation SetTaskStatusMutation($task_id: Int!) {
        leaveTask(taskId: $task_id) {
            task {
                id
                status
            }
            errors {
                field
                messages
            }
        }
    }
`;

export const GET_TASK_BY_PROJECT = gql`
    query getComparisonByProject($project_id: ID!) {
        tasksByProject(projectId: $project_id) {
            edges {
                node {
                    id
                    name
                    createDate
                    assignee
                    assigneeId
                    productCount
                    progress
                    status
                }
            }
        }
    }
`;

export const GET_TASK_BY_PROJECT_TASK_LIST = gql`
    query getComparisonByProjectTaskList($project_id: ID!) {
        tasksByProjectTaskList(projectId: $project_id) {
            edges {
                node {
                    id
                    customName
                    createDate
                    productCount
                    shops
                    project
                    progress
                }
            }
        }
    }
`;
